.table-row {

    .ant-table-content {
        width: 100%;
        .table-item {

            display: inline-flex;

            .alphabetic-item {
                width: 32px;
                height: 32px;
                background: #F5F5F5;
                border-radius: 50%;
                display: flex;
                justify-content: center;
                align-items: center;
                text-transform: uppercase;
                font-size: 14px;
                line-height: 22px;
                color: #525252;
            }
            .item-concat{
                padding-left: 8px;
                display: block;
                font-weight: 600;
                font-size: 14px;
                line-height: 22px;
                color: #262626;                           
                text-align: left;

                .email-item {
                    font-size: 12px;
                    line-height: 20px;
                    color: #525252;
                    font-weight: normal;
                }                        
            }
        }
        .ant-table-thead {
            font-weight: 600!important;
            font-size: 14px !important;
            line-height: 22px!important;
            color: #262626;
        }   
        
        th.ant-table-cell {
            font-weight: 600!important;
            font-size: 14px !important;
            line-height: 22px!important;
            color: #262626;
        }
    }

    .ant-table-wrapper {
        width: 100%;
    }

    .table-item {
        width: 100%;
    }
}

.search-row {
    display: flex;
    justify-content: end;
    padding-bottom: 24px;
    
    svg {
        vertical-align: -webkit-baseline-middle;
    }
    .searchbox {
        display: flex;
    }
}
@backgroud-color: #E8E8E8;@primary-color: #3AB100;@primary-border: #58BD1E;@primary-background: #3AB100;@primary-hover: #E4F0D8;@primary-border-hover: #97D667;@info-color: #12b1d6;@warning-color: #FA8C16;@error-primary: #F5222D;@error-border: #FFA39E;@error-background: #FFF1F0;@white: #FFFFFF;@gray-border: #BFBFBF;@gray-background: #E6E6E6;@card-background: #FFFFFF;@text-color: #525252;