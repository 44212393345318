@import "../../_variables.less";


.site-layout .site-layout-background {
  background: @backgroud-color;
}

.margin-right{
  margin-right: 8px;
}

.margin-top{
  margin-top: 5px;
}



.margin-left{
  margin-left: 15px;
}

.rounded-button {
  background: #FFFFFF;
  border: 1px solid #BFBFBF;
  box-sizing: border-box;
  border-radius: 11px;
  color: #525252;
  font-weight: normal;
  font-size: 1em;
  cursor: pointer;
  line-height: -24px;
}

.rounded-button-filled{
  background: @primary-border !important;
  border: 0 solid white;
  color: white !important;
  border-radius: 15px !important;
  font-weight: bold !important;
}

// statistic-item classes

.left-divider{
  border-left: 1px solid;
}

.statistics-item-expanded-title{
  margin-left: 45px;
  margin-top: 22px;
}

.statistics-item-expanded-value{
  margin-left: 45px;
}

.statistic-item-wrapper{
  display: inherit;
  width: 100%;
  .inherit{
    display: inherit;
  }
  .statistics-item-badge-wrapper{
    display: inherit;
    flex-direction: column;
    justify-content: center;
  }
}






.rounded-button-stealth{
  border: none;
  color: @gray-border;
  font-size: 1em;
}

.rounded-button-stealth:hover, .rounded-button-stealth:active{
  color: @primary-border;
}

.rounded-button-filled:hover{
  background: @primary-border-hover;
}

.rounded-button-exit{
  user-select: none;
  border-radius: 15px;
  font-size: 0.85em;
  padding-left: 0.65em;
  padding-right: 0.65em;
}

.rounded-button-exit:hover{
  border: 1px solid @error-border;
  color: @error-border;
}

.rounded-button-exit:active{
  border: 1px solid @warning-color;
  color: @warning-color;
}


.home-content {

  padding: 22px 32px 147px 32px;
  display: flex;
  width: 100%;
  font-weight: normal;
  font-family: "Inter", sans-serif;
  font-size: 14px;
  line-height: 22px;
  color: #737373;

  @media (max-width: 1199px) {
    .title-item {
      font-weight: normal !important;
      font-size: 0.8em !important;
      line-height: 0.9em !important;
      color: #737373 !important;

      display: flex;
      align-self: center;
      align-items: center;
      vertical-align: middle;
    }
  }

  @media (min-width: 1200px) and (max-width: 1600px) {
    .title-item {
      font-weight: normal !important;
      font-size: 1em !important;
      line-height: 1.05em !important;
      color: #737373 !important;

      display: flex;
      align-self: center;
      align-items: center;
      vertical-align: middle;
    }
  }
  @media (min-width: 1600px) {
    .title-item {
      font-weight: normal !important;
      font-size: 1.2em !important;
      line-height: 1.15em !important;
      color: #737373 !important;

      display: flex;
      align-self: center;
      align-items: center;
      vertical-align: middle;
    }
  }


  .value-item {
    font-style: normal !important;
    font-weight: 600 !important;
    font-size: 1.2em !important;
    line-height: 1.3em !important;
    color: #262626 !important;

    &.amount {
      font-size: 1.3em !important;
      line-height: 2em !important;
    }
  }

  .badge-item {

    .ant-badge-status-dot {
      width: 14px;
      height: 14px;
      display: inherit;
      top: 10px;
      align-self: center;
      align-items: center;
    }
  }

  .chart-content {
    width: 81px;
    height: 80px;

    &.goal-statistics {

      width: 112px;
      height: 112px;
    }
  }

  .home-chart-content {
    width: 174px;
    height: 174px;
  }

  &.statistics {

    .chart-content {
      width: 112px;
      height: 112px;
    }

    width: 100%;

  }

  .stats-details {
    padding-left: 11px;

    .more-details-link {
      user-select: none;
      cursor: pointer !important;
      color: @dark-grey !important;
      font-weight: normal !important;
    }

    .more-details-link:hover {
      color: @primary-border-hover !important;
    }

    .more-details-link:active {
      color: @warning-color !important;
    }
  }

  .value-item {
    font-weight: 600 !important;
    font-size: 1.3em !important;
    line-height: 1em !important;
    color: #262626 !important;
  }

  .circle-icon {
    width: 40px;
    height: 40px;
    background: #F5F5F5;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: text-bottom;
  }

  .centered-column {
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
  }

  .col-divider-right {
    border-right: 1px solid @backgroud-color;
  }

  .col-padding-left {
    padding-left: 25px;
  }

  .col-padding-right {
    padding-right: 25px;
  }


  .row-card-content {
    align-items: stretch;
    width: 100%;

    .center-flex {
      display: inline-flex;
      width: 100%;
      justify-content: center;
    }

    .card-column {
      flex: 1;

      .card-item {

        height: 100%;
        width: 100%;
        border-radius: 12px;

        .ant-tabs-tab-btn {
          font-weight: normal;
          font-size: 16px;
          line-height: 24px;
        }

        .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
          font-weight: 600;
          font-size: 16px;
          line-height: 24px;
        }

        .row-tab-content {

          //margin-top: 24px;

          .home-row-item-margin-top {
            margin-top: 12px;
          }

          .home-row-item-margin-top-main{
            margin-top: 30px;
          }

          .home-row-item-margin-left {
            margin-left: 1.5em;
          }

          .home-row-item-total {

            display: block;

            .value-item {
              font-weight: 600 !important;
              font-size: 1.3em !important;
              line-height: 1em !important;
              color: #262626 !important;
            }

            .inline-block {
              display: inline-block;
            }


            .value-item-main {
              font-weight: 600 !important;
              font-size: 1.85em !important;
              line-height: 1.3em !important;
              color: #262626 !important;
             }

            .value-item-center{
              text-align: center;
            }


          }

          .home-row-item-simple {
            display: inline-flex;
            width: 100%;

            @media (max-width: 1600px) {
            .chart-content-transaction {
              width: 6em;
              height: 6em;
            }
            }

            @media (min-width: 1600px) {
              .chart-content-transaction {
                width: 9em;
                height: 9em;
              }
            }
          }

          .home-row-item-right-block {
            display: inline-block;
            text-align: right;
          }


          .home-row-item-margin-left {
            margin-left: 25px;
          }

          .home-row-item-padding-top {
            padding-top: 16px !important;
          }

          .home-row-item {
            width: 100%;
            //margin-top: 16px;
            padding: 0 0 0 0 !important;

            &.center {
              display: flex;
              justify-content: center;
            }

            &.vcenter {
              align-items: center;
            }

            &.bottom {
              margin-bottom: 22px;
            }

            .home-row-item-center {
              display: inline-flex;
              justify-content: center;
              align-items: center;
            }

            .value-item-center-flex {
              font-weight: 600 !important;
              font-size: 36px !important;
              line-height: 32px !important;
              color: #262626 !important;
              justify-content: center;
            }

            .home-row-item-center-block {
              display: inline-block;
              text-align: center;
              align-items: center;
            }
          }


          &.inline {

            .ant-card-body {
              display: inline-flex;

              .circle-icon {
                width: 40px;
                height: 40px;
                background: #F5F5F5;
                border-radius: 50%;
                display: flex;
                justify-content: center;
                align-items: center;
              }
            }

            .tab-content {
              padding-left: 12px;
              display: block;

              .value-item {
                font-weight: 600 !important;
                font-size: 22px !important;
                line-height: 28px !important;
                color: #262626;
              }
            }
          }
        }
      }
    }
  }
}

