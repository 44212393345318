
.modal-header-title {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #262626;
}

.save-button {
    border-radius: 11px;
}

.cancel-button {
    border-radius: 11px;
}

.row-item {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 24px 0px 0px 0px;
    
    &.no-padding {
    padding: 0px 0px 0px 0px;

    }

    &.status-row {
        margin-top: 32px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        background: #F5F5F5;
        padding-bottom: 0px;

        .status-field {
          padding:16px;
          .title-field {
              display: flex;
          }
        }
    }

    &.table-row {
        padding: 10px 0px 0px 0px;

        .row-dragging {
            background: #fafafa;
            border: 1px solid #ccc;
            color :#262626;
            visibility: visible !important;

        }

        .row-dragging  tr {
            visibility: visible !important;        
        }

        .row-dragging td  {
            padding: 12px;
            visibility: visible !important;
        }
                    
        .row-dragging .drag-visible {
            visibility: visible !important;        
        }

        //         tr {                    
        //             &.ant-table-row {
        //                 &.row-dragging {
        //                     background: #fafafa;
        //                     border: 1px solid #ccc;
        //                     color :#262626;
        //                     visibility: visible !important;
        //                 }

        //             &.row-dragging td  {
        //                 padding: 12px;
        //                 visibility: visible !important;
        //             }


        //             &.row-dragging {
        //                 &.drag-visible {
        //                     visibility: visible !important;        
        //                 }       
        //             }
        //     }
        // }
    }

    .ant-table-thead {
        display: none;
    }
    
    .ant-table-wrapper {
        width: 100%;
    }

    .affiliate-name {
        display: flex;
        width: 100%;

        .edit-head-item {

            .affiliate-icon {
               
                .ant-upload.ant-upload-select.ant-upload-select-picture-card {
                    max-width: 92px;
                    max-height: 92px;
                    border-radius: 50%;
                }

                .edit-render-uploaded-image {
                    max-width: 62px;
                }  
            }
            &.name {
                width: 100%;

                .item-title {
                    font-size: 14px;
                    line-height: 22px;
                    color: #262626;
                    padding-bottom: 2px;
                }
            
                .item-input {
                    border-radius: 12px;
                    width: 80%;
                    &.with-element {
                        .ant-input-group-addon:first-child {
                            border-right: 0;
                            border-top-left-radius: 12px !important;
                            border-bottom-left-radius: 12px !important;
                        }
                        .ant-input-group > .ant-input:last-child, .ant-input-group-addon:last-child {
                            border-top-right-radius: 12px !important;
                            border-bottom-right-radius: 12px !important;
                        }
                    }
                }

                .button-delete {
                    margin-left: 5px;
                    border-radius: 12px;
                    border-color: #FF4D4F;
                    &:hover, &:active {color: #FF4D4F;}    
                }
            }
        }
    }

    .item-title {
        font-size: 14px;
        line-height: 22px;
        color: #262626;
        padding-bottom: 2px;
    }

    .item-input {
        border-radius: 12px;
        &.with-element {
            .ant-input-group-addon:first-child {
                border-right: 0;
                border-top-left-radius: 12px !important;
                border-bottom-left-radius: 12px !important;
            }
            .ant-input-group > .ant-input:last-child, .ant-input-group-addon:last-child {
                border-top-right-radius: 12px !important;
                border-bottom-right-radius: 12px !important;
            }
        }
    }

    .main-title {
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        align-items: center;
        color: #262626;
    }

    .affiliate {
        display: block;
        padding-left: 5px;
        .icon {
            border-radius: 50%;
            width: 76px;
        }
    }  

    // .row-dragging {
    //     background: #fafafa;
    //     border: 1px solid #ccc;
    //     color :#262626;
    //     visibility: visible !important;
    //   }
      
    //   .row-dragging td  {
    //     padding: 12px;
    //     visibility: visible !important;
    //   }
      
      
    //   .row-dragging .drag-visible {
    //     visibility: visible !important;
    //   }
      

      



      .new-promotion-section{
        display: flex;
        justify-content: space-between;
        align-items: baseline;
        align-content: space-between;
        width: 100%;   
        .remove-promotion {
            padding-bottom: 5px;
            .button-delete {
                margin-left: 5px;
                border-radius: 12px;
                border-color: #FF4D4F;
                &:hover, &:active {color: #FF4D4F;}    
            }
        }
    }
}

.delete-modal {

    .anticon.anticon-delete.delete-icon-modal {
        color: #F5222D !important;
    }

    .ant-modal-confirm-btns {

        .ant-btn {
            background: #FFFFFF;
            border: 1px solid #BFBFBF;
            box-sizing: border-box;
            border-radius: 11px;
            &:hover, &:active {color: #525252;;}    

            &.ant-btn-primary {
                border: 1px solid #F5222D;
                background: #F5222D;
                color: #FFFFFF;
                &:hover, &:active {color: #FFFFFF;;}    

            }
        }
    }
}

.row-dragging {
    background: #fafafa;
    border: 1px solid #ccc;
    color :#262626;
  }
  
  .row-dragging td  {
    padding: 12px;
  }
  
  .row-dragging td:nth-child(4) {
    visibility: hidden;
   // display: none;
  }
  
  .row-dragging .drag-visible {
    visibility: visible;
  }
@backgroud-color: #E8E8E8;@primary-color: #3AB100;@primary-border: #58BD1E;@primary-background: #3AB100;@primary-hover: #E4F0D8;@primary-border-hover: #97D667;@info-color: #12b1d6;@warning-color: #FA8C16;@error-primary: #F5222D;@error-border: #FFA39E;@error-background: #FFF1F0;@white: #FFFFFF;@gray-border: #BFBFBF;@gray-background: #E6E6E6;@card-background: #FFFFFF;@text-color: #525252;