@import "../../../_variables.less";

.notification-content {
  display: flex;
  width: 100%;
  font-weight: normal;
  font-family: "Inter", sans-serif;
  justify-content: center;
  font-size: 14px;
  flex-wrap: wrap;
  line-height: 22px;
  color: #737373;

  .nt-row-card-content {
    margin: 2% !important;
    width: 96%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .notification-item-right{
      justify-content: flex-end !important;
    }

    .notification-item-center{
      justify-content: center !important;
    }
    .notification-item {
      display: inline-flex;
      flex-direction: row;
      flex-grow: 1;
      justify-content: space-between;
      width: 100%;
      margin-bottom: 24px;

      .search-wrapper{
        display: inline-flex;
        max-width: 50%;

        .search-input {
          border-radius: 12px;
          span.ant-input-affix-wrapper.ant-input-affix-wrapper-lg {
            border-top-left-radius: 12px;
            border-bottom-left-radius: 12px;
          }
          button.ant-btn.ant-btn-primary.ant-btn-lg.ant-input-search-button {
            border-top-right-radius: 12px;
            border-bottom-right-radius: 12px;
          }
        }
      }

      //flex: 1;

     .notification-template-title{
       display: inline-flex;
       flex-direction: row;
       width: 80%;
       font-size: 1.4em;
       justify-content: flex-start;
       padding-left: 2.5%;
       color: black;
     }
    }

    .notification-list-sender{
      display: block;
      flex-wrap: wrap;
      flex-direction: column;
      justify-content: flex-start !important;
      vertical-align: text-bottom;
      padding: 0 !important;

      .nt-tab-row-card-content {
        align-items: stretch;
        margin: 0;
        flex: 1;
      }
    }

    .notification-log-view {
      display: block;
      flex-wrap: wrap;
      width: 96%;
      flex-direction: column;
      justify-content: flex-start !important;
      vertical-align: text-bottom;
      padding: 0 !important;

      .nt-tab-row-card-content {
        align-items: stretch;
        margin: 0;
        flex: 1;
      }
    }

    .is-flex-expanded{
      flex-grow: 1;
    }

    .notification-sender {
      width: 49.3%;
      margin-left: 1.4%;
      flex-direction: column;
      flex-wrap: wrap;
      padding: 0 !important;

      .nt-template-view-row-item{
        margin: 0;
        padding: 0;
        width: 100%;
        line-height: 24px;
      }

      .nt-tab-row-card-content {
        align-items: stretch;
        margin: 0;
        flex: 1;

        .nt-card-col{
          display: flex;
          flex: 1;
          width: 100%;

          .notification-button-wrapper {
            margin-top: 5vh;
            flex: 1;
            width: 100% !important;
            display: flex;
            justify-content: flex-end !important;
          }

          .nt-card-item {
            height: 100%;
            width: 100%;
            flex: 1;
            border-radius: 12px;

            .notification-tab {
              display: flex;
              flex: 1;
            }
          }
        }
      }

    }

    .nt-collapsed{
      max-height: 60px;
      height: initial;
      overflow: hidden;

      .ant-card-body{
        display: none !important;
      }
    }

    .nt-expanded{
      height: auto;
    }
  }


}