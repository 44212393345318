
@import '../../_variables.less';

.login-form {
    
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 24px;

    background: @card-background;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
    border-radius: 12px;  
    margin-top: 24px;

   .header-title {
        font-weight: 600;
        font-size: 20px;
        line-height: 28px;

        display: flex;
        align-items: center;
        text-align: center;


        color: #262626;

        flex: none;
        order: 0;
        align-self: center;
        flex-grow: 0;
        margin: 0px 24px;
    }
        
    .form {

        margin-top: 24px;      

     
        .email-input {
            background: @card-background;
            border: 1px solid @gray-border;
            box-sizing: border-box;
            border-radius: 12px;        
        }

        .password-input {
            background: @card-background;
            border: 1px solid @gray-border;
            box-sizing: border-box;
            border-radius: 12px;
        
        }

        .login-remember-me {
            display: inline;

            height: 22px;
            left: 24px;
            right: 0px;
            top: calc(50% - 22px/2);

            font-weight: normal;
            font-size: 14px;
            line-height: 22px;
            color: @text-color;

            flex: none;
            order: 1;
            align-self: center;
            flex-grow: 0;
            margin: 8px 0px;
            
            &:hover {
                border-color: @primary-color;
            }

            .ant-checkbox-wrapper:hover {
                border-color: @primary-color;
            }

            .ant-checkbox-checked .ant-checkbox-inner {
                background-color: @primary-color;
                border-color: @primary-color;
            }

            .ant-checkbox-wrapper:hover .ant-checkbox-inner{
                border-color: @primary-color;
            }

            .ant-checkbox:hover .ant-checkbox-inner
            {
                border-color: @primary-color;
            }

            .ant-checkbox-input:focus  .ant-checkbox-inner {
                border-color: @primary-color;
            }
        }

        .login-fogot-password {
            width: 112px;
            height: 22px;
            font-weight: normal;
            font-size: 14px;
            line-height: 22px;
            color: @primary-color;
            float: right;
        }
    }

        .login-submit-button {
            padding: 8px 16px;
            box-sizing: border-box;
            border-radius: 12px;

            &.disabled {
                background: @gray-background;
                border: 1px solid  @gray-border;
                border-color:  @gray-border;

                &:hover{
                    border-color:  @gray-border;
                }
            }
            &.active {
                background: @primary-color;
                border-radius: 12px;
                border: 1px solid@primary-color;

                &:hover{
                    border-color: @primary-color;
                }
            }
        
        }
    
    
    .error-alert {
        margin-top: 24px;
        background: #FFF1F0;
        border: 1px solid #FFA39E;
        box-sizing: border-box;
        border-radius: 12px;

        .ant-alert-message{
            padding-right: 10px;
        }
    }

}
    .header-logo {
        max-width: 125.44px ;
        height: 56px;
    }

    .footer-login {
        margin-top: 32px;
        flex: none;
        order: 1;
        align-self: center;
        flex-grow: 0;
        padding: 10px;
        
        .footer-content {
            position: static;
            width: 338px;
            height: 22px;
            left: 12px;
            top: 426px;

            font-weight: normal;
            font-size: 14px;
            line-height: 22px;
            
            color: @text-color;
        }
    }
