.cashback-details {
        display: block;
        margin: 0 !important;
        background-color: #F5F5F5;;
    .cashback-item {
        padding: 10px;

        .item-details  {   
                     
            .item-value {
                display: block;
                font-weight: normal;
                font-size: 14px;
                line-height: 22px;
                align-items: center;
                color: #525252;
                padding-top: 5px;
                
            }

            .item-title {
                font-weight: normal;
                font-size: 14px;
                line-height: 22px;
                display: flex;
                align-items: center;
                color: #737373;
                // padding: 5px;
            }
        }
    }
    .title {
        font-weight: 600;
        font-size: 14px;
        line-height: 22px;
        display: flex;
        align-items: center;
        color: #000000;
    }

    .transfer-type {
        display: flex;
        padding: 25px 0px 0px 10px;
        .title{
            font-weight: 600;
            font-size: 14px;
            line-height: 22px;
            display: flex;
            align-items: center;
            color: #000000;
        }
    }

    .ant-divider-horizontal {
        margin: 15px 0;
    }
}
@backgroud-color: #E8E8E8;@primary-color: #3AB100;@primary-border: #58BD1E;@primary-background: #3AB100;@primary-hover: #E4F0D8;@primary-border-hover: #97D667;@info-color: #12b1d6;@warning-color: #FA8C16;@error-primary: #F5222D;@error-border: #FFA39E;@error-background: #FFF1F0;@white: #FFFFFF;@gray-border: #BFBFBF;@gray-background: #E6E6E6;@card-background: #FFFFFF;@text-color: #525252;