@import './index.less';

html,
body {
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
}

body {
  background: @backgroud-color;
  -webkit-overflow-scrolling: touch;
  font-family: 'Inter', sans-serif;
  overflow-x: hidden;
  font-family: -apple-system, BlinkMacSystemFont, 'Inter', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  .icon-styles {
    color: @primary-color !important;
  }

  .warning-style {
    color: @warning-color !important;
  }

  .error-style {
    color: @error-primary !important;
    border-color: @error-border !important;
  }


  .default-breadcrumb-item {
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;
  }

  .breadcrumb {
    margin: 3px 0;
    background-color: white;
    padding: 18px;
  }

  .default-item {
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;
  }

  .ant-breadcrumb-link {
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
  }

  .breadcrumb-subpage-title {
    background-color: white;
    margin-top: -26px;
    padding: 18px;
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;
    color: #262626;
  }

  .ant-pagination-item {
    border-radius: 12px;
  }

  .ant-pagination-item-link {
    border-radius: 12px !important;
  }

  .account-header {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .ant-tag {
    border-radius: 6px;
    border: none;
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
    align-items: center;

    &.active {
      background: #E4F0D8;
      color: #3AB100;
    }

    &.bank-connected {
      background: #FCFFE6;
      color: #7CB305;
    }

    &.not-identified {
      background: #f5695e;
      color: #f6ec8d;
    }

    &.bank-disconnected {
      background: #FFF7E6;
      color: #FA8C16;
    }

    &.delete-request {
      background: #FFF1F0;
      color: #F5222D;
    }

    &.not-verified {
      background: #F5F5F5;
      color: #737373;
    }
    &.maininvest-investors {
      background: #086996;
      color: #F5F5F5;
    }
    &.onboarding {
      background: #F0F5FF;
      color: #2F54EB;
    }

    &.deposit {
      background: #F2F7EE;
      color: #3AB100;
    }

    &.direct-deposit {
      background: #fcffc9;
      color: #298A00;
      padding-left: 9.3px;
    }

    &.withdrawal {
      background: #F0FAFF;
      color: #4096E8;
    }

    &.marketplace-purchase {
      background: #FAF1FF;
      color: #8034C7;
    }

    &.cashback {
      background: #FFF0F9;
      color: #C76DB8;
    }

    &.referrarl {
      background: #E5FAFF;
      color: #23D5FF;
    }

    &.round {
      border-radius: 50% !important;
      width: 32px;
      height: 32px;
      display: flex;
    }
  }

  .ant-dropdown {

    .ant-table-filter-dropdown {
      .ant-dropdown-menu {
        .ant-dropdown-menu-item {
          img.icon {
            max-width: 32px;
            padding: 7px;
          }
        }
      }
    }
  }


  .searchbox {
    padding: 24px 0px 24px 0px;
    border-radius: 12px;
    width: 30%;
    padding-bottom: 24px;

    .ant-input.ant-input-lg {
      border-top-left-radius: 12px;
      border-bottom-left-radius: 12px;
    }

    span.ant-input-affix-wrapper.ant-input-affix-wrapper-lg {
      border-top-left-radius: 12px !important;
      border-bottom-left-radius: 12px !important;
    }

    button.ant-btn.ant-btn-lg.ant-btn-icon-only.ant-input-search-button {
      border-top-right-radius: 12px !important;
      border-bottom-right-radius: 12px !important;
    }

  }

  .ant-tabs.ant-tabs-top {
    width: 100%;
  }

  .reactEasyCrop_CropArea {
    &.reactEasyCrop_CropAreaGrid {
      width: 250px !important;
      height: 100px !important;
    }
  }

  span.anticon.anticon-search {
    vertical-align: middle;
  }

  .reactEasyCrop_CropArea.reactEasyCrop_CropAreaRound {
    width: 260px !important;
    height: 260px !important;
  }
}
