.affiliate-details {
    display: block;
    padding: 22px 32px 147px 32px;
    
    .details-item {
      width: 100%;

      .affiliate-card-item {
          
        .row-card-item {
            padding: 24px;

            .affiliate-header-row {
                display: flex;

                .icon-item {
                    width: 72px;
                    height: 72px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    text-transform: uppercase;
                    .icon {
                        width: 72px;
                        //height: 72px;
                    }
                }

                .name-details {
                    display: flex;
                    padding-left: 24px;
                    justify-content: center;
                    flex-direction: column;

                    .name-item {
                        font-weight: 600;
                        font-size: 20px;
                        line-height: 28px;
                        display: flex;
                        align-items: center;
                        color: #262626;                    
                    }

                    .details-item{
                        display: block;

                        .icon {
                            width: 30px;
                            height: 30px;
                            padding: 5px;
                        }
                    }
                }
            }

            .affiliate-status-action {
                display: flex;
                flex-direction: row;
                justify-content: flex-end;
                align-items: center;
                .button-actions {
                    padding-right: 7px;
                .edit-affiliate-button {
                    background: #FFFFFF;
                    border: 1px solid #BFBFBF;
                    box-sizing: border-box;
                    border-radius: 11px;
                    color: #525252;
                    font-size: 14px;
                    line-height: 22px;
                }                
                .delete-affiliate-button {
                    background: #FFFFFF;
                    border: 1px solid #FF4D4F;
                    box-sizing: border-box;
                    border-radius: 11px;
                    color: #FF4D4F;
                    font-size: 14px;
                    line-height: 22px;

                    &.disabled {
                        background: #FFFFFF;
                        border: 1px solid #BFBFBF;
                        box-sizing: border-box;
                        border-radius: 11px;
                        color: #A6A6A6;
                        font-size: 14px;
                        line-height: 22px;
                    }
                }   
            }     
            }            
        }

        .details-row-card-item {

            .gutter-row {
                    width: 100%;
                    // padding: 24px;

                .affiliate-field-item {
                    font-weight: 600 !important;
                    font-size: 14px !important;
                    line-height: 22px !important;
                    color: #262626 !important;
                    padding-top: 24px;
                }

                .affiliate-field-value {
                    font-size: 14px !important;
                    line-height: 22px !important;
                    color: #525252 !important;

                    .icon {
                        width: 30px;
                        height: 30px;
                        padding: 5px;
                    }
                }
            }
        }
      }
    }
    
    .ant-spin-nested-loading {
        width: 100%;
    }   

    .row-card {       
        margin-top: 24px;
        display: block;
        width: 100%;

        .affiliate-card-item {
            background: #FFFFFF;
            border: 1px solid #BFBFBF;
            box-sizing: border-box;
            border-radius: 12px;

            .details-row-card-item {
                .gutter-row {
                    width: 100%;
                    padding: 24px;
                }
            }
        }

        &.statistic-row {
            display: flex;
            width: 100%;

            .card-item {

                background: #FFFFFF;
                border: 1px solid #BFBFBF;
                box-sizing: border-box;
                border-radius: 12px;

                .statistic-item {
                    text-align: center;
                    justify-content: center;
                    align-items: center;
                    display: flex;

                    .statistic-value {
                        font-weight: 600;
                        font-size: 20px;
                        line-height: 28px;
                        color: #525252;
                    }

                    .statistic-name {
                        font-weight: normal;
                        font-size: 11px;
                        line-height: 18px;
                        color: #737373;
                    }
                }               
              
            }
        }
    }

}
@backgroud-color: #E8E8E8;@primary-color: #3AB100;@primary-border: #58BD1E;@primary-background: #3AB100;@primary-hover: #E4F0D8;@primary-border-hover: #97D667;@info-color: #12b1d6;@warning-color: #FA8C16;@error-primary: #F5222D;@error-border: #FFA39E;@error-background: #FFF1F0;@white: #FFFFFF;@gray-border: #BFBFBF;@gray-background: #E6E6E6;@card-background: #FFFFFF;@text-color: #525252;