

.withdraw-statistics {
    .anticon svg {
        display: inline-block;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
    }
    .avatar-row {

        display: flex;
        text-align: center;
        padding-bottom: 10px;
        align-items: center;

        .avatar-item {
            color: '#3AB100' !important;
            max-width: 54px;
            max-height: 54px;
            line-height: 54px;
            justify-content: center;
            align-items: center;
        }
    }
   

}
@backgroud-color: #E8E8E8;@primary-color: #3AB100;@primary-border: #58BD1E;@primary-background: #3AB100;@primary-hover: #E4F0D8;@primary-border-hover: #97D667;@info-color: #12b1d6;@warning-color: #FA8C16;@error-primary: #F5222D;@error-border: #FFA39E;@error-background: #FFF1F0;@white: #FFFFFF;@gray-border: #BFBFBF;@gray-background: #E6E6E6;@card-background: #FFFFFF;@text-color: #525252;