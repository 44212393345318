

.collapsed-in {
    .ant-card-body {
        display: none !important;
    }
}

.collapsed-off{
    
    .ant-card-body  {
        display: block !important;
    }
}

.affiliate-list {
    display: flex;
    align-items: center;
    
    .icon {
        width: 68px;
        padding-right: 6px;
        //margin-left: -25px;
    }
    .name {
        font-weight: normal;
        font-size: 14px;
        line-height: 22px;
        color: #525252;
    }
}

.affiliate-item {
    display: flex;
    justify-content: space-between ;   
    padding-bottom: 24px;   
    
    &.deletion-items {
        justify-content: flex-end;
    }

    .searchbox {
        padding: 0px;
        padding-bottom:0px;
        display: flex;
        justify-content: center;
        align-items: flex-end;

        .export-button{
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .search-input {
            padding-left: 8px;
        }
    }

}

.platform-title {
    padding-left: 5px;
}

.category-name {
    padding-left: 13px;
}
.tab-title {
    font-size: 16px;
    line-height: 24px;
    font-weight: normal;
}

@backgroud-color: #E8E8E8;@primary-color: #3AB100;@primary-border: #58BD1E;@primary-background: #3AB100;@primary-hover: #E4F0D8;@primary-border-hover: #97D667;@info-color: #12b1d6;@warning-color: #FA8C16;@error-primary: #F5222D;@error-border: #FFA39E;@error-background: #FFF1F0;@white: #FFFFFF;@gray-border: #BFBFBF;@gray-background: #E6E6E6;@card-background: #FFFFFF;@text-color: #525252;