.row-card {       
    margin-top: 24px;
    display: block;

    &.statistic-row {
        display: flex;
        width: 100%;
        padding: 24px 0px 12px 0px;
        .card-item {

            background: #FFFFFF;
            border: 1px solid #BFBFBF;
            box-sizing: border-box;
            border-radius: 12px;
            
            .statistic-item {
                text-align: center;
                justify-content: center;
                align-items: center;
                display: flex;

                .statistic-value {
                    font-weight: 600;
                    font-size: 20px;
                    line-height: 28px;
                    color: #525252;
                }

                .statistic-name {
                    font-weight: normal;
                    font-size: 11px;
                    line-height: 18px;
                    color: #737373;
                }
            }               
          
        }
    }
}
@backgroud-color: #E8E8E8;@primary-color: #3AB100;@primary-border: #58BD1E;@primary-background: #3AB100;@primary-hover: #E4F0D8;@primary-border-hover: #97D667;@info-color: #12b1d6;@warning-color: #FA8C16;@error-primary: #F5222D;@error-border: #FFA39E;@error-background: #FFF1F0;@white: #FFFFFF;@gray-border: #BFBFBF;@gray-background: #E6E6E6;@card-background: #FFFFFF;@text-color: #525252;