.dashboard-content {
    display: block;
    padding: 22px 32px 147px 32px;

    .row-card {
       
        margin-top: 24px;
        display: block;
        
        .result-details {

                font-weight: 600 !important;
                font-size: 16px !important;
                line-height: 24px !important;
                display: flex;
                align-items: center;
                color: #262626 !important;
        }

        .card-item {
            background: #FFFFFF;
            border: 1px solid #BFBFBF;
            box-sizing: border-box;
            border-radius: 12px;
            margin-top: 24px;   
            display: flex;
            .ant-card-body {
                display: flex;
            }
            .search-box {    
                display: block;
                width: 100%;            

                .title {
                    font-weight: normal !important;
                    font-size: 20px !important;
                    line-height: 28px !important;
                    display: flex;
                    align-items: center;
                    color: #262626 !important;
                }

                .description {
                    font-weight: normal !important;
                    font-size: 16px !important;
                    line-height: 24px !important;
                    display: flex;
                    align-items: center;
                    color: #525252 !important;
                    padding-bottom: 24px;
                }
                
                .search-input {
                    border-radius: 12px;  
                    span.ant-input-affix-wrapper.ant-input-affix-wrapper-lg {
                        border-top-left-radius: 12px;
                        border-bottom-left-radius: 12px;
                    }
                    button.ant-btn.ant-btn-primary.ant-btn-lg.ant-input-search-button {
                        border-top-right-radius: 12px;
                        border-bottom-right-radius: 12px;
                    }
                }
            }
        
            .calendar-picker {
                display: flex;
                align-items: flex-end;
                width: 100%;
                justify-content: flex-end;
                .ant-picker {
                    &.ant-picker-range {
                    &.dateRangePicker {
                        border-radius: 12px;
                        }
                    }
                }
                .radio-calendar-filter {
                    display: block;
                    .radio-group-filter{
                        display: flex;
                        justify-content: flex-end;                        
                        margin-bottom: -10px;
                    }
                }
            }
            

            .anticon-arrow-right {
                color: #A6A6A6;
            }

            .ant-table-content {

                    .table-item {

                        display: inline-flex;

                        .alphabetic-item {
                            width: 32px;
                            height: 32px;
                            background: #F5F5F5;
                            border-radius: 50%;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            text-transform: uppercase;
                            font-size: 14px;
                            line-height: 22px;
                            color: #525252;
                        }
                        .item-concat{
                            padding-left: 8px;
                            display: block;
                            font-weight: 600;
                            font-size: 14px;
                            line-height: 22px;
                            color: #262626;                           
                            text-align: left;

                            .email-item {
                                font-size: 12px;
                                line-height: 20px;
                                color: #525252;
                                font-weight: normal;
                            }                        
                        }
                    }
                    .ant-table-thead {
                        font-weight: 600!important;
                        font-size: 14px !important;
                        line-height: 22px!important;
                        color: #262626;
                    }   
                    
                    th.ant-table-cell {
                        font-weight: 600!important;
                        font-size: 14px !important;
                        line-height: 22px!important;
                        color: #262626;
                    }
            }

            .ant-tag
            {
                border-radius: 6px;
                border: none;
                font-weight: normal;
                font-size: 14px;
                line-height: 22px;
                align-items: center;

                &.active {
                    background: #E4F0D8;    
                    color: #3AB100;       
                }
                &.bank-connected {
                    background: #FCFFE6;
                    color: #7CB305;
                }
                &.bank-disconnected {
                    background: #FFF7E6;
                    color: #FA8C16;
                }
                &.delete-request {
                    background: #FFF1F0;
                    color: #F5222D;
                }
                &.not-verified {
                    background: #F5F5F5;
                    color: #737373;
                }
                &.onboarding {
                    background:#F0F5FF;
                    color: #2F54EB;
                }
                &.not-identified{
                    background:#F0F5FF;
                    color: #2F54EB;
                }
            }
        }
    }
}
@backgroud-color: #E8E8E8;@primary-color: #3AB100;@primary-border: #58BD1E;@primary-background: #3AB100;@primary-hover: #E4F0D8;@primary-border-hover: #97D667;@info-color: #12b1d6;@warning-color: #FA8C16;@error-primary: #F5222D;@error-border: #FFA39E;@error-background: #FFF1F0;@white: #FFFFFF;@gray-border: #BFBFBF;@gray-background: #E6E6E6;@card-background: #FFFFFF;@text-color: #525252;