.login-form {
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 24px;
  background: #FFFFFF;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
  border-radius: 12px;
  margin-top: 24px;
}
.login-form .header-title {
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #262626;
  flex: none;
  order: 0;
  align-self: center;
  flex-grow: 0;
  margin: 0px 24px;
}
.login-form .form {
  margin-top: 24px;
}
.login-form .form .email-input {
  background: #FFFFFF;
  border: 1px solid #BFBFBF;
  box-sizing: border-box;
  border-radius: 12px;
}
.login-form .form .password-input {
  background: #FFFFFF;
  border: 1px solid #BFBFBF;
  box-sizing: border-box;
  border-radius: 12px;
}
.login-form .form .login-remember-me {
  display: inline;
  height: 22px;
  left: 24px;
  right: 0px;
  top: calc(50% - 22px/2);
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  color: #525252;
  flex: none;
  order: 1;
  align-self: center;
  flex-grow: 0;
  margin: 8px 0px;
}
.login-form .form .login-remember-me:hover {
  border-color: #3AB100;
}
.login-form .form .login-remember-me .ant-checkbox-wrapper:hover {
  border-color: #3AB100;
}
.login-form .form .login-remember-me .ant-checkbox-checked .ant-checkbox-inner {
  background-color: #3AB100;
  border-color: #3AB100;
}
.login-form .form .login-remember-me .ant-checkbox-wrapper:hover .ant-checkbox-inner {
  border-color: #3AB100;
}
.login-form .form .login-remember-me .ant-checkbox:hover .ant-checkbox-inner {
  border-color: #3AB100;
}
.login-form .form .login-remember-me .ant-checkbox-input:focus .ant-checkbox-inner {
  border-color: #3AB100;
}
.login-form .form .login-fogot-password {
  width: 112px;
  height: 22px;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  color: #3AB100;
  float: right;
}
.login-form .login-submit-button {
  padding: 8px 16px;
  box-sizing: border-box;
  border-radius: 12px;
}
.login-form .login-submit-button.disabled {
  background: #E6E6E6;
  border: 1px solid #BFBFBF;
  border-color: #BFBFBF;
}
.login-form .login-submit-button.disabled:hover {
  border-color: #BFBFBF;
}
.login-form .login-submit-button.active {
  background: #3AB100;
  border-radius: 12px;
  border: 1px solid #3AB100;
}
.login-form .login-submit-button.active:hover {
  border-color: #3AB100;
}
.login-form .error-alert {
  margin-top: 24px;
  background: #FFF1F0;
  border: 1px solid #FFA39E;
  box-sizing: border-box;
  border-radius: 12px;
}
.login-form .error-alert .ant-alert-message {
  padding-right: 10px;
}
.header-logo {
  max-width: 125.44px;
  height: 56px;
}
.footer-login {
  margin-top: 32px;
  flex: none;
  order: 1;
  align-self: center;
  flex-grow: 0;
  padding: 10px;
}
.footer-login .footer-content {
  position: static;
  width: 338px;
  height: 22px;
  left: 12px;
  top: 426px;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  color: #525252;
}
.reset-password-widget {
  max-width: 365px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.reset-password-widget .forgot-password-form {
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 24px;
  background: #FFFFFF;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
  border-radius: 12px;
  margin-top: 32px;
}
.reset-password-widget .forgot-password-form .content {
  display: flex;
  justify-content: center;
  padding: none !important;
}
.reset-password-widget .forgot-password-form .header-title {
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
  text-align: center;
  color: #525252;
  margin-top: 16px;
  margin-bottom: 24px;
}
.reset-password-widget .forgot-password-form .subheader-title {
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  text-align: center;
  color: #525252;
  margin: 0px 16px;
}
.reset-password-widget .forgot-password-form .form {
  margin-top: 24px;
}
.reset-password-widget .forgot-password-form .form.reset-password-popup {
  display: block;
}
.reset-password-widget .forgot-password-form .form .email-input {
  background: #FFFFFF;
  border: 1px solid #BFBFBF;
  box-sizing: border-box;
  border-radius: 12px;
}
.reset-password-widget .forgot-password-form .form .forgot-submit-button {
  padding: 8px 16px;
  box-sizing: border-box;
  border-radius: 12px;
}
.reset-password-widget .forgot-password-form .form .forgot-submit-button.disabled {
  background: #E6E6E6;
  border: 1px solid #BFBFBF;
  border-color: #BFBFBF;
}
.reset-password-widget .forgot-password-form .form .forgot-submit-button.disabled:hover {
  border-color: #BFBFBF;
}
.reset-password-widget .forgot-password-form .form .forgot-submit-button.active {
  background: #3AB100;
  border-radius: 12px;
  border: 1px solid #3AB100;
}
.reset-password-widget .forgot-password-form .form .forgot-submit-button.active:hover {
  border-color: #3AB100;
}
.reset-password-widget .forgot-password-form .instruction-sent-form {
  display: flex;
  justify-content: center;
}
.reset-password-widget .forgot-password-form .instruction-sent-form .content {
  display: flex;
  justify-content: center;
}
.reset-password-widget .forgot-password-form .instruction-sent-form .header-title {
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
  margin-top: 19px;
  margin-bottom: 16px;
}
.reset-password-widget .forgot-password-form .instruction-sent-form .header-title .resent {
  color: #52C41A;
}
.reset-password-widget .forgot-password-form .instruction-sent-form .subheader-title {
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  color: #525252;
  margin: 0px 0px;
}
.reset-password-widget .forgot-password-form .instruction-sent-form .circle-check {
  color: #52C41A !important;
  max-width: 42px;
  max-height: 42px;
}
.reset-password-widget .forgot-password-form .instruction-sent-form .circle-check svg {
  width: 42px;
  height: 42px;
}
.reset-password-widget .forgot-password-form .instruction-sent-form .resend-link {
  display: flex;
  text-align: center;
  margin-top: 40px;
  color: #525252;
  justify-content: center;
  align-items: baseline;
}
.reset-password-widget .forgot-password-form .instruction-sent-form .resend-link .resend-anchor-link {
  padding-left: 5px;
  color: #52C41A;
}
.reset-password-widget .forgot-password-form .instruction-sent-form .button-redirect {
  display: contents;
}
.reset-password-widget .forgot-password-form .instruction-sent-form .button-redirect .forgot-submit-button {
  margin-top: 24px;
}
.reset-password-widget .forgot-password-form .instruction-sent-form .button-redirect .forgot-submit-button.disabled {
  background: #E6E6E6;
  border: 1px solid #BFBFBF;
  border-color: #BFBFBF;
}
.reset-password-widget .forgot-password-form .instruction-sent-form .button-redirect .forgot-submit-button.disabled:hover {
  border-color: #BFBFBF;
}
.reset-password-widget .forgot-password-form .instruction-sent-form .button-redirect .forgot-submit-button.active {
  background: #3AB100;
  border-radius: 12px;
  border: 1px solid #3AB100;
}
.reset-password-widget .forgot-password-form .instruction-sent-form .button-redirect .forgot-submit-button.active:hover {
  border-color: #3AB100;
}
.reset-password-widget .forgot-password-form .error-alert {
  margin-top: 24px;
  background: #FFF1F0;
  border: 1px solid #FFA39E;
  box-sizing: border-box;
  border-radius: 12px;
  max-width: 314px;
}
.reset-password-widget .forgot-password-form .error-alert .ant-alert-message {
  padding-right: 10px;
}
.reset-password-widget .forgot-password-form .ant-layout,
.reset-password-widget .forgot-password-form .ant-layout {
  display: block;
}
.site-layout .site-layout-background {
  background: #E8E8E8;
}
.margin-right {
  margin-right: 8px;
}
.margin-top {
  margin-top: 5px;
}
.margin-left {
  margin-left: 15px;
}
.rounded-button {
  background: #FFFFFF;
  border: 1px solid #BFBFBF;
  box-sizing: border-box;
  border-radius: 11px;
  color: #525252;
  font-weight: normal;
  font-size: 1em;
  cursor: pointer;
  line-height: -24px;
}
.rounded-button-filled {
  background: #58BD1E !important;
  border: 0 solid white;
  color: white !important;
  border-radius: 15px !important;
  font-weight: bold !important;
}
.left-divider {
  border-left: 1px solid;
}
.statistics-item-expanded-title {
  margin-left: 45px;
  margin-top: 22px;
}
.statistics-item-expanded-value {
  margin-left: 45px;
}
.statistic-item-wrapper {
  display: inherit;
  width: 100%;
}
.statistic-item-wrapper .inherit {
  display: inherit;
}
.statistic-item-wrapper .statistics-item-badge-wrapper {
  display: inherit;
  flex-direction: column;
  justify-content: center;
}
.rounded-button-stealth {
  border: none;
  color: #BFBFBF;
  font-size: 1em;
}
.rounded-button-stealth:hover,
.rounded-button-stealth:active {
  color: #58BD1E;
}
.rounded-button-filled:hover {
  background: #97D667;
}
.rounded-button-exit {
  user-select: none;
  border-radius: 15px;
  font-size: 0.85em;
  padding-left: 0.65em;
  padding-right: 0.65em;
}
.rounded-button-exit:hover {
  border: 1px solid #FFA39E;
  color: #FFA39E;
}
.rounded-button-exit:active {
  border: 1px solid #FA8C16;
  color: #FA8C16;
}
.home-content {
  padding: 22px 32px 147px 32px;
  display: flex;
  width: 100%;
  font-weight: normal;
  font-family: "Inter", sans-serif;
  font-size: 14px;
  line-height: 22px;
  color: #737373;
}
@media (max-width: 1199px) {
  .home-content .title-item {
    font-weight: normal !important;
    font-size: 0.8em !important;
    line-height: 0.9em !important;
    color: #737373 !important;
    display: flex;
    align-self: center;
    align-items: center;
    vertical-align: middle;
  }
}
@media (min-width: 1200px) and (max-width: 1600px) {
  .home-content .title-item {
    font-weight: normal !important;
    font-size: 1em !important;
    line-height: 1.05em !important;
    color: #737373 !important;
    display: flex;
    align-self: center;
    align-items: center;
    vertical-align: middle;
  }
}
@media (min-width: 1600px) {
  .home-content .title-item {
    font-weight: normal !important;
    font-size: 1.2em !important;
    line-height: 1.15em !important;
    color: #737373 !important;
    display: flex;
    align-self: center;
    align-items: center;
    vertical-align: middle;
  }
}
.home-content .value-item {
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 1.2em !important;
  line-height: 1.3em !important;
  color: #262626 !important;
}
.home-content .value-item.amount {
  font-size: 1.3em !important;
  line-height: 2em !important;
}
.home-content .badge-item .ant-badge-status-dot {
  width: 14px;
  height: 14px;
  display: inherit;
  top: 10px;
  align-self: center;
  align-items: center;
}
.home-content .chart-content {
  width: 81px;
  height: 80px;
}
.home-content .chart-content.goal-statistics {
  width: 112px;
  height: 112px;
}
.home-content .home-chart-content {
  width: 174px;
  height: 174px;
}
.home-content.statistics {
  width: 100%;
}
.home-content.statistics .chart-content {
  width: 112px;
  height: 112px;
}
.home-content .stats-details {
  padding-left: 11px;
}
.home-content .stats-details .more-details-link {
  user-select: none;
  cursor: pointer !important;
  color: #D1D1D1 !important;
  font-weight: normal !important;
}
.home-content .stats-details .more-details-link:hover {
  color: #97D667 !important;
}
.home-content .stats-details .more-details-link:active {
  color: #FA8C16 !important;
}
.home-content .value-item {
  font-weight: 600 !important;
  font-size: 1.3em !important;
  line-height: 1em !important;
  color: #262626 !important;
}
.home-content .circle-icon {
  width: 40px;
  height: 40px;
  background: #F5F5F5;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  vertical-align: text-bottom;
}
.home-content .centered-column {
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
}
.home-content .col-divider-right {
  border-right: 1px solid #E8E8E8;
}
.home-content .col-padding-left {
  padding-left: 25px;
}
.home-content .col-padding-right {
  padding-right: 25px;
}
.home-content .row-card-content {
  align-items: stretch;
  width: 100%;
}
.home-content .row-card-content .center-flex {
  display: inline-flex;
  width: 100%;
  justify-content: center;
}
.home-content .row-card-content .card-column {
  flex: 1;
}
.home-content .row-card-content .card-column .card-item {
  height: 100%;
  width: 100%;
  border-radius: 12px;
}
.home-content .row-card-content .card-column .card-item .ant-tabs-tab-btn {
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
}
.home-content .row-card-content .card-column .card-item .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
}
.home-content .row-card-content .card-column .card-item .row-tab-content .home-row-item-margin-top {
  margin-top: 12px;
}
.home-content .row-card-content .card-column .card-item .row-tab-content .home-row-item-margin-top-main {
  margin-top: 30px;
}
.home-content .row-card-content .card-column .card-item .row-tab-content .home-row-item-margin-left {
  margin-left: 1.5em;
}
.home-content .row-card-content .card-column .card-item .row-tab-content .home-row-item-total {
  display: block;
}
.home-content .row-card-content .card-column .card-item .row-tab-content .home-row-item-total .value-item {
  font-weight: 600 !important;
  font-size: 1.3em !important;
  line-height: 1em !important;
  color: #262626 !important;
}
.home-content .row-card-content .card-column .card-item .row-tab-content .home-row-item-total .inline-block {
  display: inline-block;
}
.home-content .row-card-content .card-column .card-item .row-tab-content .home-row-item-total .value-item-main {
  font-weight: 600 !important;
  font-size: 1.85em !important;
  line-height: 1.3em !important;
  color: #262626 !important;
}
.home-content .row-card-content .card-column .card-item .row-tab-content .home-row-item-total .value-item-center {
  text-align: center;
}
.home-content .row-card-content .card-column .card-item .row-tab-content .home-row-item-simple {
  display: inline-flex;
  width: 100%;
}
@media (max-width: 1600px) {
  .home-content .row-card-content .card-column .card-item .row-tab-content .home-row-item-simple .chart-content-transaction {
    width: 6em;
    height: 6em;
  }
}
@media (min-width: 1600px) {
  .home-content .row-card-content .card-column .card-item .row-tab-content .home-row-item-simple .chart-content-transaction {
    width: 9em;
    height: 9em;
  }
}
.home-content .row-card-content .card-column .card-item .row-tab-content .home-row-item-right-block {
  display: inline-block;
  text-align: right;
}
.home-content .row-card-content .card-column .card-item .row-tab-content .home-row-item-margin-left {
  margin-left: 25px;
}
.home-content .row-card-content .card-column .card-item .row-tab-content .home-row-item-padding-top {
  padding-top: 16px !important;
}
.home-content .row-card-content .card-column .card-item .row-tab-content .home-row-item {
  width: 100%;
  padding: 0 0 0 0 !important;
}
.home-content .row-card-content .card-column .card-item .row-tab-content .home-row-item.center {
  display: flex;
  justify-content: center;
}
.home-content .row-card-content .card-column .card-item .row-tab-content .home-row-item.vcenter {
  align-items: center;
}
.home-content .row-card-content .card-column .card-item .row-tab-content .home-row-item.bottom {
  margin-bottom: 22px;
}
.home-content .row-card-content .card-column .card-item .row-tab-content .home-row-item .home-row-item-center {
  display: inline-flex;
  justify-content: center;
  align-items: center;
}
.home-content .row-card-content .card-column .card-item .row-tab-content .home-row-item .value-item-center-flex {
  font-weight: 600 !important;
  font-size: 36px !important;
  line-height: 32px !important;
  color: #262626 !important;
  justify-content: center;
}
.home-content .row-card-content .card-column .card-item .row-tab-content .home-row-item .home-row-item-center-block {
  display: inline-block;
  text-align: center;
  align-items: center;
}
.home-content .row-card-content .card-column .card-item .row-tab-content.inline .ant-card-body {
  display: inline-flex;
}
.home-content .row-card-content .card-column .card-item .row-tab-content.inline .ant-card-body .circle-icon {
  width: 40px;
  height: 40px;
  background: #F5F5F5;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.home-content .row-card-content .card-column .card-item .row-tab-content.inline .tab-content {
  padding-left: 12px;
  display: block;
}
.home-content .row-card-content .card-column .card-item .row-tab-content.inline .tab-content .value-item {
  font-weight: 600 !important;
  font-size: 22px !important;
  line-height: 28px !important;
  color: #262626;
}
.delete-user .ant-message-notice-content {
  background: black;
  color: white;
}
.delete-modal-wrapper {
  display: inline-block;
  width: 100%;
}
.delete-modal-wrapper .delete-modal-text-wrapper-left {
  display: inline-block;
  text-align: center;
  width: 14%;
  vertical-align: text-bottom;
}
.delete-modal-wrapper .delete-modal-text-wrapper-left .delete-modal-icon {
  color: #F5222D;
  font-size: 20px;
}
.delete-modal-wrapper .delete-modal-text-wrapper-right {
  display: inline-block;
  width: 86%;
  font-size: 16px;
  vertical-align: top;
  color: #262626;
  line-height: 24px;
  font-weight: bold;
}
.delete-modal-button-wrapper {
  display: inline-block;
  width: 100%;
  margin-top: 24px;
  text-align: right;
}
.delete-button,
.delete-button:hover,
.delete-button:active {
  background: #FF4D4F !important;
  border-radius: 11px;
  color: #FFFFFF;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  border: none;
}
.cancel-deletion,
.cancel-deletion:hover,
.cancel-deletion:active {
  background: #FFFFFF !important;
  border: 1px solid #BFBFBF !important;
  box-sizing: border-box;
  border-radius: 11px;
  color: #525252 !important;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  margin-right: 10px;
}
.dashboard-content {
  display: block;
  padding: 22px 32px 147px 32px;
}
.dashboard-content .row-card {
  margin-top: 24px;
  display: block;
}
.dashboard-content .row-card .card-flex-grow {
  display: block;
  min-height: calc(100% - 24px);
}
.dashboard-content .row-card .user-card-item {
  background: #FFFFFF;
  border: 1px solid #BFBFBF;
  box-sizing: border-box;
  border-radius: 12px;
  margin-top: 24px;
}
.dashboard-content .row-card .user-card-item .user-name-row {
  display: flex;
}
.dashboard-content .row-card .user-card-item .user-name-row .initials-item {
  width: 72px;
  height: 72px;
  background: #F5F5F5;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
}
.dashboard-content .row-card .user-card-item .user-name-row .name-item {
  display: flex;
  padding-left: 12px;
  justify-content: center;
  align-items: center;
}
.dashboard-content .row-card .user-card-item .user-name-row .name-item .fullname-item {
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
  color: #262626;
}
.dashboard-content .row-card .user-card-item .user-name-row .name-item .email-item {
  font-weight: normal;
  display: block;
  font-size: 14px;
  line-height: 22px;
  color: #525252;
}
.dashboard-content .row-card .user-card-item .details-row-card-item .gutter-row {
  width: 100%;
  padding: 24px;
}
.dashboard-content .row-card .user-card-item .details-row-card-item .gutter-row .user-field-item {
  font-weight: 600 !important;
  font-size: 14px !important;
  line-height: 22px !important;
  color: #262626 !important;
  padding-top: 24px;
}
.dashboard-content .row-card .user-card-item .details-row-card-item .gutter-row .user-field-item.date {
  font-weight: normal !important;
  font-size: 14px !important;
  line-height: 22px !important;
  color: #737373 !important;
}
.dashboard-content .row-card .user-card-item .details-row-card-item .gutter-row .user-field-value {
  font-size: 14px !important;
  line-height: 22px !important;
  color: #525252 !important;
}
.dashboard-content .row-card .user-card-item .details-row-card-item .gutter-row .user-field-value .bank-connected {
  background: #FCFFE6;
  color: #7CB305;
  border: none;
}
.dashboard-content .row-card .user-card-item .details-row-card-item .gutter-row .user-field-value .bank-disconnected {
  background: #FFF7E6;
  color: #FA8C16;
  border: none;
}
.dashboard-content .row-card .user-card-item .details-row-card-item .gutter-row .user-field-value .not-identified {
  background: #FCFFE6;
  color: #7CB305;
  border: none;
}
.dashboard-content .row-card .user-card-item .user-status {
  display: block;
  float: right;
}
.dashboard-content .row-card .user-card-item .user-status .user-status-tag {
  float: right;
  padding-bottom: 16px;
}
.dashboard-content .row-card .user-card-item .user-status .user-status-buttons {
  display: block;
}
.dashboard-content .row-card .user-card-item .user-status .user-status-buttons .initiate-deletion-button {
  border-radius: 12px;
  background: white;
  color: #FF4D4F;
  border: 1px solid #FF4D4F;
}
.dashboard-content .row-card .user-card-item .user-status .user-status-buttons .cancel-request-deletion {
  background: #FFFFFF;
  border: 1px solid #BFBFBF;
  box-sizing: border-box;
  border-radius: 11px;
  margin-right: 12px;
  color: #525252;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
}
.dashboard-content .row-card .user-card-item .user-status .user-status-buttons .delete-button {
  background: #FF4D4F;
  border-radius: 11px;
  color: #FFFFFF;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  border: none;
}
.dashboard-content .row-card .user-card-item .user-status .user-status-buttons .delete-button-disabled {
  background: #BFBFBF;
  border-radius: 11px;
  color: #FFFFFF;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  border: none;
}
.dashboard-content .row-card.balance-row {
  display: flex;
}
.dashboard-content .row-card.balance-row .card-item {
  background: #FFFFFF;
  border: 1px solid #BFBFBF;
  box-sizing: border-box;
  border-radius: 12px;
}
.dashboard-content .row-card.balance-row .card-item .balance-item {
  text-align: center;
  justify-content: center;
  align-items: center;
  display: flex;
  width: 100%;
}
.dashboard-content .row-card.balance-row .card-item .balance-item .balance-value {
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
  color: #525252;
}
.dashboard-content .row-card.balance-row .card-item .balance-item .balance-name {
  font-weight: normal;
  font-size: 11px;
  line-height: 18px;
  color: #737373;
}
.dashboard-content .row-card .card-item {
  display: flex;
}
.dashboard-content .row-card .card-item .saving-rules {
  display: flex;
  flex-grow: 1;
  width: 100%;
  justify-content: flex-start;
}
.dashboard-content .row-card .card-item .saving-rules .title {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  text-align: right;
  color: #262626;
}
.dashboard-content .row-card .card-item .saving-rules .saving-rules-details {
  display: block;
  width: 100%;
  font-size: 14px;
  line-height: 22px;
  color: #525252;
  padding-top: 8px;
}
.dashboard-content .row-card .card-item .saving-rules .saving-rules-details .saving-percentage {
  padding: 0px 32px 0px 0px;
}
.dashboard-content .row-card .card-item .saving-rules .saving-rules-details .spending-limit {
  padding: 0px 0px 0px 32px;
}
.dashboard-content .row-card .card-item .saving-rules .saving-rules-details .change-link {
  float: right;
}
.dashboard-content .row-card .card-item .searchbox {
  padding: 24px 0px 24px 0px;
  border-radius: 12px;
  width: 30%;
}
.dashboard-content .row-card .card-item .searchbox .ant-input.ant-input-lg {
  border-top-left-radius: 12px;
  border-bottom-left-radius: 12px;
}
.dashboard-content .row-card .card-item .searchbox span.ant-input-affix-wrapper.ant-input-affix-wrapper-lg {
  border-top-left-radius: 12px !important;
  border-bottom-left-radius: 12px !important;
}
.dashboard-content .row-card .card-item .searchbox button.ant-btn.ant-btn-lg.ant-btn-icon-only.ant-input-search-button {
  border-top-right-radius: 12px !important;
  border-bottom-right-radius: 12px !important;
}
.dashboard-content .row-card .card-item .move-money-type {
  padding-right: 12.5px;
}
.dashboard-content .row-card .card-item .move-money-type .arrow-destination {
  padding: 3.5px;
}
.dashboard-content .row-card .card-item .move-money-type .destination {
  color: #3AB100;
}
.dashboard-content .row-card .card-item .ant-tabs-tab-btn {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
}
.dashboard-content .row-card .card-item .goal-list {
  display: flex;
  flex-grow: 1;
  width: 100%;
}
.dashboard-content .row-card .user-field-item {
  font-weight: 600 !important;
  font-size: 14px !important;
  line-height: 22px !important;
  color: #262626 !important;
  padding-top: 24px;
}
.dashboard-content .row-card .user-field-item.date {
  font-weight: normal !important;
  font-size: 14px !important;
  line-height: 22px !important;
  color: #737373 !important;
}
.dashboard-content .row-card .user-field-value {
  font-size: 14px !important;
  line-height: 22px !important;
  color: #525252 !important;
}
.dashboard-content .row-card .user-field-value .bank-connected {
  background: #FCFFE6;
  color: #7CB305;
  border: none;
}
.dashboard-content .row-card .user-field-value .bank-disconnected {
  background: #FFF7E6;
  color: #FA8C16;
  border: none;
}
.dashboard-content .row-card .user-field-value .not-identified {
  background: #FFF7E6;
  color: #FA8C16;
  border: none;
}
.dashboard-content .refresh-row-card {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.dashboard-content .ant-card-body {
  width: 100%;
}
.dashboard-content .ant-table-wrapper {
  width: 100%;
}
.dashboard-content .ud-collapsed {
  max-height: 60px;
  height: initial;
  overflow: hidden;
}
.dashboard-content .ud-collapsed .ant-card-body {
  display: none !important;
}
.dashboard-content .ud-expanded {
  height: auto;
}
.delete-button,
.delete-button:hover,
.delete-button:active {
  background: #FF4D4F !important;
  border-radius: 11px;
  color: #FFFFFF;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  border: none;
}
.cancel-deletion,
.cancel-deletion:hover,
.cancel-deletion:active {
  background: #FFFFFF;
  border: 1px solid #BFBFBF !important;
  box-sizing: border-box;
  border-radius: 11px;
  color: #525252;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
}
.dashboard-content {
  display: block;
  padding: 22px 32px 147px 32px;
}
.dashboard-content .row-card {
  margin-top: 24px;
  display: block;
}
.dashboard-content .row-card .result-details {
  font-weight: 600 !important;
  font-size: 16px !important;
  line-height: 24px !important;
  display: flex;
  align-items: center;
  color: #262626 !important;
}
.dashboard-content .row-card .card-item {
  background: #FFFFFF;
  border: 1px solid #BFBFBF;
  box-sizing: border-box;
  border-radius: 12px;
  margin-top: 24px;
  display: flex;
}
.dashboard-content .row-card .card-item .ant-card-body {
  display: flex;
}
.dashboard-content .row-card .card-item .search-box {
  display: block;
  width: 100%;
}
.dashboard-content .row-card .card-item .search-box .title {
  font-weight: normal !important;
  font-size: 20px !important;
  line-height: 28px !important;
  display: flex;
  align-items: center;
  color: #262626 !important;
}
.dashboard-content .row-card .card-item .search-box .description {
  font-weight: normal !important;
  font-size: 16px !important;
  line-height: 24px !important;
  display: flex;
  align-items: center;
  color: #525252 !important;
  padding-bottom: 24px;
}
.dashboard-content .row-card .card-item .search-box .search-input {
  border-radius: 12px;
}
.dashboard-content .row-card .card-item .search-box .search-input span.ant-input-affix-wrapper.ant-input-affix-wrapper-lg {
  border-top-left-radius: 12px;
  border-bottom-left-radius: 12px;
}
.dashboard-content .row-card .card-item .search-box .search-input button.ant-btn.ant-btn-primary.ant-btn-lg.ant-input-search-button {
  border-top-right-radius: 12px;
  border-bottom-right-radius: 12px;
}
.dashboard-content .row-card .card-item .calendar-picker {
  display: flex;
  align-items: flex-end;
  width: 100%;
  justify-content: flex-end;
}
.dashboard-content .row-card .card-item .calendar-picker .ant-picker.ant-picker-range.dateRangePicker {
  border-radius: 12px;
}
.dashboard-content .row-card .card-item .calendar-picker .radio-calendar-filter {
  display: block;
}
.dashboard-content .row-card .card-item .calendar-picker .radio-calendar-filter .radio-group-filter {
  display: flex;
  justify-content: flex-end;
  margin-bottom: -10px;
}
.dashboard-content .row-card .card-item .anticon-arrow-right {
  color: #A6A6A6;
}
.dashboard-content .row-card .card-item .ant-table-content .table-item {
  display: inline-flex;
}
.dashboard-content .row-card .card-item .ant-table-content .table-item .alphabetic-item {
  width: 32px;
  height: 32px;
  background: #F5F5F5;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  font-size: 14px;
  line-height: 22px;
  color: #525252;
}
.dashboard-content .row-card .card-item .ant-table-content .table-item .item-concat {
  padding-left: 8px;
  display: block;
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  color: #262626;
  text-align: left;
}
.dashboard-content .row-card .card-item .ant-table-content .table-item .item-concat .email-item {
  font-size: 12px;
  line-height: 20px;
  color: #525252;
  font-weight: normal;
}
.dashboard-content .row-card .card-item .ant-table-content .ant-table-thead {
  font-weight: 600!important;
  font-size: 14px !important;
  line-height: 22px!important;
  color: #262626;
}
.dashboard-content .row-card .card-item .ant-table-content th.ant-table-cell {
  font-weight: 600!important;
  font-size: 14px !important;
  line-height: 22px!important;
  color: #262626;
}
.dashboard-content .row-card .card-item .ant-tag {
  border-radius: 6px;
  border: none;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  align-items: center;
}
.dashboard-content .row-card .card-item .ant-tag.active {
  background: #E4F0D8;
  color: #3AB100;
}
.dashboard-content .row-card .card-item .ant-tag.bank-connected {
  background: #FCFFE6;
  color: #7CB305;
}
.dashboard-content .row-card .card-item .ant-tag.bank-disconnected {
  background: #FFF7E6;
  color: #FA8C16;
}
.dashboard-content .row-card .card-item .ant-tag.delete-request {
  background: #FFF1F0;
  color: #F5222D;
}
.dashboard-content .row-card .card-item .ant-tag.not-verified {
  background: #F5F5F5;
  color: #737373;
}
.dashboard-content .row-card .card-item .ant-tag.onboarding {
  background: #F0F5FF;
  color: #2F54EB;
}
.dashboard-content .row-card .card-item .ant-tag.not-identified {
  background: #F0F5FF;
  color: #2F54EB;
}
.notification-content {
  display: flex;
  width: 100%;
  font-weight: normal;
  font-family: "Inter", sans-serif;
  justify-content: center;
  font-size: 14px;
  flex-wrap: wrap;
  line-height: 22px;
  color: #737373;
}
.notification-content .nt-row-card-content {
  margin: 2% !important;
  width: 96%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.notification-content .nt-row-card-content .notification-item-right {
  justify-content: flex-end !important;
}
.notification-content .nt-row-card-content .notification-item-center {
  justify-content: center !important;
}
.notification-content .nt-row-card-content .notification-item {
  display: inline-flex;
  flex-direction: row;
  flex-grow: 1;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 24px;
}
.notification-content .nt-row-card-content .notification-item .search-wrapper {
  display: inline-flex;
  max-width: 50%;
}
.notification-content .nt-row-card-content .notification-item .search-wrapper .search-input {
  border-radius: 12px;
}
.notification-content .nt-row-card-content .notification-item .search-wrapper .search-input span.ant-input-affix-wrapper.ant-input-affix-wrapper-lg {
  border-top-left-radius: 12px;
  border-bottom-left-radius: 12px;
}
.notification-content .nt-row-card-content .notification-item .search-wrapper .search-input button.ant-btn.ant-btn-primary.ant-btn-lg.ant-input-search-button {
  border-top-right-radius: 12px;
  border-bottom-right-radius: 12px;
}
.notification-content .nt-row-card-content .notification-item .notification-template-title {
  display: inline-flex;
  flex-direction: row;
  width: 80%;
  font-size: 1.4em;
  justify-content: flex-start;
  padding-left: 2.5%;
  color: black;
}
.notification-content .nt-row-card-content .notification-list-sender {
  display: block;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: flex-start !important;
  vertical-align: text-bottom;
  padding: 0 !important;
}
.notification-content .nt-row-card-content .notification-list-sender .nt-tab-row-card-content {
  align-items: stretch;
  margin: 0;
  flex: 1;
}
.notification-content .nt-row-card-content .notification-log-view {
  display: block;
  flex-wrap: wrap;
  width: 96%;
  flex-direction: column;
  justify-content: flex-start !important;
  vertical-align: text-bottom;
  padding: 0 !important;
}
.notification-content .nt-row-card-content .notification-log-view .nt-tab-row-card-content {
  align-items: stretch;
  margin: 0;
  flex: 1;
}
.notification-content .nt-row-card-content .is-flex-expanded {
  flex-grow: 1;
}
.notification-content .nt-row-card-content .notification-sender {
  width: 49.3%;
  margin-left: 1.4%;
  flex-direction: column;
  flex-wrap: wrap;
  padding: 0 !important;
}
.notification-content .nt-row-card-content .notification-sender .nt-template-view-row-item {
  margin: 0;
  padding: 0;
  width: 100%;
  line-height: 24px;
}
.notification-content .nt-row-card-content .notification-sender .nt-tab-row-card-content {
  align-items: stretch;
  margin: 0;
  flex: 1;
}
.notification-content .nt-row-card-content .notification-sender .nt-tab-row-card-content .nt-card-col {
  display: flex;
  flex: 1;
  width: 100%;
}
.notification-content .nt-row-card-content .notification-sender .nt-tab-row-card-content .nt-card-col .notification-button-wrapper {
  margin-top: 5vh;
  flex: 1;
  width: 100% !important;
  display: flex;
  justify-content: flex-end !important;
}
.notification-content .nt-row-card-content .notification-sender .nt-tab-row-card-content .nt-card-col .nt-card-item {
  height: 100%;
  width: 100%;
  flex: 1;
  border-radius: 12px;
}
.notification-content .nt-row-card-content .notification-sender .nt-tab-row-card-content .nt-card-col .nt-card-item .notification-tab {
  display: flex;
  flex: 1;
}
.notification-content .nt-row-card-content .nt-collapsed {
  max-height: 60px;
  height: initial;
  overflow: hidden;
}
.notification-content .nt-row-card-content .nt-collapsed .ant-card-body {
  display: none !important;
}
.notification-content .nt-row-card-content .nt-expanded {
  height: auto;
}
