
.logo-menu-layout{
  display: inline-flex;
  margin-top: 20px;
  margin-bottom: 13.5px;
  width: 100%;
}

.logo-menu-layout-expanded{
  justify-content: flex-start;
  padding-left: 20px;
}

.logo-menu-layout-collapsed{
  justify-content: center;
  padding-left: 0;
}
@backgroud-color: #E8E8E8;@primary-color: #3AB100;@primary-border: #58BD1E;@primary-background: #3AB100;@primary-hover: #E4F0D8;@primary-border-hover: #97D667;@info-color: #12b1d6;@warning-color: #FA8C16;@error-primary: #F5222D;@error-border: #FFA39E;@error-background: #FFF1F0;@white: #FFFFFF;@gray-border: #BFBFBF;@gray-background: #E6E6E6;@card-background: #FFFFFF;@text-color: #525252;