.suggestion-item {
    
    &.total {
 
        display: flex;
        align-items: center;
        justify-content: center;

        .total-suggestions-box {
            display: block;
            padding-bottom: 17px;   

            .icon-center {                
                display: flex;
                align-items: center;
                justify-content: center;
                padding-bottom: 17px;

                .icon-round {
                    border-radius: 50%;
                    background: #F5F5F5;   
                    width: 40px;
                    height: 40px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }             
            }
        
            .suggestion-details {        
                display: block;
                text-align: center;

                .title {
                    
                        font-size: 14px;
                        line-height: 22px;
                        text-align: center;
                        color: #737373;    
                        display: block;
                        padding-bottom: 5px;
                }

                .value {
                    display: contents;
                    text-align: center;
                    font-weight: 600;
                    font-size: 20px;
                    line-height: 28px;
                    color: #262626;
                }
            }
        }
    }
}

.ant-list-split .ant-list-item {
     border-bottom: none;
}
.ant-list-split .ant-list-item {
    border-bottom: none;
}

.header-title {
    font-size: 12px;
    line-height: 20px;
    color: #737373;
}
.index-item {
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
    color: #525252;
    .item-name {
        .item-count {
            color: #737373; 
            padding-left: 5px;
        }
    }
}
.collapsed-in {

    .ant-card-body {
        display: none !important;
    }
}
.collapsed-off{

    .ant-card-body  {
        display: block !important;
    }
}
.ant-card {
    border-radius: 12px;
  
}
.export-button {
    display: flex;
    justify-content: flex-end;
}
@backgroud-color: #E8E8E8;@primary-color: #3AB100;@primary-border: #58BD1E;@primary-background: #3AB100;@primary-hover: #E4F0D8;@primary-border-hover: #97D667;@info-color: #12b1d6;@warning-color: #FA8C16;@error-primary: #F5222D;@error-border: #FFA39E;@error-background: #FFF1F0;@white: #FFFFFF;@gray-border: #BFBFBF;@gray-background: #E6E6E6;@card-background: #FFFFFF;@text-color: #525252;