
.cashbackActivity-details {

    display: flex;
    padding: 24px 0px 0px 60px;
    
    .item-title {
        font-weight: normal;
        font-size: 14px;
        line-height: 22px;
        color: #737373;
    }
    .item-value {
        font-weight: normal;
        font-size: 14px;
        line-height: 22px;
        display: flex;
        align-items: center;
        color: #525252;
     
    }   

    .badge-status {
        color: #8034C7;
        font-weight: normal;
        font-size: 14px;
        line-height: 22px;
    }
}
@backgroud-color: #E8E8E8;@primary-color: #3AB100;@primary-border: #58BD1E;@primary-background: #3AB100;@primary-hover: #E4F0D8;@primary-border-hover: #97D667;@info-color: #12b1d6;@warning-color: #FA8C16;@error-primary: #F5222D;@error-border: #FFA39E;@error-background: #FFF1F0;@white: #FFFFFF;@gray-border: #BFBFBF;@gray-background: #E6E6E6;@card-background: #FFFFFF;@text-color: #525252;