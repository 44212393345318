
.subscription-plan-item {
    /* padding: 10px; */
    display: block;
    /* display: flex; */
    /* padding-left: 10px; */
    justify-content: center;
    padding: 10px;

    .title {
        display: block;
    }
}
.button-link {
    display: flex;
    justify-content:flex-end;
    text-align: center;
    width: 100%;
}

@backgroud-color: #E8E8E8;@primary-color: #3AB100;@primary-border: #58BD1E;@primary-background: #3AB100;@primary-hover: #E4F0D8;@primary-border-hover: #97D667;@info-color: #12b1d6;@warning-color: #FA8C16;@error-primary: #F5222D;@error-border: #FFA39E;@error-background: #FFF1F0;@white: #FFFFFF;@gray-border: #BFBFBF;@gray-background: #E6E6E6;@card-background: #FFFFFF;@text-color: #525252;