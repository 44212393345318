
.modal-header-title {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #262626;
}
.save-button {
    border-radius: 11px;
}
.cancel-button {
    border-radius: 11px;
}

.row-item {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 24px 0px 0px 0px;
    
    &.no-padding {
    padding: 0px 0px 0px 0px;

    }

    .affiliate-name {
        display: flex;
        width: 100%;

        .head-item {

            .affiliate-icon {
                
                .ant-upload.ant-upload-select.ant-upload-select-picture-card {
                    width: 92px;
                    height: 92px;
                    border-radius: 50%;
                }
                .render-uploaded-image{
                    width: 62px;
                }  
                &.error {
                    
                    .ant-upload.ant-upload-select.ant-upload-select-picture-card {
                        border-color: red;
                        .ant-upload{
                            color: red;
                        }
                    }
                }
            }
            &.name {
                width: 100%;
                .item-title {
                    font-size: 14px;
                    line-height: 22px;
                    color: #262626;
                    padding-bottom: 2px;
                }
            
                .item-input {
                    border-radius: 12px;
                    &.with-element {
                        .ant-input-group-addon:first-child {
                            border-right: 0;
                            border-top-left-radius: 12px !important;
                            border-bottom-left-radius: 12px !important;
                        }
                        .ant-input-group > .ant-input:last-child, .ant-input-group-addon:last-child {
                            border-top-right-radius: 12px !important;
                            border-bottom-right-radius: 12px !important;
                        }
                    }
                }
            
            }
        }
    }

    .item-title {
        font-size: 14px;
        line-height: 22px;
        color: #262626;
        padding-bottom: 2px;
    }

    .item-input {
        border-radius: 12px;
        &.with-element {
            .ant-input-group-addon:first-child {
                border-right: 0;
                border-top-left-radius: 12px !important;
                border-bottom-left-radius: 12px !important;
            }
            .ant-input-group > .ant-input:last-child, .ant-input-group-addon:last-child {
                border-top-right-radius: 12px !important;
                border-bottom-right-radius: 12px !important;
            }
        }
    }

    .main-title {
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        align-items: center;
        color: #262626;
    }

    .relation-options {
        display: flex;
        width: 100%;
        .platform-item {
            width: 100%;
            &.first-item {
                padding-right: 24px;
            }
            .item-select {
                display: block;
                .ant-select-selector {
                    border: 1px solid #BFBFBF;
                    box-sizing: border-box;
                    border-radius: 12px;
                }
            }
        }

    }

    .percentage-items {
        display: flex;
        padding-top: 12px;
        .percentage {

            &.first-item {
                padding-right: 24px;
            }
            .item-title {
                font-size: 14px;
                line-height: 22px;
                color: #262626;
                padding-bottom: 2px;
            }
        
            .item-input {
                border-radius: 12px;
                &.with-element-after {
                    .ant-input-group > .ant-input:first-child, .ant-input-group-addon:first-child {
                        border-top-left-radius: 12px;
                        border-bottom-left-radius: 12px;
                    }
                    .ant-input-group > .ant-input:last-child, .ant-input-group-addon:last-child {
                        border-top-right-radius: 12px !important;
                        border-bottom-right-radius: 12px !important;
                    }
                }
            }
        
        }
    }
    
    .new-promotion-section{
        display: flex;
        justify-content: space-between;
        align-items: baseline;
        align-content: space-between;
        width: 100%;   
        .remove-promotion {
            padding-bottom: 5px;
            .button-delete {
                margin-left: 5px;
                border-radius: 12px;
                border-color: #FF4D4F;
                &:hover, &:active {color: #FF4D4F;}    
            }
        }
    }

    &.status-row {       
        margin-top: 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        background: #F5F5F5;
        padding-top: 0px;
        padding-bottom: 0px;

        .status-field {
          padding:16px;
          .title-field {
              display: flex;
          }
        }
    }
}
@backgroud-color: #E8E8E8;@primary-color: #3AB100;@primary-border: #58BD1E;@primary-background: #3AB100;@primary-hover: #E4F0D8;@primary-border-hover: #97D667;@info-color: #12b1d6;@warning-color: #FA8C16;@error-primary: #F5222D;@error-border: #FFA39E;@error-background: #FFF1F0;@white: #FFFFFF;@gray-border: #BFBFBF;@gray-background: #E6E6E6;@card-background: #FFFFFF;@text-color: #525252;