@import "src/styles/_variables";

.toggle-menu {
    font-size: 1.5em;
    cursor: pointer;
    transition: color 0.3s;
  }

 .layout-height {
   height: 100vh;
 }
  .toggle-menu:hover {
    color: @primary-color;
  }
  
  .logo {
    margin: 16px;
    width: 71.68px;
    height: 32px;
    background: rgba(255, 255, 255, 0.3) url("../../../assets/icons/login-logo.png") round;
  }

  .logo-item {
    min-height: 60px;
    justify-content: center;
  }

  .header{
    padding: 0 !important;
    margin: 0 !important;

    .header-content {
      display: inline-flex;
      height: 100%;
      padding: 0;
      width: 100%;
      justify-content: flex-start;

      .header-content-left {
        display: inline-flex;
        height: 100%;
        width: 50%;
        padding: 0 28px 0;
        justify-content: flex-start;

        .menu-toggle-wrapper {
          display: inline-flex;
          height: 100%;
          flex-direction: column;
          justify-content: center;
        }
      }


      .header-content-right {
        display: inline-flex;
        width: 50%;
        height: 100%;
        justify-content: flex-end;
        padding-right: 28px;

        .link-style {
          text-decoration: none;
          color: @text-color;

          .avatar{
            background-color: @light-grey;
            color: @text-color;
            border: 1px solid @dark-grey;
            margin-top: -7px;

          }
          .avatar-content-wrapper {
            display: inline-flex;
            justify-content: center;
            flex-direction: column;
            height: 100%;
            margin-left: 15px;
            font-size: 20px;
            color: #262626;

            .avatar-wrapper {
              display: inline-flex;
              width: 100%;
              flex-direction: row;
              justify-content: flex-start;
              font-style: normal;
              font-weight: normal;
              font-size: 15px;
              line-height: 22px;
              color: #262626;

              .down-arrow {
                display: inline-flex;
                height: 100%;
                flex-direction: column;
                justify-content: center;
                font-size: 10px;
                padding-left: 7px;
              }
            }
          };

        }
      }
    }
  }

  .ant-layout-header{
    background: @white !important;
  }
  
  .site-layout .site-layout-background {
    background: @backgroud-color;
    display: flex;
  }

  .side-menu{
    background: white;
  }
 
  .ant-menu-item {
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 22px;
}
.ant-menu-item.ant-menu-item-selected {
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
}
@backgroud-color: #E8E8E8;@primary-color: #3AB100;@primary-border: #58BD1E;@primary-background: #3AB100;@primary-hover: #E4F0D8;@primary-border-hover: #97D667;@info-color: #12b1d6;@warning-color: #FA8C16;@error-primary: #F5222D;@error-border: #FFA39E;@error-background: #FFF1F0;@white: #FFFFFF;@gray-border: #BFBFBF;@gray-background: #E6E6E6;@card-background: #FFFFFF;@text-color: #525252;