.add-new-platform {
  font-size: 14px;
  line-height: 22px;
  color: #3AB100;

  .add-new-icon {
    padding-right: 5px;
  }
}

.action-icons {
  display: flex;
  justify-content: flex-end;

  .icon-circle {
    background: #FFFFFF;
    border: 1px solid #BFBFBF;
    box-sizing: border-box;
    border-radius: 10px;
    margin-right: 5px;

    .anticon {
      width: 24px;
      padding: 5px;
    }
  }
}

.title {
  font-size: 14px;
  line-height: 22px;
  color: #424242;
  
}

.platform-icon {
  max-height: 24px;
  max-width: 24px;
  border-radius: 50%;
}


.modal {
  .ant-modal-header {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }

  .ant-modal-content {
    border-radius: 10px;
  }

  .row-modal {
    display: flex;
    padding-bottom: 30px;

    .title-field {
      font-size: 14px;
      line-height: 22px;
      color: #262626;
      display: flex;
      padding-bottom: 5px;
    }

    .name {
      background: #FFFFFF;
      border: 1px solid #BFBFBF;
      box-sizing: border-box;
      border-radius: 12px;
    }

    &.upload-row {
      display: block;
      padding-bottom: 5px;

      .upload-button {
        border: 1px solid #BFBFBF;
        box-sizing: border-box;
        border-radius: 11px;
      }
    }

    &.status-row {
      margin-top: 32px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      background: #F5F5F5;
      padding-bottom: 0px;
      .status-field {
        padding:16px;
      }
    }
  }

  .image-description {
    font-size: 12px;
    line-height: 20px;
  }

  .footer-cancel-button {
    border: 1px solid #BFBFBF;
    box-sizing: border-box;
    border-radius: 11px;
  }

  .footer-submit-button {
    border-radius: 11px;
  }

  .edit-platform-image {
    border: 1px solid #BFBFBF;
    box-sizing: border-box;
    border-radius: 2px;
    height: 48px;
    display: flex;
    align-items: center;
  }


  .anticon.anticon-delete.delete-icon-modal {
    color: #F5222D;
  }

}

.ant-card {
  &.ant-card-bordered {
      &.collapsed-off {
        .ant-card-body  {
          display: block !important;
        }
        height: 100%;
      }
      &.collapsed-in {
        .ant-card-body {
          display: none !important;
        }
        height: auto;
      }
  }
}

.disabled-row {
  background-color: #dcdcdc;
  &:hover {background-color: #dcdcdc;}  
  &:active   {background-color: #dcdcdc;}  
}


.platform-table {
  .ant-table-thead {
    display: none;
  }
}
@backgroud-color: #E8E8E8;@primary-color: #3AB100;@primary-border: #58BD1E;@primary-background: #3AB100;@primary-hover: #E4F0D8;@primary-border-hover: #97D667;@info-color: #12b1d6;@warning-color: #FA8C16;@error-primary: #F5222D;@error-border: #FFA39E;@error-background: #FFF1F0;@white: #FFFFFF;@gray-border: #BFBFBF;@gray-background: #E6E6E6;@card-background: #FFFFFF;@text-color: #525252;