.delete-user{
  .ant-message-notice-content{
    background: black;
    color: white;
  }
}
.delete-modal-wrapper{
  display: inline-block;
  width: 100%;

  .delete-modal-text-wrapper-left {
    display: inline-block;
    text-align: center;
    width: 14%;
    vertical-align: text-bottom;

    .delete-modal-icon {
      color: #F5222D;
      font-size: 20px;
    }
  }

  .delete-modal-text-wrapper-right {
    display: inline-block;
    width: 86%;
    font-size: 16px;
    vertical-align: top;
    color: #262626;
    line-height: 24px;
    font-weight: bold;
  }
}

.delete-modal-button-wrapper{
  display: inline-block;
  width: 100%;
  margin-top: 24px;
  text-align: right;
}


.delete-button, .delete-button:hover, .delete-button:active{
  background: #FF4D4F !important;
  border-radius: 11px;
  color: #FFFFFF;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  border: none;
}

.cancel-deletion, .cancel-deletion:hover, .cancel-deletion:active {
  background: #FFFFFF !important;
  border: 1px solid #BFBFBF !important;
  box-sizing: border-box;
  border-radius: 11px;
  color: #525252 !important;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  margin-right: 10px;
}