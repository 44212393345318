.row-card-content {

    padding-bottom: 24px;

    .card-section {
        width: 100%;

        .row-buttons {
            display: flex;
            align-items: center;
            justify-content: center;

            .upload-button {
                vertical-align: middle;

                .btn-item {
                    background: #FFFFFF;
                    border: 1px solid #BFBFBF;
                    box-sizing: border-box;
                    border-radius: 12px !important;

                    &:hover {
                        background: white;
                        border: 1px solid #3AB100;
                        box-sizing: border-box;
                        color: #3AB100;
                        border-radius: 12px !important;
                    }
                }
            }

            .reset-button {
                background: #FFFFFF;
                border: 1px solid #BFBFBF;
                box-sizing: border-box;
                border-radius: 12px !important;

                &:hover {
                    background: white;
                    border: 1px solid #F5222D;
                    box-sizing: border-box;
                    color: #F5222D;
                    border-radius: 12px !important;
                }
            }
        }

        .match-button {
            background: #3AB100;
            border: 1px solid #3AB100;
            color: white;
            box-sizing: border-box;
            border-radius: 12px !important;
            margin-right: 8px;

            &:hover {
                background: white;
                border: 1px solid #3AB100;
                box-sizing: border-box;
                color: #3AB100;
                border-radius: 12px !important;
            }

            &.disabled {
                background: #FFFFFF;
                color: #3AB100;
                border: 1px solid #BFBFBF;
                box-sizing: border-box;
                border-radius: 12px !important;
            }
        }

        .reject-button {

            background: #F5222D;
            border: 1px solid #F5222D;
            box-sizing: border-box;
            color: white;
            border-radius: 12px !important;
            margin-right: 8px;

            &:hover {
                background: white;
                border: 1px solid #F5222D;
                box-sizing: border-box;
                color: #F5222D;
                border-radius: 12px !important;
            }
        }

        .more-info-button {

            background: #BFBFBF;
            border: 1px solid #FFFFFF;
            box-sizing: border-box;
            color: white;
            border-radius: 12px !important;
            margin-right: 8px;

            &:hover {
                background: #FFFFFF;
                border: 1px solid #525252;
                box-sizing: border-box;
                color: #525252;
                border-radius: 12px !important;
            }
        }

        .action-column {
            display: flex;

            .reject-button {

                background: #F5222D;
                border: 1px solid #F5222D;
                box-sizing: border-box;
                color: white;
                border-radius: 12px !important;
                margin-right: 8px;

                &:hover {
                    background: white;
                    border: 1px solid #F5222D;
                    box-sizing: border-box;
                    color: #F5222D;
                    border-radius: 12px !important;
                }
            }

            .more-info-button {

                background: #BFBFBF;
                border: 1px solid #FFFFFF;
                box-sizing: border-box;
                color: white;
                border-radius: 12px !important;
                margin-right: 8px;

                &:hover {
                    background: #FFFFFF;
                    border: 1px solid #525252;
                    box-sizing: border-box;
                    color: #525252;
                    border-radius: 12px !important;
                }
            }
        }

        .general-button {
            background: #FFFFFF;
            color: #3AB100;
            border: 1px solid #BFBFBF;
            box-sizing: border-box;
            border-radius: 12px !important;
        }

        .modal-confirm-cashback {
            width: 80% !important;

            button.ant-btn {
                border-radius: 12px;
            }
        }

    }

    &.row-child-changed {
        opacity: 0.04;
        pointer-events: none;
    }
}

.ant-modal.modal-confirm-cashback {
    width: 80% !important;
    border-radius: 12px;

    button.ant-btn {
        border-radius: 12px;
    }
}

.ant-modal.modal-uploaded-invoices {
    width: 40% !important;

    button.ant-btn {
        border-radius: 12px;
    }

    .ant-modal-body {
        display: flex;
        justify-content: center;
    }
}

.general-button {
    background: #FFFFFF;
    color: #3AB100;
    border: 1px solid #BFBFBF;
    box-sizing: border-box;
    border-radius: 12px !important;

    &.cancel {
        background: white;
        border: 1px solid #F5222D;
        box-sizing: border-box;
        color: #F5222D;
        border-radius: 12px !important;

        &:hover {
            background: white;
            border: 1px solid #F5222D;
            box-sizing: border-box;
            color: #F5222D;
            border-radius: 12px !important;
        }
    }
}

.amazon-request-row-item {
    display: flex;
    justify-content: center;

    .amazon-affiliate-item {
        //  padding: 20px;
        display: block;
        padding-bottom: 20px;

        .item-select {
            width: 100%;
        }

        .item-title {
            font-size: 16px;
            display: block;
            color: #262626;
            padding-bottom: 15px;
        }
    }

    .percentage-items {
        display: flex;

        .percentage {
            width: 100%;

            .item-title {
                color: #262626;
                font-size: 16px;
            }

            .item-input {
                border-radius: 12px;
                padding-top: 15px;

                &.with-element-after {

                    .ant-input-group>.ant-input:first-child,
                    .ant-input-group-addon:first-child {
                        border-top-left-radius: 12px;
                        border-bottom-left-radius: 12px;
                    }

                    .ant-input-group>.ant-input:last-child,
                    .ant-input-group-addon:last-child {
                        border-top-right-radius: 12px !important;
                        border-bottom-right-radius: 12px !important;
                    }
                }
            }
        }
    }

    .total-amount-item {
        display: flex;

        .amazon-affiliate-item {
            padding: 20px;
        }
    }

    .row-card-amazon {

        &.statistic-row {
            display: flex;
            width: 100%;
            padding: 24px 0px 12px 0px;

            .card-item {
                background: #FFFFFF;
                border: 1px solid #BFBFBF;
                box-sizing: border-box;
                border-radius: 12px;
                height: 100%;
                
                .statistic-item {
                    text-align: center;
                    justify-content: center;
                    align-items: center;
                    display: flex;

                    .statistic-value {
                        font-weight: 600;
                        font-size: 20px;
                        line-height: 28px;
                        color: #525252;
                        display: block;

    
                            .statistic-name {
                                font-weight: normal;
                                font-size: 16px;
                                line-height: 18px;
                                color: #737373;
    
                            }
                        
                    }

              
                }

                .button-item {
                    display: flex;
                    justify-content: flex-end;
                    width: 100%;
                }
            }
        }

        &.row-child-hidden {
            opacity: 0.04;
            pointer-events: none;
        }
    }

    .row-child-hidden {
        opacity: 0.04;
        pointer-events: none;
    }

    .item-input {
        border-radius: 12px;
        padding-top: 15px;

        .ant-input-group>.ant-input:first-child,
        .ant-input-group-addon:first-child {
            border-top-left-radius: 12px;
            border-bottom-left-radius: 12px;
        }

        .ant-input-group>.ant-input:last-child,
        .ant-input-group-addon:last-child {
            border-top-right-radius: 12px !important;
            border-bottom-right-radius: 12px !important;
        }
    }
}

.row-modal {
    display: block;

    .form-item {
        display: block !important;

        .input-subject {
            border-radius: 12px;
        }

        .input-msg {
            border-radius: 12px;
        }
    }
}

.percentage-rates {
    width: 100%;

    .collapsed-panel {
        width: 100%;
    }
}

.confirm-icon {
    color: #3AB100;

    svg {
        width: 1.5em !important;
        height: 1.5em !important;
    }
}

.returned-icon {
    color: #F5222D;

    svg {
        width: 1.5em !important;
        height: 1.5em !important;
    }
}

.expanded-matching {

    max-width: 80%;
    padding: .75rem;
    margin-bottom: 2rem;
    border: 0;
    flex-basis: 80%;
    flex-grow: 0;
    flex-shrink: 0;

    .matched-records {
        width: 100%;

        .matched-records {
            .ant-collapse-header {
                background: white;
                //background: #E6F5DE;

            }
        }

        .unmatched-records {
            .ant-collapse-header {
                background: white;

                //background: #FFC4C4;

            }
        }

        .header-title {
            font-family: 'Inter';
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 24px;

            &.matched {
                color: #58BD1E;
            }

            &.unmatched {
                color: #F5222D;
            }
        }

    }

}

.amazon-report-item-search {
    width: 80%;

}
@backgroud-color: #E8E8E8;@primary-color: #3AB100;@primary-border: #58BD1E;@primary-background: #3AB100;@primary-hover: #E4F0D8;@primary-border-hover: #97D667;@info-color: #12b1d6;@warning-color: #FA8C16;@error-primary: #F5222D;@error-border: #FFA39E;@error-background: #FFF1F0;@white: #FFFFFF;@gray-border: #BFBFBF;@gray-background: #E6E6E6;@card-background: #FFFFFF;@text-color: #525252;