@import url(http://fonts.googleapis.com/css?family=Playfair+Display+SC);
@import url(http://fonts.googleapis.com/css?family=Playfair+Display+SC);


.toggle-menu {
  font-size: 1.5em;
  cursor: pointer;
  transition: color 0.3s;
}
.layout-height {
  height: 100vh;
}
.toggle-menu:hover {
  color: #3AB100;
}
.logo {
  margin: 16px;
  width: 71.68px;
  height: 32px;
  background: rgba(255, 255, 255, 0.3) url(/static/media/login-logo.cde90b21.png) round;
}
.logo-item {
  min-height: 60px;
  justify-content: center;
}
.header {
  padding: 0 !important;
  margin: 0 !important;
}
.header .header-content {
  display: inline-flex;
  height: 100%;
  padding: 0;
  width: 100%;
  justify-content: flex-start;
}
.header .header-content .header-content-left {
  display: inline-flex;
  height: 100%;
  width: 50%;
  padding: 0 28px 0;
  justify-content: flex-start;
}
.header .header-content .header-content-left .menu-toggle-wrapper {
  display: inline-flex;
  height: 100%;
  flex-direction: column;
  justify-content: center;
}
.header .header-content .header-content-right {
  display: inline-flex;
  width: 50%;
  height: 100%;
  justify-content: flex-end;
  padding-right: 28px;
}
.header .header-content .header-content-right .link-style {
  text-decoration: none;
  color: #525252;
}
.header .header-content .header-content-right .link-style .avatar {
  background-color: #F5F5F5;
  color: #525252;
  border: 1px solid #D1D1D1;
  margin-top: -7px;
}
.header .header-content .header-content-right .link-style .avatar-content-wrapper {
  display: inline-flex;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  margin-left: 15px;
  font-size: 20px;
  color: #262626;
}
.header .header-content .header-content-right .link-style .avatar-content-wrapper .avatar-wrapper {
  display: inline-flex;
  width: 100%;
  flex-direction: row;
  justify-content: flex-start;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 22px;
  color: #262626;
}
.header .header-content .header-content-right .link-style .avatar-content-wrapper .avatar-wrapper .down-arrow {
  display: inline-flex;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  font-size: 10px;
  padding-left: 7px;
}
.ant-layout-header {
  background: #FFFFFF !important;
}
.site-layout .site-layout-background {
  background: #E8E8E8;
  display: flex;
}
.side-menu {
  background: white;
}
.ant-menu-item {
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 22px;
}
.ant-menu-item.ant-menu-item-selected {
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
}

.login-form {
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 24px;
  background: #FFFFFF;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
  border-radius: 12px;
  margin-top: 24px;
}
.login-form .header-title {
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #262626;
  flex: none;
  order: 0;
  align-self: center;
  flex-grow: 0;
  margin: 0px 24px;
}
.login-form .form {
  margin-top: 24px;
}
.login-form .form .email-input {
  background: #FFFFFF;
  border: 1px solid #BFBFBF;
  box-sizing: border-box;
  border-radius: 12px;
}
.login-form .form .password-input {
  background: #FFFFFF;
  border: 1px solid #BFBFBF;
  box-sizing: border-box;
  border-radius: 12px;
}
.login-form .form .login-remember-me {
  display: inline;
  height: 22px;
  left: 24px;
  right: 0px;
  top: calc(50% - 22px/2);
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  color: #525252;
  flex: none;
  order: 1;
  align-self: center;
  flex-grow: 0;
  margin: 8px 0px;
}
.login-form .form .login-remember-me:hover {
  border-color: #3AB100;
}
.login-form .form .login-remember-me .ant-checkbox-wrapper:hover {
  border-color: #3AB100;
}
.login-form .form .login-remember-me .ant-checkbox-checked .ant-checkbox-inner {
  background-color: #3AB100;
  border-color: #3AB100;
}
.login-form .form .login-remember-me .ant-checkbox-wrapper:hover .ant-checkbox-inner {
  border-color: #3AB100;
}
.login-form .form .login-remember-me .ant-checkbox:hover .ant-checkbox-inner {
  border-color: #3AB100;
}
.login-form .form .login-remember-me .ant-checkbox-input:focus .ant-checkbox-inner {
  border-color: #3AB100;
}
.login-form .form .login-fogot-password {
  width: 112px;
  height: 22px;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  color: #3AB100;
  float: right;
}
.login-form .login-submit-button {
  padding: 8px 16px;
  box-sizing: border-box;
  border-radius: 12px;
}
.login-form .login-submit-button.disabled {
  background: #E6E6E6;
  border: 1px solid #BFBFBF;
  border-color: #BFBFBF;
}
.login-form .login-submit-button.disabled:hover {
  border-color: #BFBFBF;
}
.login-form .login-submit-button.active {
  background: #3AB100;
  border-radius: 12px;
  border: 1px solid #3AB100;
}
.login-form .login-submit-button.active:hover {
  border-color: #3AB100;
}
.login-form .error-alert {
  margin-top: 24px;
  background: #FFF1F0;
  border: 1px solid #FFA39E;
  box-sizing: border-box;
  border-radius: 12px;
}
.login-form .error-alert .ant-alert-message {
  padding-right: 10px;
}
.header-logo {
  max-width: 125.44px;
  height: 56px;
}
.footer-login {
  margin-top: 32px;
  flex: none;
  order: 1;
  align-self: center;
  flex-grow: 0;
  padding: 10px;
}
.footer-login .footer-content {
  position: static;
  width: 338px;
  height: 22px;
  left: 12px;
  top: 426px;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  color: #525252;
}
.reset-password-widget {
  max-width: 365px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.reset-password-widget .forgot-password-form {
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 24px;
  background: #FFFFFF;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
  border-radius: 12px;
  margin-top: 32px;
}
.reset-password-widget .forgot-password-form .content {
  display: flex;
  justify-content: center;
  padding: none !important;
}
.reset-password-widget .forgot-password-form .header-title {
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
  text-align: center;
  color: #525252;
  margin-top: 16px;
  margin-bottom: 24px;
}
.reset-password-widget .forgot-password-form .subheader-title {
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  text-align: center;
  color: #525252;
  margin: 0px 16px;
}
.reset-password-widget .forgot-password-form .form {
  margin-top: 24px;
}
.reset-password-widget .forgot-password-form .form.reset-password-popup {
  display: block;
}
.reset-password-widget .forgot-password-form .form .email-input {
  background: #FFFFFF;
  border: 1px solid #BFBFBF;
  box-sizing: border-box;
  border-radius: 12px;
}
.reset-password-widget .forgot-password-form .form .forgot-submit-button {
  padding: 8px 16px;
  box-sizing: border-box;
  border-radius: 12px;
}
.reset-password-widget .forgot-password-form .form .forgot-submit-button.disabled {
  background: #E6E6E6;
  border: 1px solid #BFBFBF;
  border-color: #BFBFBF;
}
.reset-password-widget .forgot-password-form .form .forgot-submit-button.disabled:hover {
  border-color: #BFBFBF;
}
.reset-password-widget .forgot-password-form .form .forgot-submit-button.active {
  background: #3AB100;
  border-radius: 12px;
  border: 1px solid #3AB100;
}
.reset-password-widget .forgot-password-form .form .forgot-submit-button.active:hover {
  border-color: #3AB100;
}
.reset-password-widget .forgot-password-form .instruction-sent-form {
  display: flex;
  justify-content: center;
}
.reset-password-widget .forgot-password-form .instruction-sent-form .content {
  display: flex;
  justify-content: center;
}
.reset-password-widget .forgot-password-form .instruction-sent-form .header-title {
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
  margin-top: 19px;
  margin-bottom: 16px;
}
.reset-password-widget .forgot-password-form .instruction-sent-form .header-title .resent {
  color: #52C41A;
}
.reset-password-widget .forgot-password-form .instruction-sent-form .subheader-title {
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  color: #525252;
  margin: 0px 0px;
}
.reset-password-widget .forgot-password-form .instruction-sent-form .circle-check {
  color: #52C41A !important;
  max-width: 42px;
  max-height: 42px;
}
.reset-password-widget .forgot-password-form .instruction-sent-form .circle-check svg {
  width: 42px;
  height: 42px;
}
.reset-password-widget .forgot-password-form .instruction-sent-form .resend-link {
  display: flex;
  text-align: center;
  margin-top: 40px;
  color: #525252;
  justify-content: center;
  align-items: baseline;
}
.reset-password-widget .forgot-password-form .instruction-sent-form .resend-link .resend-anchor-link {
  padding-left: 5px;
  color: #52C41A;
}
.reset-password-widget .forgot-password-form .instruction-sent-form .button-redirect {
  display: contents;
}
.reset-password-widget .forgot-password-form .instruction-sent-form .button-redirect .forgot-submit-button {
  margin-top: 24px;
}
.reset-password-widget .forgot-password-form .instruction-sent-form .button-redirect .forgot-submit-button.disabled {
  background: #E6E6E6;
  border: 1px solid #BFBFBF;
  border-color: #BFBFBF;
}
.reset-password-widget .forgot-password-form .instruction-sent-form .button-redirect .forgot-submit-button.disabled:hover {
  border-color: #BFBFBF;
}
.reset-password-widget .forgot-password-form .instruction-sent-form .button-redirect .forgot-submit-button.active {
  background: #3AB100;
  border-radius: 12px;
  border: 1px solid #3AB100;
}
.reset-password-widget .forgot-password-form .instruction-sent-form .button-redirect .forgot-submit-button.active:hover {
  border-color: #3AB100;
}
.reset-password-widget .forgot-password-form .error-alert {
  margin-top: 24px;
  background: #FFF1F0;
  border: 1px solid #FFA39E;
  box-sizing: border-box;
  border-radius: 12px;
  max-width: 314px;
}
.reset-password-widget .forgot-password-form .error-alert .ant-alert-message {
  padding-right: 10px;
}
.reset-password-widget .forgot-password-form .ant-layout,
.reset-password-widget .forgot-password-form .ant-layout {
  display: block;
}
.site-layout .site-layout-background {
  background: #E8E8E8;
}
.margin-right {
  margin-right: 8px;
}
.margin-top {
  margin-top: 5px;
}
.margin-left {
  margin-left: 15px;
}
.rounded-button {
  background: #FFFFFF;
  border: 1px solid #BFBFBF;
  box-sizing: border-box;
  border-radius: 11px;
  color: #525252;
  font-weight: normal;
  font-size: 1em;
  cursor: pointer;
  line-height: -24px;
}
.rounded-button-filled {
  background: #58BD1E !important;
  border: 0 solid white;
  color: white !important;
  border-radius: 15px !important;
  font-weight: bold !important;
}
.left-divider {
  border-left: 1px solid;
}
.statistics-item-expanded-title {
  margin-left: 45px;
  margin-top: 22px;
}
.statistics-item-expanded-value {
  margin-left: 45px;
}
.statistic-item-wrapper {
  display: inherit;
  width: 100%;
}
.statistic-item-wrapper .inherit {
  display: inherit;
}
.statistic-item-wrapper .statistics-item-badge-wrapper {
  display: inherit;
  flex-direction: column;
  justify-content: center;
}
.rounded-button-stealth {
  border: none;
  color: #BFBFBF;
  font-size: 1em;
}
.rounded-button-stealth:hover,
.rounded-button-stealth:active {
  color: #58BD1E;
}
.rounded-button-filled:hover {
  background: #97D667;
}
.rounded-button-exit {
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  border-radius: 15px;
  font-size: 0.85em;
  padding-left: 0.65em;
  padding-right: 0.65em;
}
.rounded-button-exit:hover {
  border: 1px solid #FFA39E;
  color: #FFA39E;
}
.rounded-button-exit:active {
  border: 1px solid #FA8C16;
  color: #FA8C16;
}
.home-content {
  padding: 22px 32px 147px 32px;
  display: flex;
  width: 100%;
  font-weight: normal;
  font-family: "Inter", sans-serif;
  font-size: 14px;
  line-height: 22px;
  color: #737373;
}
@media (max-width: 1199px) {
  .home-content .title-item {
    font-weight: normal !important;
    font-size: 0.8em !important;
    line-height: 0.9em !important;
    color: #737373 !important;
    display: flex;
    align-self: center;
    align-items: center;
    vertical-align: middle;
  }
}
@media (min-width: 1200px) and (max-width: 1600px) {
  .home-content .title-item {
    font-weight: normal !important;
    font-size: 1em !important;
    line-height: 1.05em !important;
    color: #737373 !important;
    display: flex;
    align-self: center;
    align-items: center;
    vertical-align: middle;
  }
}
@media (min-width: 1600px) {
  .home-content .title-item {
    font-weight: normal !important;
    font-size: 1.2em !important;
    line-height: 1.15em !important;
    color: #737373 !important;
    display: flex;
    align-self: center;
    align-items: center;
    vertical-align: middle;
  }
}
.home-content .value-item {
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 1.2em !important;
  line-height: 1.3em !important;
  color: #262626 !important;
}
.home-content .value-item.amount {
  font-size: 1.3em !important;
  line-height: 2em !important;
}
.home-content .badge-item .ant-badge-status-dot {
  width: 14px;
  height: 14px;
  display: inherit;
  top: 10px;
  align-self: center;
  align-items: center;
}
.home-content .chart-content {
  width: 81px;
  height: 80px;
}
.home-content .chart-content.goal-statistics {
  width: 112px;
  height: 112px;
}
.home-content .home-chart-content {
  width: 174px;
  height: 174px;
}
.home-content.statistics {
  width: 100%;
}
.home-content.statistics .chart-content {
  width: 112px;
  height: 112px;
}
.home-content .stats-details {
  padding-left: 11px;
}
.home-content .stats-details .more-details-link {
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  cursor: pointer !important;
  color: #D1D1D1 !important;
  font-weight: normal !important;
}
.home-content .stats-details .more-details-link:hover {
  color: #97D667 !important;
}
.home-content .stats-details .more-details-link:active {
  color: #FA8C16 !important;
}
.home-content .value-item {
  font-weight: 600 !important;
  font-size: 1.3em !important;
  line-height: 1em !important;
  color: #262626 !important;
}
.home-content .circle-icon {
  width: 40px;
  height: 40px;
  background: #F5F5F5;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  vertical-align: text-bottom;
}
.home-content .centered-column {
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
}
.home-content .col-divider-right {
  border-right: 1px solid #E8E8E8;
}
.home-content .col-padding-left {
  padding-left: 25px;
}
.home-content .col-padding-right {
  padding-right: 25px;
}
.home-content .row-card-content {
  align-items: stretch;
  width: 100%;
}
.home-content .row-card-content .center-flex {
  display: inline-flex;
  width: 100%;
  justify-content: center;
}
.home-content .row-card-content .card-column {
  flex: 1 1;
}
.home-content .row-card-content .card-column .card-item {
  height: 100%;
  width: 100%;
  border-radius: 12px;
}
.home-content .row-card-content .card-column .card-item .ant-tabs-tab-btn {
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
}
.home-content .row-card-content .card-column .card-item .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
}
.home-content .row-card-content .card-column .card-item .row-tab-content .home-row-item-margin-top {
  margin-top: 12px;
}
.home-content .row-card-content .card-column .card-item .row-tab-content .home-row-item-margin-top-main {
  margin-top: 30px;
}
.home-content .row-card-content .card-column .card-item .row-tab-content .home-row-item-margin-left {
  margin-left: 1.5em;
}
.home-content .row-card-content .card-column .card-item .row-tab-content .home-row-item-total {
  display: block;
}
.home-content .row-card-content .card-column .card-item .row-tab-content .home-row-item-total .value-item {
  font-weight: 600 !important;
  font-size: 1.3em !important;
  line-height: 1em !important;
  color: #262626 !important;
}
.home-content .row-card-content .card-column .card-item .row-tab-content .home-row-item-total .inline-block {
  display: inline-block;
}
.home-content .row-card-content .card-column .card-item .row-tab-content .home-row-item-total .value-item-main {
  font-weight: 600 !important;
  font-size: 1.85em !important;
  line-height: 1.3em !important;
  color: #262626 !important;
}
.home-content .row-card-content .card-column .card-item .row-tab-content .home-row-item-total .value-item-center {
  text-align: center;
}
.home-content .row-card-content .card-column .card-item .row-tab-content .home-row-item-simple {
  display: inline-flex;
  width: 100%;
}
@media (max-width: 1600px) {
  .home-content .row-card-content .card-column .card-item .row-tab-content .home-row-item-simple .chart-content-transaction {
    width: 6em;
    height: 6em;
  }
}
@media (min-width: 1600px) {
  .home-content .row-card-content .card-column .card-item .row-tab-content .home-row-item-simple .chart-content-transaction {
    width: 9em;
    height: 9em;
  }
}
.home-content .row-card-content .card-column .card-item .row-tab-content .home-row-item-right-block {
  display: inline-block;
  text-align: right;
}
.home-content .row-card-content .card-column .card-item .row-tab-content .home-row-item-margin-left {
  margin-left: 25px;
}
.home-content .row-card-content .card-column .card-item .row-tab-content .home-row-item-padding-top {
  padding-top: 16px !important;
}
.home-content .row-card-content .card-column .card-item .row-tab-content .home-row-item {
  width: 100%;
  padding: 0 0 0 0 !important;
}
.home-content .row-card-content .card-column .card-item .row-tab-content .home-row-item.center {
  display: flex;
  justify-content: center;
}
.home-content .row-card-content .card-column .card-item .row-tab-content .home-row-item.vcenter {
  align-items: center;
}
.home-content .row-card-content .card-column .card-item .row-tab-content .home-row-item.bottom {
  margin-bottom: 22px;
}
.home-content .row-card-content .card-column .card-item .row-tab-content .home-row-item .home-row-item-center {
  display: inline-flex;
  justify-content: center;
  align-items: center;
}
.home-content .row-card-content .card-column .card-item .row-tab-content .home-row-item .value-item-center-flex {
  font-weight: 600 !important;
  font-size: 36px !important;
  line-height: 32px !important;
  color: #262626 !important;
  justify-content: center;
}
.home-content .row-card-content .card-column .card-item .row-tab-content .home-row-item .home-row-item-center-block {
  display: inline-block;
  text-align: center;
  align-items: center;
}
.home-content .row-card-content .card-column .card-item .row-tab-content.inline .ant-card-body {
  display: inline-flex;
}
.home-content .row-card-content .card-column .card-item .row-tab-content.inline .ant-card-body .circle-icon {
  width: 40px;
  height: 40px;
  background: #F5F5F5;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.home-content .row-card-content .card-column .card-item .row-tab-content.inline .tab-content {
  padding-left: 12px;
  display: block;
}
.home-content .row-card-content .card-column .card-item .row-tab-content.inline .tab-content .value-item {
  font-weight: 600 !important;
  font-size: 22px !important;
  line-height: 28px !important;
  color: #262626;
}
.delete-user .ant-message-notice-content {
  background: black;
  color: white;
}
.delete-modal-wrapper {
  display: inline-block;
  width: 100%;
}
.delete-modal-wrapper .delete-modal-text-wrapper-left {
  display: inline-block;
  text-align: center;
  width: 14%;
  vertical-align: text-bottom;
}
.delete-modal-wrapper .delete-modal-text-wrapper-left .delete-modal-icon {
  color: #F5222D;
  font-size: 20px;
}
.delete-modal-wrapper .delete-modal-text-wrapper-right {
  display: inline-block;
  width: 86%;
  font-size: 16px;
  vertical-align: top;
  color: #262626;
  line-height: 24px;
  font-weight: bold;
}
.delete-modal-button-wrapper {
  display: inline-block;
  width: 100%;
  margin-top: 24px;
  text-align: right;
}
.delete-button,
.delete-button:hover,
.delete-button:active {
  background: #FF4D4F !important;
  border-radius: 11px;
  color: #FFFFFF;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  border: none;
}
.cancel-deletion,
.cancel-deletion:hover,
.cancel-deletion:active {
  background: #FFFFFF !important;
  border: 1px solid #BFBFBF !important;
  box-sizing: border-box;
  border-radius: 11px;
  color: #525252 !important;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  margin-right: 10px;
}
.dashboard-content {
  display: block;
  padding: 22px 32px 147px 32px;
}
.dashboard-content .row-card {
  margin-top: 24px;
  display: block;
}
.dashboard-content .row-card .card-flex-grow {
  display: block;
  min-height: calc(100% - 24px);
}
.dashboard-content .row-card .user-card-item {
  background: #FFFFFF;
  border: 1px solid #BFBFBF;
  box-sizing: border-box;
  border-radius: 12px;
  margin-top: 24px;
}
.dashboard-content .row-card .user-card-item .user-name-row {
  display: flex;
}
.dashboard-content .row-card .user-card-item .user-name-row .initials-item {
  width: 72px;
  height: 72px;
  background: #F5F5F5;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
}
.dashboard-content .row-card .user-card-item .user-name-row .name-item {
  display: flex;
  padding-left: 12px;
  justify-content: center;
  align-items: center;
}
.dashboard-content .row-card .user-card-item .user-name-row .name-item .fullname-item {
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
  color: #262626;
}
.dashboard-content .row-card .user-card-item .user-name-row .name-item .email-item {
  font-weight: normal;
  display: block;
  font-size: 14px;
  line-height: 22px;
  color: #525252;
}
.dashboard-content .row-card .user-card-item .details-row-card-item .gutter-row {
  width: 100%;
  padding: 24px;
}
.dashboard-content .row-card .user-card-item .details-row-card-item .gutter-row .user-field-item {
  font-weight: 600 !important;
  font-size: 14px !important;
  line-height: 22px !important;
  color: #262626 !important;
  padding-top: 24px;
}
.dashboard-content .row-card .user-card-item .details-row-card-item .gutter-row .user-field-item.date {
  font-weight: normal !important;
  font-size: 14px !important;
  line-height: 22px !important;
  color: #737373 !important;
}
.dashboard-content .row-card .user-card-item .details-row-card-item .gutter-row .user-field-value {
  font-size: 14px !important;
  line-height: 22px !important;
  color: #525252 !important;
}
.dashboard-content .row-card .user-card-item .details-row-card-item .gutter-row .user-field-value .bank-connected {
  background: #FCFFE6;
  color: #7CB305;
  border: none;
}
.dashboard-content .row-card .user-card-item .details-row-card-item .gutter-row .user-field-value .bank-disconnected {
  background: #FFF7E6;
  color: #FA8C16;
  border: none;
}
.dashboard-content .row-card .user-card-item .details-row-card-item .gutter-row .user-field-value .not-identified {
  background: #FCFFE6;
  color: #7CB305;
  border: none;
}
.dashboard-content .row-card .user-card-item .user-status {
  display: block;
  float: right;
}
.dashboard-content .row-card .user-card-item .user-status .user-status-tag {
  float: right;
  padding-bottom: 16px;
}
.dashboard-content .row-card .user-card-item .user-status .user-status-buttons {
  display: block;
}
.dashboard-content .row-card .user-card-item .user-status .user-status-buttons .initiate-deletion-button {
  border-radius: 12px;
  background: white;
  color: #FF4D4F;
  border: 1px solid #FF4D4F;
}
.dashboard-content .row-card .user-card-item .user-status .user-status-buttons .cancel-request-deletion {
  background: #FFFFFF;
  border: 1px solid #BFBFBF;
  box-sizing: border-box;
  border-radius: 11px;
  margin-right: 12px;
  color: #525252;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
}
.dashboard-content .row-card .user-card-item .user-status .user-status-buttons .delete-button {
  background: #FF4D4F;
  border-radius: 11px;
  color: #FFFFFF;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  border: none;
}
.dashboard-content .row-card .user-card-item .user-status .user-status-buttons .delete-button-disabled {
  background: #BFBFBF;
  border-radius: 11px;
  color: #FFFFFF;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  border: none;
}
.dashboard-content .row-card.balance-row {
  display: flex;
}
.dashboard-content .row-card.balance-row .card-item {
  background: #FFFFFF;
  border: 1px solid #BFBFBF;
  box-sizing: border-box;
  border-radius: 12px;
}
.dashboard-content .row-card.balance-row .card-item .balance-item {
  text-align: center;
  justify-content: center;
  align-items: center;
  display: flex;
  width: 100%;
}
.dashboard-content .row-card.balance-row .card-item .balance-item .balance-value {
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
  color: #525252;
}
.dashboard-content .row-card.balance-row .card-item .balance-item .balance-name {
  font-weight: normal;
  font-size: 11px;
  line-height: 18px;
  color: #737373;
}
.dashboard-content .row-card .card-item {
  display: flex;
}
.dashboard-content .row-card .card-item .saving-rules {
  display: flex;
  flex-grow: 1;
  width: 100%;
  justify-content: flex-start;
}
.dashboard-content .row-card .card-item .saving-rules .title {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  text-align: right;
  color: #262626;
}
.dashboard-content .row-card .card-item .saving-rules .saving-rules-details {
  display: block;
  width: 100%;
  font-size: 14px;
  line-height: 22px;
  color: #525252;
  padding-top: 8px;
}
.dashboard-content .row-card .card-item .saving-rules .saving-rules-details .saving-percentage {
  padding: 0px 32px 0px 0px;
}
.dashboard-content .row-card .card-item .saving-rules .saving-rules-details .spending-limit {
  padding: 0px 0px 0px 32px;
}
.dashboard-content .row-card .card-item .saving-rules .saving-rules-details .change-link {
  float: right;
}
.dashboard-content .row-card .card-item .searchbox {
  padding: 24px 0px 24px 0px;
  border-radius: 12px;
  width: 30%;
}
.dashboard-content .row-card .card-item .searchbox .ant-input.ant-input-lg {
  border-top-left-radius: 12px;
  border-bottom-left-radius: 12px;
}
.dashboard-content .row-card .card-item .searchbox span.ant-input-affix-wrapper.ant-input-affix-wrapper-lg {
  border-top-left-radius: 12px !important;
  border-bottom-left-radius: 12px !important;
}
.dashboard-content .row-card .card-item .searchbox button.ant-btn.ant-btn-lg.ant-btn-icon-only.ant-input-search-button {
  border-top-right-radius: 12px !important;
  border-bottom-right-radius: 12px !important;
}
.dashboard-content .row-card .card-item .move-money-type {
  padding-right: 12.5px;
}
.dashboard-content .row-card .card-item .move-money-type .arrow-destination {
  padding: 3.5px;
}
.dashboard-content .row-card .card-item .move-money-type .destination {
  color: #3AB100;
}
.dashboard-content .row-card .card-item .ant-tabs-tab-btn {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
}
.dashboard-content .row-card .card-item .goal-list {
  display: flex;
  flex-grow: 1;
  width: 100%;
}
.dashboard-content .row-card .user-field-item {
  font-weight: 600 !important;
  font-size: 14px !important;
  line-height: 22px !important;
  color: #262626 !important;
  padding-top: 24px;
}
.dashboard-content .row-card .user-field-item.date {
  font-weight: normal !important;
  font-size: 14px !important;
  line-height: 22px !important;
  color: #737373 !important;
}
.dashboard-content .row-card .user-field-value {
  font-size: 14px !important;
  line-height: 22px !important;
  color: #525252 !important;
}
.dashboard-content .row-card .user-field-value .bank-connected {
  background: #FCFFE6;
  color: #7CB305;
  border: none;
}
.dashboard-content .row-card .user-field-value .bank-disconnected {
  background: #FFF7E6;
  color: #FA8C16;
  border: none;
}
.dashboard-content .row-card .user-field-value .not-identified {
  background: #FFF7E6;
  color: #FA8C16;
  border: none;
}
.dashboard-content .refresh-row-card {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.dashboard-content .ant-card-body {
  width: 100%;
}
.dashboard-content .ant-table-wrapper {
  width: 100%;
}
.dashboard-content .ud-collapsed {
  max-height: 60px;
  height: auto;
  height: initial;
  overflow: hidden;
}
.dashboard-content .ud-collapsed .ant-card-body {
  display: none !important;
}
.dashboard-content .ud-expanded {
  height: auto;
}
.delete-button,
.delete-button:hover,
.delete-button:active {
  background: #FF4D4F !important;
  border-radius: 11px;
  color: #FFFFFF;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  border: none;
}
.cancel-deletion,
.cancel-deletion:hover,
.cancel-deletion:active {
  background: #FFFFFF;
  border: 1px solid #BFBFBF !important;
  box-sizing: border-box;
  border-radius: 11px;
  color: #525252;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
}
.dashboard-content {
  display: block;
  padding: 22px 32px 147px 32px;
}
.dashboard-content .row-card {
  margin-top: 24px;
  display: block;
}
.dashboard-content .row-card .result-details {
  font-weight: 600 !important;
  font-size: 16px !important;
  line-height: 24px !important;
  display: flex;
  align-items: center;
  color: #262626 !important;
}
.dashboard-content .row-card .card-item {
  background: #FFFFFF;
  border: 1px solid #BFBFBF;
  box-sizing: border-box;
  border-radius: 12px;
  margin-top: 24px;
  display: flex;
}
.dashboard-content .row-card .card-item .ant-card-body {
  display: flex;
}
.dashboard-content .row-card .card-item .search-box {
  display: block;
  width: 100%;
}
.dashboard-content .row-card .card-item .search-box .title {
  font-weight: normal !important;
  font-size: 20px !important;
  line-height: 28px !important;
  display: flex;
  align-items: center;
  color: #262626 !important;
}
.dashboard-content .row-card .card-item .search-box .description {
  font-weight: normal !important;
  font-size: 16px !important;
  line-height: 24px !important;
  display: flex;
  align-items: center;
  color: #525252 !important;
  padding-bottom: 24px;
}
.dashboard-content .row-card .card-item .search-box .search-input {
  border-radius: 12px;
}
.dashboard-content .row-card .card-item .search-box .search-input span.ant-input-affix-wrapper.ant-input-affix-wrapper-lg {
  border-top-left-radius: 12px;
  border-bottom-left-radius: 12px;
}
.dashboard-content .row-card .card-item .search-box .search-input button.ant-btn.ant-btn-primary.ant-btn-lg.ant-input-search-button {
  border-top-right-radius: 12px;
  border-bottom-right-radius: 12px;
}
.dashboard-content .row-card .card-item .calendar-picker {
  display: flex;
  align-items: flex-end;
  width: 100%;
  justify-content: flex-end;
}
.dashboard-content .row-card .card-item .calendar-picker .ant-picker.ant-picker-range.dateRangePicker {
  border-radius: 12px;
}
.dashboard-content .row-card .card-item .calendar-picker .radio-calendar-filter {
  display: block;
}
.dashboard-content .row-card .card-item .calendar-picker .radio-calendar-filter .radio-group-filter {
  display: flex;
  justify-content: flex-end;
  margin-bottom: -10px;
}
.dashboard-content .row-card .card-item .anticon-arrow-right {
  color: #A6A6A6;
}
.dashboard-content .row-card .card-item .ant-table-content .table-item {
  display: inline-flex;
}
.dashboard-content .row-card .card-item .ant-table-content .table-item .alphabetic-item {
  width: 32px;
  height: 32px;
  background: #F5F5F5;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  font-size: 14px;
  line-height: 22px;
  color: #525252;
}
.dashboard-content .row-card .card-item .ant-table-content .table-item .item-concat {
  padding-left: 8px;
  display: block;
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  color: #262626;
  text-align: left;
}
.dashboard-content .row-card .card-item .ant-table-content .table-item .item-concat .email-item {
  font-size: 12px;
  line-height: 20px;
  color: #525252;
  font-weight: normal;
}
.dashboard-content .row-card .card-item .ant-table-content .ant-table-thead {
  font-weight: 600!important;
  font-size: 14px !important;
  line-height: 22px!important;
  color: #262626;
}
.dashboard-content .row-card .card-item .ant-table-content th.ant-table-cell {
  font-weight: 600!important;
  font-size: 14px !important;
  line-height: 22px!important;
  color: #262626;
}
.dashboard-content .row-card .card-item .ant-tag {
  border-radius: 6px;
  border: none;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  align-items: center;
}
.dashboard-content .row-card .card-item .ant-tag.active {
  background: #E4F0D8;
  color: #3AB100;
}
.dashboard-content .row-card .card-item .ant-tag.bank-connected {
  background: #FCFFE6;
  color: #7CB305;
}
.dashboard-content .row-card .card-item .ant-tag.bank-disconnected {
  background: #FFF7E6;
  color: #FA8C16;
}
.dashboard-content .row-card .card-item .ant-tag.delete-request {
  background: #FFF1F0;
  color: #F5222D;
}
.dashboard-content .row-card .card-item .ant-tag.not-verified {
  background: #F5F5F5;
  color: #737373;
}
.dashboard-content .row-card .card-item .ant-tag.onboarding {
  background: #F0F5FF;
  color: #2F54EB;
}
.dashboard-content .row-card .card-item .ant-tag.not-identified {
  background: #F0F5FF;
  color: #2F54EB;
}
.notification-content {
  display: flex;
  width: 100%;
  font-weight: normal;
  font-family: "Inter", sans-serif;
  justify-content: center;
  font-size: 14px;
  flex-wrap: wrap;
  line-height: 22px;
  color: #737373;
}
.notification-content .nt-row-card-content {
  margin: 2% !important;
  width: 96%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.notification-content .nt-row-card-content .notification-item-right {
  justify-content: flex-end !important;
}
.notification-content .nt-row-card-content .notification-item-center {
  justify-content: center !important;
}
.notification-content .nt-row-card-content .notification-item {
  display: inline-flex;
  flex-direction: row;
  flex-grow: 1;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 24px;
}
.notification-content .nt-row-card-content .notification-item .search-wrapper {
  display: inline-flex;
  max-width: 50%;
}
.notification-content .nt-row-card-content .notification-item .search-wrapper .search-input {
  border-radius: 12px;
}
.notification-content .nt-row-card-content .notification-item .search-wrapper .search-input span.ant-input-affix-wrapper.ant-input-affix-wrapper-lg {
  border-top-left-radius: 12px;
  border-bottom-left-radius: 12px;
}
.notification-content .nt-row-card-content .notification-item .search-wrapper .search-input button.ant-btn.ant-btn-primary.ant-btn-lg.ant-input-search-button {
  border-top-right-radius: 12px;
  border-bottom-right-radius: 12px;
}
.notification-content .nt-row-card-content .notification-item .notification-template-title {
  display: inline-flex;
  flex-direction: row;
  width: 80%;
  font-size: 1.4em;
  justify-content: flex-start;
  padding-left: 2.5%;
  color: black;
}
.notification-content .nt-row-card-content .notification-list-sender {
  display: block;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: flex-start !important;
  vertical-align: text-bottom;
  padding: 0 !important;
}
.notification-content .nt-row-card-content .notification-list-sender .nt-tab-row-card-content {
  align-items: stretch;
  margin: 0;
  flex: 1 1;
}
.notification-content .nt-row-card-content .notification-log-view {
  display: block;
  flex-wrap: wrap;
  width: 96%;
  flex-direction: column;
  justify-content: flex-start !important;
  vertical-align: text-bottom;
  padding: 0 !important;
}
.notification-content .nt-row-card-content .notification-log-view .nt-tab-row-card-content {
  align-items: stretch;
  margin: 0;
  flex: 1 1;
}
.notification-content .nt-row-card-content .is-flex-expanded {
  flex-grow: 1;
}
.notification-content .nt-row-card-content .notification-sender {
  width: 49.3%;
  margin-left: 1.4%;
  flex-direction: column;
  flex-wrap: wrap;
  padding: 0 !important;
}
.notification-content .nt-row-card-content .notification-sender .nt-template-view-row-item {
  margin: 0;
  padding: 0;
  width: 100%;
  line-height: 24px;
}
.notification-content .nt-row-card-content .notification-sender .nt-tab-row-card-content {
  align-items: stretch;
  margin: 0;
  flex: 1 1;
}
.notification-content .nt-row-card-content .notification-sender .nt-tab-row-card-content .nt-card-col {
  display: flex;
  flex: 1 1;
  width: 100%;
}
.notification-content .nt-row-card-content .notification-sender .nt-tab-row-card-content .nt-card-col .notification-button-wrapper {
  margin-top: 5vh;
  flex: 1 1;
  width: 100% !important;
  display: flex;
  justify-content: flex-end !important;
}
.notification-content .nt-row-card-content .notification-sender .nt-tab-row-card-content .nt-card-col .nt-card-item {
  height: 100%;
  width: 100%;
  flex: 1 1;
  border-radius: 12px;
}
.notification-content .nt-row-card-content .notification-sender .nt-tab-row-card-content .nt-card-col .nt-card-item .notification-tab {
  display: flex;
  flex: 1 1;
}
.notification-content .nt-row-card-content .nt-collapsed {
  max-height: 60px;
  height: auto;
  height: initial;
  overflow: hidden;
}
.notification-content .nt-row-card-content .nt-collapsed .ant-card-body {
  display: none !important;
}
.notification-content .nt-row-card-content .nt-expanded {
  height: auto;
}

body {
  font-family: "Inter", sans-serif !important;
  font-size: 18px;
  line-height: 21px;
  color: black !important;
  font-weight: 300 !important;
  font-style: normal !important;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Inter", sans-serif !important;
  color: black !important;
  font-weight: 300 !important;
}
h1,
.h1 {
  font-size: 32px !important;
  line-height: 37px;
}
h2,
.h2 {
  font-size: 30px !important;
  line-height: 35px;
}
h3,
.h3 {
  font-size: 28px !important;
  line-height: 33px;
}
h4,
.h4 {
  font-size: 26px !important;
  line-height: 30px;
}
h5,
.h5 {
  font-size: 24px !important;
  line-height: 28px;
}
h6,
.h6 {
  font-size: 22px !important;
  line-height: 26px;
}
html,
body {
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
}
body {
  background: #E8E8E8;
  -webkit-overflow-scrolling: touch;
  font-family: 'Inter', sans-serif;
  overflow-x: hidden;
  font-family: -apple-system, BlinkMacSystemFont, 'Inter', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
body .icon-styles {
  color: #3AB100 !important;
}
body .warning-style {
  color: #FA8C16 !important;
}
body .error-style {
  color: #F5222D !important;
  border-color: #FFA39E !important;
}
body .default-breadcrumb-item {
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
}
body .breadcrumb {
  margin: 3px 0;
  background-color: white;
  padding: 18px;
}
body .default-item {
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
}
body .ant-breadcrumb-link {
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
}
body .breadcrumb-subpage-title {
  background-color: white;
  margin-top: -26px;
  padding: 18px;
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
  color: #262626;
}
body .ant-pagination-item {
  border-radius: 12px;
}
body .ant-pagination-item-link {
  border-radius: 12px !important;
}
body .account-header {
  display: flex;
  justify-content: center;
  align-items: center;
}
body .ant-tag {
  border-radius: 6px;
  border: none;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  align-items: center;
}
body .ant-tag.active {
  background: #E4F0D8;
  color: #3AB100;
}
body .ant-tag.bank-connected {
  background: #FCFFE6;
  color: #7CB305;
}
body .ant-tag.not-identified {
  background: #f5695e;
  color: #f6ec8d;
}
body .ant-tag.bank-disconnected {
  background: #FFF7E6;
  color: #FA8C16;
}
body .ant-tag.delete-request {
  background: #FFF1F0;
  color: #F5222D;
}
body .ant-tag.not-verified {
  background: #F5F5F5;
  color: #737373;
}
body .ant-tag.maininvest-investors {
  background: #086996;
  color: #F5F5F5;
}
body .ant-tag.onboarding {
  background: #F0F5FF;
  color: #2F54EB;
}
body .ant-tag.deposit {
  background: #F2F7EE;
  color: #3AB100;
}
body .ant-tag.direct-deposit {
  background: #fcffc9;
  color: #298A00;
  padding-left: 9.3px;
}
body .ant-tag.withdrawal {
  background: #F0FAFF;
  color: #4096E8;
}
body .ant-tag.marketplace-purchase {
  background: #FAF1FF;
  color: #8034C7;
}
body .ant-tag.cashback {
  background: #FFF0F9;
  color: #C76DB8;
}
body .ant-tag.referrarl {
  background: #E5FAFF;
  color: #23D5FF;
}
body .ant-tag.round {
  border-radius: 50% !important;
  width: 32px;
  height: 32px;
  display: flex;
}
body .ant-dropdown .ant-table-filter-dropdown .ant-dropdown-menu .ant-dropdown-menu-item img.icon {
  max-width: 32px;
  padding: 7px;
}
body .searchbox {
  padding: 24px 0px 24px 0px;
  border-radius: 12px;
  width: 30%;
  padding-bottom: 24px;
}
body .searchbox .ant-input.ant-input-lg {
  border-top-left-radius: 12px;
  border-bottom-left-radius: 12px;
}
body .searchbox span.ant-input-affix-wrapper.ant-input-affix-wrapper-lg {
  border-top-left-radius: 12px !important;
  border-bottom-left-radius: 12px !important;
}
body .searchbox button.ant-btn.ant-btn-lg.ant-btn-icon-only.ant-input-search-button {
  border-top-right-radius: 12px !important;
  border-bottom-right-radius: 12px !important;
}
body .ant-tabs.ant-tabs-top {
  width: 100%;
}
body .reactEasyCrop_CropArea.reactEasyCrop_CropAreaGrid {
  width: 250px !important;
  height: 100px !important;
}
body span.anticon.anticon-search {
  vertical-align: middle;
}
body .reactEasyCrop_CropArea.reactEasyCrop_CropAreaRound {
  width: 260px !important;
  height: 260px !important;
}

.logo-menu-layout {
  display: inline-flex;
  margin-top: 20px;
  margin-bottom: 13.5px;
  width: 100%;
}
.logo-menu-layout-expanded {
  justify-content: flex-start;
  padding-left: 20px;
}
.logo-menu-layout-collapsed {
  justify-content: center;
  padding-left: 0;
}

.search-box-goals {
  display: block;
  width: 100%;
  padding-bottom: 24px;
}
.search-box-goals .search-input-goals {
  border-radius: 12px;
  width: 30%;
  border-top-left-radius: 12px;
  border-bottom-left-radius: 12px;
}
.search-box-goals .search-input-goals .ant-input.ant-input-lg {
  border-top-left-radius: 12px;
  border-bottom-left-radius: 12px;
}
.search-box-goals .search-input-goals span.ant-input-affix-wrapper.ant-input-affix-wrapper-lg {
  border-top-left-radius: 12px !important;
  border-bottom-left-radius: 12px !important;
}
.search-box-goals .search-input-goals button.ant-btn.ant-btn-lg.ant-btn-icon-only.ant-input-search-button {
  border-top-right-radius: 12px !important;
  border-bottom-right-radius: 12px !important;
}
.search-box-goals .button-row {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  float: right;
}
.search-box-goals .button-row .switch-deleted-goals {
  float: right;
  padding: 16px;
}
.search-box-goals .button-row .switch-deleted-goals .title {
  color: #525252;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  padding: 16px;
}
.search-box-goals .button-row .sync-button {
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 200px;
  max-height: 100px;
  position: relative;
}
.search-box-goals .button-row .sync-button span.anticon.anticon-sync {
  position: absolute;
  display: flex;
  align-items: center;
  top: 0;
  bottom: 0;
  right: 105px;
}
.goal-name-row {
  display: flex;
}
.goal-name-row .goal-name {
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  color: #525252;
}
.goal-name-row .goal-image {
  max-width: 72px;
  max-height: 40px;
}
.goal-name-row .goal-image .ant-image-img {
  border-radius: 12px;
}
.goal-name-row .goal-with-progress {
  padding-left: 12px;
}
.goal-name-row .goal-with-progress .goal-name {
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  color: #525252;
}

.affiliate {
  display: flex;
  justify-content: center;
  align-items: center;
}
.affiliate .icon {
  width: 80px;
  padding-right: 5px;
  margin-left: -25px;
}
.affiliate .name {
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  color: #525252;
}

.cashback-available {
  background: #FCFFE6;
  border-radius: 6px;
  font-size: 14px;
  line-height: 22px;
  color: #A0D911;
}
.cashback-canceled {
  background: #FFF1F0;
  border-radius: 6px;
  font-size: 14px;
  line-height: 22px;
  color: #F5222D;
}
.cashback-collected {
  background: #F2F7EE;
  border-radius: 6px;
  font-size: 14px;
  line-height: 22px;
  color: #3AB100;
}
.cashback-processing {
  background: #F0F5FF;
  border-radius: 6px;
  font-size: 14px;
  line-height: 22px;
  color: #4096E8;
}
.cashback-expired {
  background: #FFF7E6;
  border-radius: 6px;
  font-size: 14px;
  line-height: 22px;
  color: #FA8C16;
}
.cashback-pending {
  background: #E6E6E6;
  border-radius: 6px;
  font-size: 14px;
  line-height: 22px;
  color: #525252;
}

.cashback-details {
  display: block;
  margin: 0 !important;
  background-color: #F5F5F5;
}
.cashback-details .cashback-item {
  padding: 10px;
}
.cashback-details .cashback-item .item-details .item-value {
  display: block;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  align-items: center;
  color: #525252;
  padding-top: 5px;
}
.cashback-details .cashback-item .item-details .item-title {
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  display: flex;
  align-items: center;
  color: #737373;
}
.cashback-details .title {
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  display: flex;
  align-items: center;
  color: #000000;
}
.cashback-details .transfer-type {
  display: flex;
  padding: 25px 0px 0px 10px;
}
.cashback-details .transfer-type .title {
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  display: flex;
  align-items: center;
  color: #000000;
}
.cashback-details .ant-divider-horizontal {
  margin: 15px 0;
}

.transfer-type {
  display: flex;
  padding: 0px 0px 0px;
}
.transfer-type .title-head {
  justify-content: center;
  align-items: center;
  display: flex;
}
.ant-divider.ant-divider-horizontal.transfer-type-devider {
  margin: 10px 0;
}

.movemoneyTransations-details {
  display: flex;
  padding: 24px 0px 0px 60px;
}
.movemoneyTransations-details .item-title {
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  color: #737373;
}
.movemoneyTransations-details .item-title.block {
  display: block ;
}
.movemoneyTransations-details .item-value {
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  display: flex;
  align-items: center;
  color: #525252;
}
.movemoneyTransations-details .item-value .bank-account-icon {
  padding-right: 5px;
}
.movemoneyTransations-details .badge-status {
  color: #8034C7;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  padding-top: 8px;
}
.movemoneyTransations-details .badge-status.block {
  display: block;
}
.movemoneyTransations-details .badge-status.withdrawal {
  color: #4096E8;
}
.movemoneyTransations-details .badge-status .title {
  padding-left: 10px;
}

.transaction-table {
  width: 100%;
}

.cashbackActivity-details {
  display: flex;
  padding: 24px 0px 0px 60px;
}
.cashbackActivity-details .item-title {
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  color: #737373;
}
.cashbackActivity-details .item-value {
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  display: flex;
  align-items: center;
  color: #525252;
}
.cashbackActivity-details .badge-status {
  color: #8034C7;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
}

.add-new-category {
  font-size: 14px;
  line-height: 22px;
  color: #3AB100;
}
.add-new-category .add-new-icon {
  padding-right: 5px;
}
.action-icons {
  display: flex;
  justify-content: flex-end;
}
.action-icons .icon-circle {
  background: #FFFFFF;
  border: 1px solid #BFBFBF;
  box-sizing: border-box;
  border-radius: 10px;
  margin-right: 5px;
}
.action-icons .icon-circle .anticon {
  width: 24px;
  padding: 5px;
}
.title {
  font-size: 14px;
  line-height: 22px;
  color: #424242;
}
.title .affiliates-count {
  padding-left: 5px;
  font-size: 14px;
  line-height: 22px;
  color: #424242;
}
.category-icon {
  max-height: 24px;
  max-width: 24px;
}
.modal .ant-modal-header {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
.modal .ant-modal-content {
  border-radius: 10px;
}
.modal .row-modal {
  display: flex;
  padding-bottom: 30px;
}
.modal .row-modal .title-field {
  font-size: 14px;
  line-height: 22px;
  color: #262626;
  display: flex;
  padding-bottom: 5px;
}
.modal .row-modal .name {
  background: #FFFFFF;
  border: 1px solid #BFBFBF;
  box-sizing: border-box;
  border-radius: 12px;
}
.modal .row-modal.upload-row {
  display: block;
  padding-bottom: 5px;
}
.modal .row-modal.upload-row .upload-button {
  border: 1px solid #BFBFBF;
  box-sizing: border-box;
  border-radius: 11px;
}
.modal .row-modal .status-row {
  background: #F5F5F5;
  padding-top: 32px;
  display: block;
}
.modal .image-description {
  font-size: 12px;
  line-height: 20px;
}
.modal .footer-cancel-button {
  border: 1px solid #BFBFBF;
  box-sizing: border-box;
  border-radius: 11px;
}
.modal .footer-submit-button {
  border-radius: 11px;
}
.modal .edit-platform-image {
  border: 1px solid #BFBFBF;
  box-sizing: border-box;
  border-radius: 2px;
  height: 48px;
  display: flex;
  align-items: center;
}
.modal .anticon.anticon-delete.delete-icon-modal {
  color: #F5222D;
}
.ant-card.ant-card-bordered.collapsed-off {
  height: 100%;
}
.ant-card.ant-card-bordered.collapsed-off .ant-card-body {
  display: block !important;
}
.ant-card.ant-card-bordered.collapsed-in {
  height: auto;
}
.ant-card.ant-card-bordered.collapsed-in .ant-card-body {
  display: none !important;
}
.categories-table .ant-table-thead {
  display: none;
}
.row-dragging {
  background: #fafafa;
  border: 1px solid #ccc;
  color: #262626;
}
.row-dragging td {
  padding: 12px;
}
.row-dragging td:nth-child(4) {
  visibility: hidden;
}
.row-dragging .drag-visible {
  visibility: visible;
}

.modal-header-title {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #262626;
}
.save-button {
  border-radius: 11px;
}
.cancel-button {
  border-radius: 11px;
}
.row-item {
  width: 100%;
  display: flex;
  align-items: center;
  padding: 24px 0px 0px 0px;
}
.row-item.no-padding {
  padding: 0px 0px 0px 0px;
}
.row-item.status-row {
  margin-top: 32px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #F5F5F5;
  padding-bottom: 0px;
}
.row-item.status-row .status-field {
  padding: 16px;
}
.row-item.status-row .status-field .title-field {
  display: flex;
}
.row-item.table-row {
  padding: 10px 0px 0px 0px;
}
.row-item.table-row .row-dragging {
  background: #fafafa;
  border: 1px solid #ccc;
  color: #262626;
  visibility: visible !important;
}
.row-item.table-row .row-dragging tr {
  visibility: visible !important;
}
.row-item.table-row .row-dragging td {
  padding: 12px;
  visibility: visible !important;
}
.row-item.table-row .row-dragging .drag-visible {
  visibility: visible !important;
}
.row-item .ant-table-thead {
  display: none;
}
.row-item .ant-table-wrapper {
  width: 100%;
}
.row-item .affiliate-name {
  display: flex;
  width: 100%;
}
.row-item .affiliate-name .edit-head-item .affiliate-icon .ant-upload.ant-upload-select.ant-upload-select-picture-card {
  max-width: 92px;
  max-height: 92px;
  border-radius: 50%;
}
.row-item .affiliate-name .edit-head-item .affiliate-icon .edit-render-uploaded-image {
  max-width: 62px;
}
.row-item .affiliate-name .edit-head-item.name {
  width: 100%;
}
.row-item .affiliate-name .edit-head-item.name .item-title {
  font-size: 14px;
  line-height: 22px;
  color: #262626;
  padding-bottom: 2px;
}
.row-item .affiliate-name .edit-head-item.name .item-input {
  border-radius: 12px;
  width: 80%;
}
.row-item .affiliate-name .edit-head-item.name .item-input.with-element .ant-input-group-addon:first-child {
  border-right: 0;
  border-top-left-radius: 12px !important;
  border-bottom-left-radius: 12px !important;
}
.row-item .affiliate-name .edit-head-item.name .item-input.with-element .ant-input-group > .ant-input:last-child,
.row-item .affiliate-name .edit-head-item.name .item-input.with-element .ant-input-group-addon:last-child {
  border-top-right-radius: 12px !important;
  border-bottom-right-radius: 12px !important;
}
.row-item .affiliate-name .edit-head-item.name .button-delete {
  margin-left: 5px;
  border-radius: 12px;
  border-color: #FF4D4F;
}
.row-item .affiliate-name .edit-head-item.name .button-delete:hover,
.row-item .affiliate-name .edit-head-item.name .button-delete:active {
  color: #FF4D4F;
}
.row-item .item-title {
  font-size: 14px;
  line-height: 22px;
  color: #262626;
  padding-bottom: 2px;
}
.row-item .item-input {
  border-radius: 12px;
}
.row-item .item-input.with-element .ant-input-group-addon:first-child {
  border-right: 0;
  border-top-left-radius: 12px !important;
  border-bottom-left-radius: 12px !important;
}
.row-item .item-input.with-element .ant-input-group > .ant-input:last-child,
.row-item .item-input.with-element .ant-input-group-addon:last-child {
  border-top-right-radius: 12px !important;
  border-bottom-right-radius: 12px !important;
}
.row-item .main-title {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  align-items: center;
  color: #262626;
}
.row-item .affiliate {
  display: block;
  padding-left: 5px;
}
.row-item .affiliate .icon {
  border-radius: 50%;
  width: 76px;
}
.row-item .new-promotion-section {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  align-content: space-between;
  width: 100%;
}
.row-item .new-promotion-section .remove-promotion {
  padding-bottom: 5px;
}
.row-item .new-promotion-section .remove-promotion .button-delete {
  margin-left: 5px;
  border-radius: 12px;
  border-color: #FF4D4F;
}
.row-item .new-promotion-section .remove-promotion .button-delete:hover,
.row-item .new-promotion-section .remove-promotion .button-delete:active {
  color: #FF4D4F;
}
.delete-modal .anticon.anticon-delete.delete-icon-modal {
  color: #F5222D !important;
}
.delete-modal .ant-modal-confirm-btns .ant-btn {
  background: #FFFFFF;
  border: 1px solid #BFBFBF;
  box-sizing: border-box;
  border-radius: 11px;
}
.delete-modal .ant-modal-confirm-btns .ant-btn:hover,
.delete-modal .ant-modal-confirm-btns .ant-btn:active {
  color: #525252;
}
.delete-modal .ant-modal-confirm-btns .ant-btn.ant-btn-primary {
  border: 1px solid #F5222D;
  background: #F5222D;
  color: #FFFFFF;
}
.delete-modal .ant-modal-confirm-btns .ant-btn.ant-btn-primary:hover,
.delete-modal .ant-modal-confirm-btns .ant-btn.ant-btn-primary:active {
  color: #FFFFFF;
}
.row-dragging {
  background: #fafafa;
  border: 1px solid #ccc;
  color: #262626;
}
.row-dragging td {
  padding: 12px;
}
.row-dragging td:nth-child(4) {
  visibility: hidden;
}
.row-dragging .drag-visible {
  visibility: visible;
}

.ant-alert.ant-alert-closable {
  padding-right: 30px;
  border-radius: 12px;
}

.add-new-platform {
  font-size: 14px;
  line-height: 22px;
  color: #3AB100;
}
.add-new-platform .add-new-icon {
  padding-right: 5px;
}
.action-icons {
  display: flex;
  justify-content: flex-end;
}
.action-icons .icon-circle {
  background: #FFFFFF;
  border: 1px solid #BFBFBF;
  box-sizing: border-box;
  border-radius: 10px;
  margin-right: 5px;
}
.action-icons .icon-circle .anticon {
  width: 24px;
  padding: 5px;
}
.title {
  font-size: 14px;
  line-height: 22px;
  color: #424242;
}
.platform-icon {
  max-height: 24px;
  max-width: 24px;
  border-radius: 50%;
}
.modal .ant-modal-header {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
.modal .ant-modal-content {
  border-radius: 10px;
}
.modal .row-modal {
  display: flex;
  padding-bottom: 30px;
}
.modal .row-modal .title-field {
  font-size: 14px;
  line-height: 22px;
  color: #262626;
  display: flex;
  padding-bottom: 5px;
}
.modal .row-modal .name {
  background: #FFFFFF;
  border: 1px solid #BFBFBF;
  box-sizing: border-box;
  border-radius: 12px;
}
.modal .row-modal.upload-row {
  display: block;
  padding-bottom: 5px;
}
.modal .row-modal.upload-row .upload-button {
  border: 1px solid #BFBFBF;
  box-sizing: border-box;
  border-radius: 11px;
}
.modal .row-modal.status-row {
  margin-top: 32px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #F5F5F5;
  padding-bottom: 0px;
}
.modal .row-modal.status-row .status-field {
  padding: 16px;
}
.modal .image-description {
  font-size: 12px;
  line-height: 20px;
}
.modal .footer-cancel-button {
  border: 1px solid #BFBFBF;
  box-sizing: border-box;
  border-radius: 11px;
}
.modal .footer-submit-button {
  border-radius: 11px;
}
.modal .edit-platform-image {
  border: 1px solid #BFBFBF;
  box-sizing: border-box;
  border-radius: 2px;
  height: 48px;
  display: flex;
  align-items: center;
}
.modal .anticon.anticon-delete.delete-icon-modal {
  color: #F5222D;
}
.ant-card.ant-card-bordered.collapsed-off {
  height: 100%;
}
.ant-card.ant-card-bordered.collapsed-off .ant-card-body {
  display: block !important;
}
.ant-card.ant-card-bordered.collapsed-in {
  height: auto;
}
.ant-card.ant-card-bordered.collapsed-in .ant-card-body {
  display: none !important;
}
.disabled-row {
  background-color: #dcdcdc;
}
.disabled-row:hover {
  background-color: #dcdcdc;
}
.disabled-row:active {
  background-color: #dcdcdc;
}
.platform-table .ant-table-thead {
  display: none;
}

.collapsed-in .ant-card-body {
  display: none !important;
}
.collapsed-off .ant-card-body {
  display: block !important;
}
.affiliate-list {
  display: flex;
  align-items: center;
}
.affiliate-list .icon {
  width: 68px;
  padding-right: 6px;
}
.affiliate-list .name {
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  color: #525252;
}
.affiliate-item {
  display: flex;
  justify-content: space-between;
  padding-bottom: 24px;
}
.affiliate-item.deletion-items {
  justify-content: flex-end;
}
.affiliate-item .searchbox {
  padding: 0px;
  padding-bottom: 0px;
  display: flex;
  justify-content: center;
  align-items: flex-end;
}
.affiliate-item .searchbox .export-button {
  display: flex;
  justify-content: center;
  align-items: center;
}
.affiliate-item .searchbox .search-input {
  padding-left: 8px;
}
.platform-title {
  padding-left: 5px;
}
.category-name {
  padding-left: 13px;
}
.tab-title {
  font-size: 16px;
  line-height: 24px;
  font-weight: normal;
}

.reactEasyCrop_CropArea.reactEasyCrop_CropAreaRound {
  width: 124px;
  height: 124px;
}
.modal-header-title {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #262626;
}
.save-button {
  border-radius: 11px;
}
.cancel-button {
  border-radius: 11px;
}
.row-item {
  width: 100%;
  display: flex;
  align-items: center;
  padding: 24px 0px 0px 0px;
}
.row-item.no-padding {
  padding: 0px 0px 0px 0px;
}
.row-item .affiliate-name {
  display: flex;
  width: 100%;
}
.row-item .affiliate-name .head-item .affiliate-icon .ant-upload.ant-upload-select.ant-upload-select-picture-card {
  width: 92px;
  height: 92px;
  border-radius: 50%;
}
.row-item .affiliate-name .head-item .affiliate-icon .render-uploaded-image {
  width: 62px;
}
.row-item .affiliate-name .head-item .affiliate-icon.error .ant-upload.ant-upload-select.ant-upload-select-picture-card {
  border-color: red;
}
.row-item .affiliate-name .head-item .affiliate-icon.error .ant-upload.ant-upload-select.ant-upload-select-picture-card .ant-upload {
  color: red;
}
.row-item .affiliate-name .head-item.name {
  width: 100%;
}
.row-item .affiliate-name .head-item.name .item-title {
  font-size: 14px;
  line-height: 22px;
  color: #262626;
  padding-bottom: 2px;
}
.row-item .affiliate-name .head-item.name .item-input {
  border-radius: 12px;
}
.row-item .affiliate-name .head-item.name .item-input.with-element .ant-input-group-addon:first-child {
  border-right: 0;
  border-top-left-radius: 12px !important;
  border-bottom-left-radius: 12px !important;
}
.row-item .affiliate-name .head-item.name .item-input.with-element .ant-input-group > .ant-input:last-child,
.row-item .affiliate-name .head-item.name .item-input.with-element .ant-input-group-addon:last-child {
  border-top-right-radius: 12px !important;
  border-bottom-right-radius: 12px !important;
}
.row-item .item-title {
  font-size: 14px;
  line-height: 22px;
  color: #262626;
  padding-bottom: 2px;
}
.row-item .item-input {
  border-radius: 12px;
}
.row-item .item-input.with-element .ant-input-group-addon:first-child {
  border-right: 0;
  border-top-left-radius: 12px !important;
  border-bottom-left-radius: 12px !important;
}
.row-item .item-input.with-element .ant-input-group > .ant-input:last-child,
.row-item .item-input.with-element .ant-input-group-addon:last-child {
  border-top-right-radius: 12px !important;
  border-bottom-right-radius: 12px !important;
}
.row-item .main-title {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  align-items: center;
  color: #262626;
}
.row-item .relation-options {
  display: flex;
  width: 100%;
}
.row-item .relation-options .platform-item {
  width: 100%;
}
.row-item .relation-options .platform-item.first-item {
  padding-right: 24px;
}
.row-item .relation-options .platform-item .item-select {
  display: block;
}
.row-item .relation-options .platform-item .item-select .ant-select-selector {
  border: 1px solid #BFBFBF;
  box-sizing: border-box;
  border-radius: 12px;
}
.row-item .percentage-items {
  display: flex;
  padding-top: 12px;
}
.row-item .percentage-items .percentage.first-item {
  padding-right: 24px;
}
.row-item .percentage-items .percentage .item-title {
  font-size: 14px;
  line-height: 22px;
  color: #262626;
  padding-bottom: 2px;
}
.row-item .percentage-items .percentage .item-input {
  border-radius: 12px;
}
.row-item .percentage-items .percentage .item-input.with-element-after .ant-input-group > .ant-input:first-child,
.row-item .percentage-items .percentage .item-input.with-element-after .ant-input-group-addon:first-child {
  border-top-left-radius: 12px;
  border-bottom-left-radius: 12px;
}
.row-item .percentage-items .percentage .item-input.with-element-after .ant-input-group > .ant-input:last-child,
.row-item .percentage-items .percentage .item-input.with-element-after .ant-input-group-addon:last-child {
  border-top-right-radius: 12px !important;
  border-bottom-right-radius: 12px !important;
}
.row-item .new-promotion-section {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  align-content: space-between;
  width: 100%;
}

.suggestion-item.total {
  display: flex;
  align-items: center;
  justify-content: center;
}
.suggestion-item.total .total-suggestions-box {
  display: block;
  padding-bottom: 17px;
}
.suggestion-item.total .total-suggestions-box .icon-center {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 17px;
}
.suggestion-item.total .total-suggestions-box .icon-center .icon-round {
  border-radius: 50%;
  background: #F5F5F5;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.suggestion-item.total .total-suggestions-box .suggestion-details {
  display: block;
  text-align: center;
}
.suggestion-item.total .total-suggestions-box .suggestion-details .title {
  font-size: 14px;
  line-height: 22px;
  text-align: center;
  color: #737373;
  display: block;
  padding-bottom: 5px;
}
.suggestion-item.total .total-suggestions-box .suggestion-details .value {
  display: contents;
  text-align: center;
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
  color: #262626;
}
.ant-list-split .ant-list-item {
  border-bottom: none;
}
.ant-list-split .ant-list-item {
  border-bottom: none;
}
.header-title {
  font-size: 12px;
  line-height: 20px;
  color: #737373;
}
.index-item {
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  color: #525252;
}
.index-item .item-name .item-count {
  color: #737373;
  padding-left: 5px;
}
.collapsed-in .ant-card-body {
  display: none !important;
}
.collapsed-off .ant-card-body {
  display: block !important;
}
.ant-card {
  border-radius: 12px;
}
.export-button {
  display: flex;
  justify-content: flex-end;
}

.user-status-multiple-select {
  width: 100% !important;
  padding-top: 10px;
  padding-bottom: 10px;
}
.ant-select-selector {
  border-radius: 12px !important;
}

.notification-template-view {
  width: 100%;
  border-color: #97D667;
  margin-bottom: 3vh;
}
.notification-template-view .item-value {
  color: #97D667 !important;
  font-size: 1.4em;
}

.row-header-actions {
  justify-content: space-between;
}
.row-header-actions .header-actions {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-bottom: 10px;
}

.transaction-affiliate-row-item .row-item {
  width: 100%;
  display: flex;
  align-items: center;
  padding: 24px 0px 0px 0px;
}
.transaction-affiliate-row-item .row-item.no-padding {
  padding: 0px 0px 0px 0px;
}
.transaction-affiliate-row-item .row-item .fullwidth-input {
  display: flex;
  width: 100%;
}
.transaction-affiliate-row-item .row-item .ant-select-selector {
  border-radius: 12px;
}
.ant-popover.ant-popover-placement-bottom {
  max-width: 400px;
}

.row-card {
  margin-top: 24px;
  display: block;
}
.row-card.statistic-row {
  display: flex;
  width: 100%;
  padding: 24px 0px 12px 0px;
}
.row-card.statistic-row .card-item {
  background: #FFFFFF;
  border: 1px solid #BFBFBF;
  box-sizing: border-box;
  border-radius: 12px;
}
.row-card.statistic-row .card-item .statistic-item {
  text-align: center;
  justify-content: center;
  align-items: center;
  display: flex;
}
.row-card.statistic-row .card-item .statistic-item .statistic-value {
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
  color: #525252;
}
.row-card.statistic-row .card-item .statistic-item .statistic-name {
  font-weight: normal;
  font-size: 11px;
  line-height: 18px;
  color: #737373;
}

.promotion-item {
  width: 100%;
  background: white;
}
.promotion-item .promotion-panel .promotion-title-section {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: #262626;
}
.promotion-item .promotion-panel .promotion-row {
  width: 100%;
  margin: 0px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #D1D1D1;
  box-sizing: border-box;
  border-radius: 12px;
}
.promotion-item .promotion-panel .promotion-row .promotion-field-item {
  font-weight: 600 !important;
  font-size: 14px !important;
  line-height: 22px !important;
  color: #262626 !important;
  padding-top: 24px;
}
.promotion-item .promotion-panel .promotion-row .promotion-field-value {
  font-size: 14px !important;
  line-height: 22px !important;
  color: #525252 !important;
}

.modal-header-title {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #262626;
}
.save-button {
  border-radius: 11px;
}
.cancel-button {
  border-radius: 11px;
}
.row-item {
  width: 100%;
  display: flex;
  align-items: center;
  padding: 24px 0px 0px 0px;
}
.row-item.no-padding {
  padding: 0px 0px 0px 0px;
}
.row-item .affiliate-name {
  display: flex;
  width: 100%;
}
.row-item .affiliate-name .head-item .affiliate-icon .ant-upload.ant-upload-select.ant-upload-select-picture-card {
  width: 92px;
  height: 92px;
  border-radius: 50%;
}
.row-item .affiliate-name .head-item .affiliate-icon .render-uploaded-image {
  width: 62px;
}
.row-item .affiliate-name .head-item .affiliate-icon.error .ant-upload.ant-upload-select.ant-upload-select-picture-card {
  border-color: red;
}
.row-item .affiliate-name .head-item .affiliate-icon.error .ant-upload.ant-upload-select.ant-upload-select-picture-card .ant-upload {
  color: red;
}
.row-item .affiliate-name .head-item.name {
  width: 100%;
}
.row-item .affiliate-name .head-item.name .item-title {
  font-size: 14px;
  line-height: 22px;
  color: #262626;
  padding-bottom: 2px;
}
.row-item .affiliate-name .head-item.name .item-input {
  border-radius: 12px;
}
.row-item .affiliate-name .head-item.name .item-input.with-element .ant-input-group-addon:first-child {
  border-right: 0;
  border-top-left-radius: 12px !important;
  border-bottom-left-radius: 12px !important;
}
.row-item .affiliate-name .head-item.name .item-input.with-element .ant-input-group > .ant-input:last-child,
.row-item .affiliate-name .head-item.name .item-input.with-element .ant-input-group-addon:last-child {
  border-top-right-radius: 12px !important;
  border-bottom-right-radius: 12px !important;
}
.row-item .item-title {
  font-size: 14px;
  line-height: 22px;
  color: #262626;
  padding-bottom: 2px;
}
.row-item .item-input {
  border-radius: 12px;
}
.row-item .item-input.with-element .ant-input-group-addon:first-child {
  border-right: 0;
  border-top-left-radius: 12px !important;
  border-bottom-left-radius: 12px !important;
}
.row-item .item-input.with-element .ant-input-group > .ant-input:last-child,
.row-item .item-input.with-element .ant-input-group-addon:last-child {
  border-top-right-radius: 12px !important;
  border-bottom-right-radius: 12px !important;
}
.row-item .main-title {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  align-items: center;
  color: #262626;
}
.row-item .relation-options {
  display: flex;
  width: 100%;
}
.row-item .relation-options .platform-item {
  width: 100%;
}
.row-item .relation-options .platform-item.first-item {
  padding-right: 24px;
}
.row-item .relation-options .platform-item .item-select {
  display: block;
}
.row-item .relation-options .platform-item .item-select .ant-select-selector {
  border: 1px solid #BFBFBF;
  box-sizing: border-box;
  border-radius: 12px;
}
.row-item .percentage-items {
  display: flex;
  padding-top: 12px;
}
.row-item .percentage-items .percentage.first-item {
  padding-right: 24px;
}
.row-item .percentage-items .percentage .item-title {
  font-size: 14px;
  line-height: 22px;
  color: #262626;
  padding-bottom: 2px;
}
.row-item .percentage-items .percentage .item-input {
  border-radius: 12px;
}
.row-item .percentage-items .percentage .item-input.with-element-after .ant-input-group > .ant-input:first-child,
.row-item .percentage-items .percentage .item-input.with-element-after .ant-input-group-addon:first-child {
  border-top-left-radius: 12px;
  border-bottom-left-radius: 12px;
}
.row-item .percentage-items .percentage .item-input.with-element-after .ant-input-group > .ant-input:last-child,
.row-item .percentage-items .percentage .item-input.with-element-after .ant-input-group-addon:last-child {
  border-top-right-radius: 12px !important;
  border-bottom-right-radius: 12px !important;
}
.row-item .new-promotion-section {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  align-content: space-between;
  width: 100%;
}
.row-item .new-promotion-section .remove-promotion {
  padding-bottom: 5px;
}
.row-item .new-promotion-section .remove-promotion .button-delete {
  margin-left: 5px;
  border-radius: 12px;
  border-color: #FF4D4F;
}
.row-item .new-promotion-section .remove-promotion .button-delete:hover,
.row-item .new-promotion-section .remove-promotion .button-delete:active {
  color: #FF4D4F;
}
.row-item.status-row {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #F5F5F5;
  padding-top: 0px;
  padding-bottom: 0px;
}
.row-item.status-row .status-field {
  padding: 16px;
}
.row-item.status-row .status-field .title-field {
  display: flex;
}

.affiliate-details {
  display: block;
  padding: 22px 32px 147px 32px;
}
.affiliate-details .details-item {
  width: 100%;
}
.affiliate-details .details-item .affiliate-card-item .row-card-item {
  padding: 24px;
}
.affiliate-details .details-item .affiliate-card-item .row-card-item .affiliate-header-row {
  display: flex;
}
.affiliate-details .details-item .affiliate-card-item .row-card-item .affiliate-header-row .icon-item {
  width: 72px;
  height: 72px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
}
.affiliate-details .details-item .affiliate-card-item .row-card-item .affiliate-header-row .icon-item .icon {
  width: 72px;
}
.affiliate-details .details-item .affiliate-card-item .row-card-item .affiliate-header-row .name-details {
  display: flex;
  padding-left: 24px;
  justify-content: center;
  flex-direction: column;
}
.affiliate-details .details-item .affiliate-card-item .row-card-item .affiliate-header-row .name-details .name-item {
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
  display: flex;
  align-items: center;
  color: #262626;
}
.affiliate-details .details-item .affiliate-card-item .row-card-item .affiliate-header-row .name-details .details-item {
  display: block;
}
.affiliate-details .details-item .affiliate-card-item .row-card-item .affiliate-header-row .name-details .details-item .icon {
  width: 30px;
  height: 30px;
  padding: 5px;
}
.affiliate-details .details-item .affiliate-card-item .row-card-item .affiliate-status-action {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}
.affiliate-details .details-item .affiliate-card-item .row-card-item .affiliate-status-action .button-actions {
  padding-right: 7px;
}
.affiliate-details .details-item .affiliate-card-item .row-card-item .affiliate-status-action .button-actions .edit-affiliate-button {
  background: #FFFFFF;
  border: 1px solid #BFBFBF;
  box-sizing: border-box;
  border-radius: 11px;
  color: #525252;
  font-size: 14px;
  line-height: 22px;
}
.affiliate-details .details-item .affiliate-card-item .row-card-item .affiliate-status-action .button-actions .delete-affiliate-button {
  background: #FFFFFF;
  border: 1px solid #FF4D4F;
  box-sizing: border-box;
  border-radius: 11px;
  color: #FF4D4F;
  font-size: 14px;
  line-height: 22px;
}
.affiliate-details .details-item .affiliate-card-item .row-card-item .affiliate-status-action .button-actions .delete-affiliate-button.disabled {
  background: #FFFFFF;
  border: 1px solid #BFBFBF;
  box-sizing: border-box;
  border-radius: 11px;
  color: #A6A6A6;
  font-size: 14px;
  line-height: 22px;
}
.affiliate-details .details-item .affiliate-card-item .details-row-card-item .gutter-row {
  width: 100%;
}
.affiliate-details .details-item .affiliate-card-item .details-row-card-item .gutter-row .affiliate-field-item {
  font-weight: 600 !important;
  font-size: 14px !important;
  line-height: 22px !important;
  color: #262626 !important;
  padding-top: 24px;
}
.affiliate-details .details-item .affiliate-card-item .details-row-card-item .gutter-row .affiliate-field-value {
  font-size: 14px !important;
  line-height: 22px !important;
  color: #525252 !important;
}
.affiliate-details .details-item .affiliate-card-item .details-row-card-item .gutter-row .affiliate-field-value .icon {
  width: 30px;
  height: 30px;
  padding: 5px;
}
.affiliate-details .ant-spin-nested-loading {
  width: 100%;
}
.affiliate-details .row-card {
  margin-top: 24px;
  display: block;
  width: 100%;
}
.affiliate-details .row-card .affiliate-card-item {
  background: #FFFFFF;
  border: 1px solid #BFBFBF;
  box-sizing: border-box;
  border-radius: 12px;
}
.affiliate-details .row-card .affiliate-card-item .details-row-card-item .gutter-row {
  width: 100%;
  padding: 24px;
}
.affiliate-details .row-card.statistic-row {
  display: flex;
  width: 100%;
}
.affiliate-details .row-card.statistic-row .card-item {
  background: #FFFFFF;
  border: 1px solid #BFBFBF;
  box-sizing: border-box;
  border-radius: 12px;
}
.affiliate-details .row-card.statistic-row .card-item .statistic-item {
  text-align: center;
  justify-content: center;
  align-items: center;
  display: flex;
}
.affiliate-details .row-card.statistic-row .card-item .statistic-item .statistic-value {
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
  color: #525252;
}
.affiliate-details .row-card.statistic-row .card-item .statistic-item .statistic-name {
  font-weight: normal;
  font-size: 11px;
  line-height: 18px;
  color: #737373;
}

.subscription-list-search-row {
  display: flex;
  justify-content: end;
  padding-bottom: 24px;
}
.subscription-list-search-row svg {
  vertical-align: -webkit-baseline-middle;
}

.subscription-plan-item {
  /* padding: 10px; */
  display: block;
  /* display: flex; */
  /* padding-left: 10px; */
  justify-content: center;
  padding: 10px;
}
.subscription-plan-item .title {
  display: block;
}
.button-link {
  display: flex;
  justify-content: flex-end;
  text-align: center;
  width: 100%;
}

.withdraw-statistics .anticon svg {
  display: inline-block;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}
.withdraw-statistics .avatar-row {
  display: flex;
  text-align: center;
  padding-bottom: 10px;
  align-items: center;
}
.withdraw-statistics .avatar-row .avatar-item {
  color: '#3AB100' !important;
  max-width: 54px;
  max-height: 54px;
  line-height: 54px;
  justify-content: center;
  align-items: center;
}

.dashboard-content {
  display: block;
  padding: 22px 32px 147px 32px;
}
.dashboard-content .row-card {
  margin-top: 24px;
  display: block;
}
.dashboard-content .row-card .result-details {
  font-weight: 600 !important;
  font-size: 16px !important;
  line-height: 24px !important;
  display: flex;
  align-items: center;
  color: #262626 !important;
}
.dashboard-content .row-card .card-item {
  background: #FFFFFF;
  border: 1px solid #BFBFBF;
  box-sizing: border-box;
  border-radius: 12px;
  margin-top: 24px;
  display: flex;
}
.dashboard-content .row-card .card-item .ant-card-body {
  display: flex;
}
.dashboard-content .row-card .card-item .search-box {
  display: block;
  width: 100%;
}
.dashboard-content .row-card .card-item .search-box .title {
  font-weight: normal !important;
  font-size: 20px !important;
  line-height: 28px !important;
  display: flex;
  align-items: center;
  color: #262626 !important;
}
.dashboard-content .row-card .card-item .search-box .description {
  font-weight: normal !important;
  font-size: 16px !important;
  line-height: 24px !important;
  display: flex;
  align-items: center;
  color: #525252 !important;
  padding-bottom: 24px;
}
.dashboard-content .row-card .card-item .search-box .search-input {
  border-radius: 12px;
}
.dashboard-content .row-card .card-item .search-box .search-input span.ant-input-affix-wrapper.ant-input-affix-wrapper-lg {
  border-top-left-radius: 12px;
  border-bottom-left-radius: 12px;
}
.dashboard-content .row-card .card-item .search-box .search-input button.ant-btn.ant-btn-primary.ant-btn-lg.ant-input-search-button {
  border-top-right-radius: 12px;
  border-bottom-right-radius: 12px;
}
.dashboard-content .row-card .card-item .calendar-picker {
  display: flex;
  align-items: flex-end;
  width: 100%;
  justify-content: flex-end;
}
.dashboard-content .row-card .card-item .calendar-picker .ant-picker.ant-picker-range.dateRangePicker {
  border-radius: 12px;
}
.dashboard-content .row-card .card-item .calendar-picker .radio-calendar-filter {
  display: block;
}
.dashboard-content .row-card .card-item .calendar-picker .radio-calendar-filter .radio-group-filter {
  display: flex;
  justify-content: flex-end;
  margin-bottom: -10px;
}
.dashboard-content .row-card .card-item .anticon-arrow-right {
  color: #A6A6A6;
}
.dashboard-content .row-card .card-item .ant-table-content .table-item {
  display: inline-flex;
}
.dashboard-content .row-card .card-item .ant-table-content .table-item .alphabetic-item {
  width: 32px;
  height: 32px;
  background: #F5F5F5;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  font-size: 14px;
  line-height: 22px;
  color: #525252;
}
.dashboard-content .row-card .card-item .ant-table-content .table-item .item-concat {
  padding-left: 8px;
  display: block;
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  color: #262626;
  text-align: left;
}
.dashboard-content .row-card .card-item .ant-table-content .table-item .item-concat .email-item {
  font-size: 12px;
  line-height: 20px;
  color: #525252;
  font-weight: normal;
}
.dashboard-content .row-card .card-item .ant-table-content .ant-table-thead {
  font-weight: 600!important;
  font-size: 14px !important;
  line-height: 22px!important;
  color: #262626;
}
.dashboard-content .row-card .card-item .ant-table-content th.ant-table-cell {
  font-weight: 600!important;
  font-size: 14px !important;
  line-height: 22px!important;
  color: #262626;
}
.dashboard-content .row-card .card-item .ant-tag {
  border-radius: 6px;
  border: none;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  align-items: center;
}
.dashboard-content .row-card .card-item .ant-tag.active {
  background: #E4F0D8;
  color: #3AB100;
}
.dashboard-content .row-card .card-item .ant-tag.bank-connected {
  background: #FCFFE6;
  color: #7CB305;
}
.dashboard-content .row-card .card-item .ant-tag.bank-disconnected {
  background: #FFF7E6;
  color: #FA8C16;
}
.dashboard-content .row-card .card-item .ant-tag.delete-request {
  background: #FFF1F0;
  color: #F5222D;
}
.dashboard-content .row-card .card-item .ant-tag.not-verified {
  background: #F5F5F5;
  color: #737373;
}
.dashboard-content .row-card .card-item .ant-tag.onboarding {
  background: #F0F5FF;
  color: #2F54EB;
}
.dashboard-content .row-card .card-item .ant-tag.not-identified {
  background: #F0F5FF;
  color: #2F54EB;
}

.table-row .ant-table-content {
  width: 100%;
}
.table-row .ant-table-content .table-item {
  display: inline-flex;
}
.table-row .ant-table-content .table-item .alphabetic-item {
  width: 32px;
  height: 32px;
  background: #F5F5F5;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  font-size: 14px;
  line-height: 22px;
  color: #525252;
}
.table-row .ant-table-content .table-item .item-concat {
  padding-left: 8px;
  display: block;
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  color: #262626;
  text-align: left;
}
.table-row .ant-table-content .table-item .item-concat .email-item {
  font-size: 12px;
  line-height: 20px;
  color: #525252;
  font-weight: normal;
}
.table-row .ant-table-content .ant-table-thead {
  font-weight: 600!important;
  font-size: 14px !important;
  line-height: 22px!important;
  color: #262626;
}
.table-row .ant-table-content th.ant-table-cell {
  font-weight: 600!important;
  font-size: 14px !important;
  line-height: 22px!important;
  color: #262626;
}
.table-row .ant-table-wrapper {
  width: 100%;
}
.table-row .table-item {
  width: 100%;
}
.search-row {
  display: flex;
  justify-content: end;
  padding-bottom: 24px;
}
.search-row svg {
  vertical-align: -webkit-baseline-middle;
}
.search-row .searchbox {
  display: flex;
}

.row-card-content {
  padding-bottom: 24px;
}
.row-card-content .card-section {
  width: 100%;
}
.row-card-content .card-section .row-buttons {
  display: flex;
  align-items: center;
  justify-content: center;
}
.row-card-content .card-section .row-buttons .upload-button {
  vertical-align: middle;
}
.row-card-content .card-section .row-buttons .upload-button .btn-item {
  background: #FFFFFF;
  border: 1px solid #BFBFBF;
  box-sizing: border-box;
  border-radius: 12px !important;
}
.row-card-content .card-section .row-buttons .upload-button .btn-item:hover {
  background: white;
  border: 1px solid #3AB100;
  box-sizing: border-box;
  color: #3AB100;
  border-radius: 12px !important;
}
.row-card-content .card-section .row-buttons .reset-button {
  background: #FFFFFF;
  border: 1px solid #BFBFBF;
  box-sizing: border-box;
  border-radius: 12px !important;
}
.row-card-content .card-section .row-buttons .reset-button:hover {
  background: white;
  border: 1px solid #F5222D;
  box-sizing: border-box;
  color: #F5222D;
  border-radius: 12px !important;
}
.row-card-content .card-section .match-button {
  background: #3AB100;
  border: 1px solid #3AB100;
  color: white;
  box-sizing: border-box;
  border-radius: 12px !important;
  margin-right: 8px;
}
.row-card-content .card-section .match-button:hover {
  background: white;
  border: 1px solid #3AB100;
  box-sizing: border-box;
  color: #3AB100;
  border-radius: 12px !important;
}
.row-card-content .card-section .match-button.disabled {
  background: #FFFFFF;
  color: #3AB100;
  border: 1px solid #BFBFBF;
  box-sizing: border-box;
  border-radius: 12px !important;
}
.row-card-content .card-section .reject-button {
  background: #F5222D;
  border: 1px solid #F5222D;
  box-sizing: border-box;
  color: white;
  border-radius: 12px !important;
  margin-right: 8px;
}
.row-card-content .card-section .reject-button:hover {
  background: white;
  border: 1px solid #F5222D;
  box-sizing: border-box;
  color: #F5222D;
  border-radius: 12px !important;
}
.row-card-content .card-section .more-info-button {
  background: #BFBFBF;
  border: 1px solid #FFFFFF;
  box-sizing: border-box;
  color: white;
  border-radius: 12px !important;
  margin-right: 8px;
}
.row-card-content .card-section .more-info-button:hover {
  background: #FFFFFF;
  border: 1px solid #525252;
  box-sizing: border-box;
  color: #525252;
  border-radius: 12px !important;
}
.row-card-content .card-section .action-column {
  display: flex;
}
.row-card-content .card-section .action-column .reject-button {
  background: #F5222D;
  border: 1px solid #F5222D;
  box-sizing: border-box;
  color: white;
  border-radius: 12px !important;
  margin-right: 8px;
}
.row-card-content .card-section .action-column .reject-button:hover {
  background: white;
  border: 1px solid #F5222D;
  box-sizing: border-box;
  color: #F5222D;
  border-radius: 12px !important;
}
.row-card-content .card-section .action-column .more-info-button {
  background: #BFBFBF;
  border: 1px solid #FFFFFF;
  box-sizing: border-box;
  color: white;
  border-radius: 12px !important;
  margin-right: 8px;
}
.row-card-content .card-section .action-column .more-info-button:hover {
  background: #FFFFFF;
  border: 1px solid #525252;
  box-sizing: border-box;
  color: #525252;
  border-radius: 12px !important;
}
.row-card-content .card-section .general-button {
  background: #FFFFFF;
  color: #3AB100;
  border: 1px solid #BFBFBF;
  box-sizing: border-box;
  border-radius: 12px !important;
}
.row-card-content .card-section .modal-confirm-cashback {
  width: 80% !important;
}
.row-card-content .card-section .modal-confirm-cashback button.ant-btn {
  border-radius: 12px;
}
.row-card-content.row-child-changed {
  opacity: 0.04;
  pointer-events: none;
}
.ant-modal.modal-confirm-cashback {
  width: 80% !important;
  border-radius: 12px;
}
.ant-modal.modal-confirm-cashback button.ant-btn {
  border-radius: 12px;
}
.ant-modal.modal-uploaded-invoices {
  width: 40% !important;
}
.ant-modal.modal-uploaded-invoices button.ant-btn {
  border-radius: 12px;
}
.ant-modal.modal-uploaded-invoices .ant-modal-body {
  display: flex;
  justify-content: center;
}
.general-button {
  background: #FFFFFF;
  color: #3AB100;
  border: 1px solid #BFBFBF;
  box-sizing: border-box;
  border-radius: 12px !important;
}
.general-button.cancel {
  background: white;
  border: 1px solid #F5222D;
  box-sizing: border-box;
  color: #F5222D;
  border-radius: 12px !important;
}
.general-button.cancel:hover {
  background: white;
  border: 1px solid #F5222D;
  box-sizing: border-box;
  color: #F5222D;
  border-radius: 12px !important;
}
.amazon-request-row-item {
  display: flex;
  justify-content: center;
}
.amazon-request-row-item .amazon-affiliate-item {
  display: block;
  padding-bottom: 20px;
}
.amazon-request-row-item .amazon-affiliate-item .item-select {
  width: 100%;
}
.amazon-request-row-item .amazon-affiliate-item .item-title {
  font-size: 16px;
  display: block;
  color: #262626;
  padding-bottom: 15px;
}
.amazon-request-row-item .percentage-items {
  display: flex;
}
.amazon-request-row-item .percentage-items .percentage {
  width: 100%;
}
.amazon-request-row-item .percentage-items .percentage .item-title {
  color: #262626;
  font-size: 16px;
}
.amazon-request-row-item .percentage-items .percentage .item-input {
  border-radius: 12px;
  padding-top: 15px;
}
.amazon-request-row-item .percentage-items .percentage .item-input.with-element-after .ant-input-group > .ant-input:first-child,
.amazon-request-row-item .percentage-items .percentage .item-input.with-element-after .ant-input-group-addon:first-child {
  border-top-left-radius: 12px;
  border-bottom-left-radius: 12px;
}
.amazon-request-row-item .percentage-items .percentage .item-input.with-element-after .ant-input-group > .ant-input:last-child,
.amazon-request-row-item .percentage-items .percentage .item-input.with-element-after .ant-input-group-addon:last-child {
  border-top-right-radius: 12px !important;
  border-bottom-right-radius: 12px !important;
}
.amazon-request-row-item .total-amount-item {
  display: flex;
}
.amazon-request-row-item .total-amount-item .amazon-affiliate-item {
  padding: 20px;
}
.amazon-request-row-item .row-card-amazon.statistic-row {
  display: flex;
  width: 100%;
  padding: 24px 0px 12px 0px;
}
.amazon-request-row-item .row-card-amazon.statistic-row .card-item {
  background: #FFFFFF;
  border: 1px solid #BFBFBF;
  box-sizing: border-box;
  border-radius: 12px;
  height: 100%;
}
.amazon-request-row-item .row-card-amazon.statistic-row .card-item .statistic-item {
  text-align: center;
  justify-content: center;
  align-items: center;
  display: flex;
}
.amazon-request-row-item .row-card-amazon.statistic-row .card-item .statistic-item .statistic-value {
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
  color: #525252;
  display: block;
}
.amazon-request-row-item .row-card-amazon.statistic-row .card-item .statistic-item .statistic-value .statistic-name {
  font-weight: normal;
  font-size: 16px;
  line-height: 18px;
  color: #737373;
}
.amazon-request-row-item .row-card-amazon.statistic-row .card-item .button-item {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}
.amazon-request-row-item .row-card-amazon.row-child-hidden {
  opacity: 0.04;
  pointer-events: none;
}
.amazon-request-row-item .row-child-hidden {
  opacity: 0.04;
  pointer-events: none;
}
.amazon-request-row-item .item-input {
  border-radius: 12px;
  padding-top: 15px;
}
.amazon-request-row-item .item-input .ant-input-group > .ant-input:first-child,
.amazon-request-row-item .item-input .ant-input-group-addon:first-child {
  border-top-left-radius: 12px;
  border-bottom-left-radius: 12px;
}
.amazon-request-row-item .item-input .ant-input-group > .ant-input:last-child,
.amazon-request-row-item .item-input .ant-input-group-addon:last-child {
  border-top-right-radius: 12px !important;
  border-bottom-right-radius: 12px !important;
}
.row-modal {
  display: block;
}
.row-modal .form-item {
  display: block !important;
}
.row-modal .form-item .input-subject {
  border-radius: 12px;
}
.row-modal .form-item .input-msg {
  border-radius: 12px;
}
.percentage-rates {
  width: 100%;
}
.percentage-rates .collapsed-panel {
  width: 100%;
}
.confirm-icon {
  color: #3AB100;
}
.confirm-icon svg {
  width: 1.5em !important;
  height: 1.5em !important;
}
.returned-icon {
  color: #F5222D;
}
.returned-icon svg {
  width: 1.5em !important;
  height: 1.5em !important;
}
.expanded-matching {
  max-width: 80%;
  padding: 0.75rem;
  margin-bottom: 2rem;
  border: 0;
  flex-basis: 80%;
  flex-grow: 0;
  flex-shrink: 0;
}
.expanded-matching .matched-records {
  width: 100%;
}
.expanded-matching .matched-records .matched-records .ant-collapse-header {
  background: white;
}
.expanded-matching .matched-records .unmatched-records .ant-collapse-header {
  background: white;
}
.expanded-matching .matched-records .header-title {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
}
.expanded-matching .matched-records .header-title.matched {
  color: #58BD1E;
}
.expanded-matching .matched-records .header-title.unmatched {
  color: #F5222D;
}
.amazon-report-item-search {
  width: 80%;
}

html,
body {
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
}
body {
  background: #E8E8E8;
  -webkit-overflow-scrolling: touch;
  font-family: 'Inter', sans-serif;
  overflow-x: hidden;
  font-family: -apple-system, BlinkMacSystemFont, 'Inter', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
body .icon-styles {
  color: #3AB100 !important;
}
body .warning-style {
  color: #FA8C16 !important;
}
body .error-style {
  color: #F5222D !important;
  border-color: #FFA39E !important;
}
body .default-breadcrumb-item {
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
}
body .breadcrumb {
  margin: 3px 0;
  background-color: white;
  padding: 18px;
}
body .default-item {
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
}
body .ant-breadcrumb-link {
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
}
body .breadcrumb-subpage-title {
  background-color: white;
  margin-top: -26px;
  padding: 18px;
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
  color: #262626;
}
body .ant-pagination-item {
  border-radius: 12px;
}
body .ant-pagination-item-link {
  border-radius: 12px !important;
}
body .account-header {
  display: flex;
  justify-content: center;
  align-items: center;
}
body .ant-tag {
  border-radius: 6px;
  border: none;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  align-items: center;
}
body .ant-tag.active {
  background: #E4F0D8;
  color: #3AB100;
}
body .ant-tag.bank-connected {
  background: #FCFFE6;
  color: #7CB305;
}
body .ant-tag.not-identified {
  background: #f5695e;
  color: #f6ec8d;
}
body .ant-tag.bank-disconnected {
  background: #FFF7E6;
  color: #FA8C16;
}
body .ant-tag.delete-request {
  background: #FFF1F0;
  color: #F5222D;
}
body .ant-tag.not-verified {
  background: #F5F5F5;
  color: #737373;
}
body .ant-tag.maininvest-investors {
  background: #086996;
  color: #F5F5F5;
}
body .ant-tag.onboarding {
  background: #F0F5FF;
  color: #2F54EB;
}
body .ant-tag.deposit {
  background: #F2F7EE;
  color: #3AB100;
}
body .ant-tag.direct-deposit {
  background: #fcffc9;
  color: #298A00;
  padding-left: 9.3px;
}
body .ant-tag.withdrawal {
  background: #F0FAFF;
  color: #4096E8;
}
body .ant-tag.marketplace-purchase {
  background: #FAF1FF;
  color: #8034C7;
}
body .ant-tag.cashback {
  background: #FFF0F9;
  color: #C76DB8;
}
body .ant-tag.referrarl {
  background: #E5FAFF;
  color: #23D5FF;
}
body .ant-tag.round {
  border-radius: 50% !important;
  width: 32px;
  height: 32px;
  display: flex;
}
body .ant-dropdown .ant-table-filter-dropdown .ant-dropdown-menu .ant-dropdown-menu-item img.icon {
  max-width: 32px;
  padding: 7px;
}
body .searchbox {
  padding: 24px 0px 24px 0px;
  border-radius: 12px;
  width: 30%;
  padding-bottom: 24px;
}
body .searchbox .ant-input.ant-input-lg {
  border-top-left-radius: 12px;
  border-bottom-left-radius: 12px;
}
body .searchbox span.ant-input-affix-wrapper.ant-input-affix-wrapper-lg {
  border-top-left-radius: 12px !important;
  border-bottom-left-radius: 12px !important;
}
body .searchbox button.ant-btn.ant-btn-lg.ant-btn-icon-only.ant-input-search-button {
  border-top-right-radius: 12px !important;
  border-bottom-right-radius: 12px !important;
}
body .ant-tabs.ant-tabs-top {
  width: 100%;
}
body .reactEasyCrop_CropArea.reactEasyCrop_CropAreaGrid {
  width: 250px !important;
  height: 100px !important;
}
body span.anticon.anticon-search {
  vertical-align: middle;
}
body .reactEasyCrop_CropArea.reactEasyCrop_CropAreaRound {
  width: 260px !important;
  height: 260px !important;
}
body {
  font-family: "Inter", sans-serif !important;
  font-size: 18px;
  line-height: 21px;
  color: black !important;
  font-weight: 300 !important;
  font-style: normal !important;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Inter", sans-serif !important;
  color: black !important;
  font-weight: 300 !important;
}
h1,
.h1 {
  font-size: 32px !important;
  line-height: 37px;
}
h2,
.h2 {
  font-size: 30px !important;
  line-height: 35px;
}
h3,
.h3 {
  font-size: 28px !important;
  line-height: 33px;
}
h4,
.h4 {
  font-size: 26px !important;
  line-height: 30px;
}
h5,
.h5 {
  font-size: 24px !important;
  line-height: 28px;
}
h6,
.h6 {
  font-size: 22px !important;
  line-height: 26px;
}

