.dashboard-content {
    display: block;
    padding: 22px 32px 147px 32px;

    .row-card {
       
        margin-top: 24px;
        display: block;

        .card-flex-grow{
            display: block;
            min-height: calc(100% - 24px);
        }

        .user-card-item {
            background: #FFFFFF;
            border: 1px solid #BFBFBF;
            box-sizing: border-box;
            border-radius: 12px;
            margin-top: 24px;



            .user-name-row {
                
                display: flex;

                .initials-item {
                    width: 72px;
                    height: 72px;
                    background: #F5F5F5;
                    border-radius: 50%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    text-transform: uppercase;
                }                

                .name-item {
                    display: flex;
                    align-items: center;
                    padding-left: 12px;
                    justify-content: center;
                    align-items: center;
                    
                    .fullname-item {
                        font-weight: 600;
                        font-size: 20px;
                        line-height: 28px;
                        color: #262626;
                    }
                    .email-item{
                        font-weight: normal;
                        display: block;
                        font-size: 14px;
                        line-height: 22px;
                        color: #525252;
                    }
                }
            }

            .details-row-card-item {

                .gutter-row {
                    width: 100%;
                    padding: 24px;

                    .user-field-item {
                        font-weight: 600 !important;
                        font-size: 14px !important;
                        line-height: 22px !important;
                        color: #262626 !important;
                        padding-top: 24px;
                        
                        &.date {
                            font-weight: normal !important;
                            font-size: 14px !important;
                            line-height: 22px !important;
                            color: #737373 !important;
                        }
                    }

                    .user-field-value {
                        
                        font-size: 14px !important;
                        line-height: 22px !important;
                        color: #525252 !important;
                        
                        .bank-connected {
                            background: #FCFFE6;
                            color: #7CB305;
                            border: none;
                        }
                        .bank-disconnected {
                            background: #FFF7E6;
                            color: #FA8C16;
                            border: none;

                        }
                        .not-identified {
                            background: #FCFFE6;
                            color: #7CB305;
                            border: none;
                        }
                    }
                }
            }

            .user-status {
                display: block;
                float: right;

                .user-status-tag {
                        float: right;
                        padding-bottom: 16px;
                }

                .user-status-buttons {
                    display: block; 
                    
                    .initiate-deletion-button {
                        border-radius: 12px;
                        background: white;
                        color: #FF4D4F;
                        border: 1px solid #FF4D4F;
                    }

                    .cancel-request-deletion {
                        background: #FFFFFF;
                        border: 1px solid #BFBFBF;
                        box-sizing: border-box;
                        border-radius: 11px;
                        margin-right: 12px;
                        color: #525252;
                        font-weight: normal;
                        font-size: 14px;
                        line-height: 22px;
                    }

                    .delete-button {
                        background: #FF4D4F;
                        border-radius: 11px;
                        color: #FFFFFF;
                        font-weight: normal;
                        font-size: 14px;
                        line-height: 22px;
                        border: none;
                    }

                    .delete-button-disabled {
                        background: #BFBFBF;
                        border-radius: 11px;
                        color: #FFFFFF;
                        font-weight: normal;
                        font-size: 14px;
                        line-height: 22px;
                        border: none;
                    }
                }
            }
        }

        &.balance-row {
            display: flex;

            .card-item {
                background: #FFFFFF;
                border: 1px solid #BFBFBF;
                box-sizing: border-box;
                border-radius: 12px;

                .balance-item {
                    text-align: center;
                    justify-content: center;
                    align-items: center;
                    display: flex;
                    width: 100%;
                
                    .balance-value {
                        font-weight: 600;
                        font-size: 20px;
                        line-height: 28px;
                        color: #525252;
                    }

                    .balance-name {
                        font-weight: normal;
                        font-size: 11px;
                        line-height: 18px;
                        color: #737373;
                    }
                }               
              
            }
        }

        .card-item {
            display: flex;

            .saving-rules {
               display: flex;
                flex-grow: 1;
                width: 100%;
                justify-content: flex-start;
                .title {
                    font-weight: 600;
                    font-size: 16px;
                    line-height: 24px;
                    text-align: right;
                    color: #262626;
                }

                .saving-rules-details {
                    display: block;
                    width: 100%;
                    font-size: 14px;
                    line-height: 22px;
                    color: #525252;
                    padding-top: 8px;
                    
                    .saving-percentage {
                        padding: 0px 32px 0px 0px;
                    }
                    .spending-limit {
                        padding: 0px 0px 0px 32px;
                    }
                    .change-link {
                        float: right;
                    }
                }
            }

            .searchbox {
                padding: 24px 0px 24px 0px;
                border-radius: 12px;                    
                width: 30%;

                .ant-input.ant-input-lg {
                    border-top-left-radius: 12px;
                    border-bottom-left-radius: 12px;
                }

                span.ant-input-affix-wrapper.ant-input-affix-wrapper-lg {
                    border-top-left-radius: 12px !important;
                    border-bottom-left-radius: 12px !important;
                }
                button.ant-btn.ant-btn-lg.ant-btn-icon-only.ant-input-search-button {
                    border-top-right-radius: 12px !important;
                    border-bottom-right-radius: 12px !important;
                }
            }

            .move-money-type {
                padding-right: 12.5px;
                .arrow-destination{
                    padding:3.5px;
                }
                .destination {
                    color:#3AB100;
                }
            }

            .ant-tabs-tab-btn {
                font-weight: 600;
                font-size: 16px;
                line-height: 24px;
            }

            .goal-list{
                display: flex;
                flex-grow: 1;
                width: 100%;
            }
        }

        .user-field-item {
            font-weight: 600 !important;
            font-size: 14px !important;
            line-height: 22px !important;
            color: #262626 !important;
            padding-top: 24px;

            &.date {
                font-weight: normal !important;
                font-size: 14px !important;
                line-height: 22px !important;
                color: #737373 !important;
            }
        }

        .user-field-value {

            font-size: 14px !important;
            line-height: 22px !important;
            color: #525252 !important;

            .bank-connected {
                background: #FCFFE6;
                color: #7CB305;
                border: none;
            }
            .bank-disconnected {
                background: #FFF7E6;
                color: #FA8C16;
                border: none;

            }
            .not-identified {
                background: #FFF7E6;
                color: #FA8C16;
                border: none;

            }
        }
    }


    .refresh-row-card{
        width: 100%;
        display: flex;
        justify-content: space-between;
    }

    .ant-card-body {
        width: 100%;
    }

    .ant-table-wrapper {
        width: 100%;       
    }

    .ud-collapsed{
        max-height: 60px;
        height: initial;
        overflow: hidden;

        .ant-card-body{
            display: none !important;
        }
    }

    .ud-expanded{
        height: auto;
    }
}


.delete-button, .delete-button:hover, .delete-button:active{
    background: #FF4D4F !important;
    border-radius: 11px;
    color: #FFFFFF;
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
    border: none;
}

.cancel-deletion, .cancel-deletion:hover, .cancel-deletion:active {
    background: #FFFFFF;
    border: 1px solid #BFBFBF !important;
    box-sizing: border-box;
    border-radius: 11px;
    color: #525252;
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
}

