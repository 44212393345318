.search-box-goals {
    display: block;   
    width: 100%;
    padding-bottom: 24px;

    .search-input-goals {
        border-radius: 12px;                    
        width: 30%;
        border-top-left-radius: 12px;
        border-bottom-left-radius: 12px;
        .ant-input.ant-input-lg {
            border-top-left-radius: 12px;
            border-bottom-left-radius: 12px;
        }
        span.ant-input-affix-wrapper.ant-input-affix-wrapper-lg {
            border-top-left-radius: 12px !important;
            border-bottom-left-radius: 12px !important;
        }
        button.ant-btn.ant-btn-lg.ant-btn-icon-only.ant-input-search-button {
            border-top-right-radius: 12px !important;
            border-bottom-right-radius: 12px !important;
            
        }
    }
    .button-row {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        float: right;
        
        .switch-deleted-goals {
            float: right;
            padding: 16px;
            .title {
                color: #525252;
                font-weight: normal;
                font-size: 14px;
                line-height: 22px;
                padding: 16px;

            }
        }

        .sync-button {
            display: flex;
            align-items: center;
            justify-content: center;
            max-width: 200px;
            max-height: 100px;
            position: relative;
            
            span.anticon.anticon-sync {
                position: absolute;
                display: flex;
                align-items: center;
                top: 0;
                bottom: 0;
                right: 105px;
            }
        }
    }
}
.goal-name-row {
    display: flex;
    .goal-name {
        font-weight: 600;
        font-size: 14px;
        line-height: 22px;
        color: #525252;
    }
    .goal-image  {
        max-width: 72px;
        max-height: 40px;
        .ant-image-img { 
            border-radius: 12px; 
        }
    }
    .goal-with-progress {
        padding-left: 12px;

        .goal-name {
            font-weight: 600;
            font-size: 14px;
            line-height: 22px;
            color: #525252;
        }
    }
}
@backgroud-color: #E8E8E8;@primary-color: #3AB100;@primary-border: #58BD1E;@primary-background: #3AB100;@primary-hover: #E4F0D8;@primary-border-hover: #97D667;@info-color: #12b1d6;@warning-color: #FA8C16;@error-primary: #F5222D;@error-border: #FFA39E;@error-background: #FFF1F0;@white: #FFFFFF;@gray-border: #BFBFBF;@gray-background: #E6E6E6;@card-background: #FFFFFF;@text-color: #525252;