.promotion-item {
    width: 100%;
    background: white;

    .promotion-panel {
            .promotion-title-section {
                font-weight: 600;
                font-size: 16px;
                line-height: 24px;
                display: flex;
                align-items: center;
                color: #262626;
            }
          .promotion-row {
            width: 100%;
            margin: 0px !important;
            display: flex;
            justify-content: center;
            align-items: center;
            border: 1px solid #D1D1D1;
            box-sizing: border-box;
            border-radius: 12px;

            .promotion-field-item {
                font-weight: 600 !important;
                font-size: 14px !important;
                line-height: 22px !important;
                color: #262626 !important;
                padding-top: 24px;
            }

            .promotion-field-value {
                font-size: 14px !important;
                line-height: 22px !important;
                color: #525252 !important;
            }
        }
          
    }
}
@backgroud-color: #E8E8E8;@primary-color: #3AB100;@primary-border: #58BD1E;@primary-background: #3AB100;@primary-hover: #E4F0D8;@primary-border-hover: #97D667;@info-color: #12b1d6;@warning-color: #FA8C16;@error-primary: #F5222D;@error-border: #FFA39E;@error-background: #FFF1F0;@white: #FFFFFF;@gray-border: #BFBFBF;@gray-background: #E6E6E6;@card-background: #FFFFFF;@text-color: #525252;