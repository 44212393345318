@import url(http://fonts.googleapis.com/css?family=Playfair+Display+SC);
html,
body {
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
}
body {
  background: #E8E8E8;
  -webkit-overflow-scrolling: touch;
  font-family: 'Inter', sans-serif;
  overflow-x: hidden;
  font-family: -apple-system, BlinkMacSystemFont, 'Inter', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
body .icon-styles {
  color: #3AB100 !important;
}
body .warning-style {
  color: #FA8C16 !important;
}
body .error-style {
  color: #F5222D !important;
  border-color: #FFA39E !important;
}
body .default-breadcrumb-item {
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
}
body .breadcrumb {
  margin: 3px 0;
  background-color: white;
  padding: 18px;
}
body .default-item {
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
}
body .ant-breadcrumb-link {
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
}
body .breadcrumb-subpage-title {
  background-color: white;
  margin-top: -26px;
  padding: 18px;
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
  color: #262626;
}
body .ant-pagination-item {
  border-radius: 12px;
}
body .ant-pagination-item-link {
  border-radius: 12px !important;
}
body .account-header {
  display: flex;
  justify-content: center;
  align-items: center;
}
body .ant-tag {
  border-radius: 6px;
  border: none;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  align-items: center;
}
body .ant-tag.active {
  background: #E4F0D8;
  color: #3AB100;
}
body .ant-tag.bank-connected {
  background: #FCFFE6;
  color: #7CB305;
}
body .ant-tag.not-identified {
  background: #f5695e;
  color: #f6ec8d;
}
body .ant-tag.bank-disconnected {
  background: #FFF7E6;
  color: #FA8C16;
}
body .ant-tag.delete-request {
  background: #FFF1F0;
  color: #F5222D;
}
body .ant-tag.not-verified {
  background: #F5F5F5;
  color: #737373;
}
body .ant-tag.maininvest-investors {
  background: #086996;
  color: #F5F5F5;
}
body .ant-tag.onboarding {
  background: #F0F5FF;
  color: #2F54EB;
}
body .ant-tag.deposit {
  background: #F2F7EE;
  color: #3AB100;
}
body .ant-tag.direct-deposit {
  background: #fcffc9;
  color: #298A00;
  padding-left: 9.3px;
}
body .ant-tag.withdrawal {
  background: #F0FAFF;
  color: #4096E8;
}
body .ant-tag.marketplace-purchase {
  background: #FAF1FF;
  color: #8034C7;
}
body .ant-tag.cashback {
  background: #FFF0F9;
  color: #C76DB8;
}
body .ant-tag.referrarl {
  background: #E5FAFF;
  color: #23D5FF;
}
body .ant-tag.round {
  border-radius: 50% !important;
  width: 32px;
  height: 32px;
  display: flex;
}
body .ant-dropdown .ant-table-filter-dropdown .ant-dropdown-menu .ant-dropdown-menu-item img.icon {
  max-width: 32px;
  padding: 7px;
}
body .searchbox {
  padding: 24px 0px 24px 0px;
  border-radius: 12px;
  width: 30%;
  padding-bottom: 24px;
}
body .searchbox .ant-input.ant-input-lg {
  border-top-left-radius: 12px;
  border-bottom-left-radius: 12px;
}
body .searchbox span.ant-input-affix-wrapper.ant-input-affix-wrapper-lg {
  border-top-left-radius: 12px !important;
  border-bottom-left-radius: 12px !important;
}
body .searchbox button.ant-btn.ant-btn-lg.ant-btn-icon-only.ant-input-search-button {
  border-top-right-radius: 12px !important;
  border-bottom-right-radius: 12px !important;
}
body .ant-tabs.ant-tabs-top {
  width: 100%;
}
body .reactEasyCrop_CropArea.reactEasyCrop_CropAreaGrid {
  width: 250px !important;
  height: 100px !important;
}
body span.anticon.anticon-search {
  vertical-align: middle;
}
body .reactEasyCrop_CropArea.reactEasyCrop_CropAreaRound {
  width: 260px !important;
  height: 260px !important;
}
body {
  font-family: "Inter", sans-serif !important;
  font-size: 18px;
  line-height: 21px;
  color: black !important;
  font-weight: 300 !important;
  font-style: normal !important;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Inter", sans-serif !important;
  color: black !important;
  font-weight: 300 !important;
}
h1,
.h1 {
  font-size: 32px !important;
  line-height: 37px;
}
h2,
.h2 {
  font-size: 30px !important;
  line-height: 35px;
}
h3,
.h3 {
  font-size: 28px !important;
  line-height: 33px;
}
h4,
.h4 {
  font-size: 26px !important;
  line-height: 30px;
}
h5,
.h5 {
  font-size: 24px !important;
  line-height: 28px;
}
h6,
.h6 {
  font-size: 22px !important;
  line-height: 26px;
}
