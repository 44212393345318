.cashback-available {
    background: #FCFFE6;
    border-radius: 6px;
    font-size: 14px;
    line-height: 22px;
    color: #A0D911;
}
.cashback-canceled {
    background: #FFF1F0;
    border-radius: 6px;
    font-size: 14px;
    line-height: 22px;
    color: #F5222D;
}
.cashback-collected {
    background: #F2F7EE;
    border-radius: 6px;
    font-size: 14px;
    line-height: 22px;
    color: #3AB100;
}
.cashback-processing {
    background: #F0F5FF;
    border-radius: 6px;
    font-size: 14px;
    line-height: 22px;
    color: #4096E8;
}
.cashback-expired {
    background: #FFF7E6;
    border-radius: 6px;
    font-size: 14px;
    line-height: 22px;
    color: #FA8C16;
}

.cashback-pending {
    background: #E6E6E6;
    border-radius: 6px;
    font-size: 14px;
    line-height: 22px;
    color: #525252;
}
@backgroud-color: #E8E8E8;@primary-color: #3AB100;@primary-border: #58BD1E;@primary-background: #3AB100;@primary-hover: #E4F0D8;@primary-border-hover: #97D667;@info-color: #12b1d6;@warning-color: #FA8C16;@error-primary: #F5222D;@error-border: #FFA39E;@error-background: #FFF1F0;@white: #FFFFFF;@gray-border: #BFBFBF;@gray-background: #E6E6E6;@card-background: #FFFFFF;@text-color: #525252;