@import (css) url(http://fonts.googleapis.com/css?family=Playfair+Display+SC);
body {
	font-family: "Inter", sans-serif !important;
	font-size: 18px;
	line-height: 21px;
	color: black !important;
	font-weight: 300 !important;
	font-style: normal !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	font-family: "Inter", sans-serif !important;
	color: black !important;
	font-weight: 300 !important;
}

h1, .h1 {
	font-size: 32px !important;
	line-height: 37px;
}

h2, .h2 {
	font-size: 30px !important;
	line-height: 35px;
}

h3, .h3 {
	font-size: 28px !important;
	line-height: 33px;
}

h4, .h4 {
	font-size: 26px !important;
	line-height: 30px;

}

h5, .h5 {
	font-size: 24px !important;
	line-height: 28px;

}

h6, .h6 {
	font-size: 22px !important; //or 18
	line-height: 26px;

}
