@import '../../_variables.less';
@import '../../_mediaquery.less';

.reset-password-widget {
    max-width: 365px;
    max-width: 365px;
    display: flex;
    justify-content: center;
    align-items: center;

    .forgot-password-form {

        display: flex;
        justify-content: center;
        flex-direction: column;
        padding: 24px;
        background: #FFFFFF;
        box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
        border-radius: 12px;
        margin-top: 32px;
        
        .content {
            display: flex;
            justify-content: center;
            padding:  none !important;
        }

        .header-title {
            font-weight: 600;
            font-size: 20px;
            line-height: 28px;

            text-align: center;
            color: #525252;

            margin-top: 16px;
            margin-bottom: 24px;

        }

        .subheader-title {
            font-weight: normal;
            font-size: 14px;
            line-height: 22px;

            text-align: center;
            color: #525252;
            margin: 0px 16px;
        }

        .form {

            margin-top: 24px;

            &.reset-password-popup {
                display: block;
            }
            
            .email-input {
                background: @card-background;
                border: 1px solid @gray-border;
                box-sizing: border-box;
                border-radius: 12px;  
            }

    
            .forgot-submit-button {
                padding: 8px 16px;
                box-sizing: border-box;
                border-radius: 12px;

                &.disabled {
                    background: @gray-background;
                    border: 1px solid  @gray-border;
                    border-color:  @gray-border;

                    &:hover{
                        border-color:  @gray-border;
                    }
                }
                &.active {
                    background: @primary-color;
                    border-radius: 12px;
                    border: 1px solid@primary-color;

                    &:hover{
                        border-color: @primary-color;
                    }
                }
            
            } 
        }

        .instruction-sent-form {

            display: flex;
            justify-content: center;

            .content {
                display: flex;
                justify-content: center;
            }

            .header-title {
                font-weight: 600;
                font-size: 20px;
                line-height: 28px;

                margin-top: 19px;
                margin-bottom: 16px;

                .resent {
                    color:#52C41A;
                }
            }

            .subheader-title {
                font-weight: normal;
                font-size: 14px;
                line-height: 22px;
                color: #525252;
                margin: 0px 0px;
            }

            .circle-check {
                color: #52C41A !important;
                max-width: 42px;
                max-height: 42px;

                svg {
                    width: 42px;
                    height: 42px;
                }            
            }

            .resend-link {

                display: flex;
                text-align: center;
                margin-top: 40px;        
                color: #525252;
                justify-content: center;
                align-items: baseline;
                
                .resend-anchor-link {
                    padding-left: 5px;
                    color: #52C41A;
                    
                }
            }

        .button-redirect {    

            display: contents;      

            .forgot-submit-button {
                
                margin-top: 24px;

                &.disabled {
                    background: @gray-background;
                    border: 1px solid  @gray-border;
                    border-color:  @gray-border;

                    &:hover{
                        border-color:  @gray-border;
                    }
                }
                &.active {
                    background: @primary-color;
                    border-radius: 12px;
                    border: 1px solid @primary-color;

                    &:hover{
                        border-color: @primary-color;
                    }
                }
            
            } 
        }
        }
        
        .error-alert {
            margin-top: 24px;
            background: #FFF1F0;
            border: 1px solid #FFA39E;
            box-sizing: border-box;
            border-radius: 12px;
            max-width: 314px;
            .ant-alert-message{
                padding-right: 10px;
            }
        }
        
        .ant-layout, .ant-layout {
            display: block;
        }
    }
}

