.transaction-affiliate-row-item {
    
    .row-item {
        width: 100%;
        display: flex;
        align-items: center;
        padding: 24px 0px 0px 0px;
        
        &.no-padding {
            padding: 0px 0px 0px 0px;
        }

        .fullwidth-input{
            display: flex;
            width: 100%;
        }

        .ant-select-selector {
            border-radius: 12px;
        }

 
        
       
    }

}
.ant-popover {
    &.ant-popover-placement-bottom {
       max-width: 400px;
   }
}
@backgroud-color: #E8E8E8;@primary-color: #3AB100;@primary-border: #58BD1E;@primary-background: #3AB100;@primary-hover: #E4F0D8;@primary-border-hover: #97D667;@info-color: #12b1d6;@warning-color: #FA8C16;@error-primary: #F5222D;@error-border: #FFA39E;@error-background: #FFF1F0;@white: #FFFFFF;@gray-border: #BFBFBF;@gray-background: #E6E6E6;@card-background: #FFFFFF;@text-color: #525252;